export default [
  {
    title: 'West Coquitlam',
    marker: require('src/assets/images/community/map/west-coquitlam.svg'),
    largerMarker: true,
    top: '45.5%',
    left: '43.4%',
    tabletTop: '48%',
    tabletLeft: '43.3%',
    description:
      'All eyes are on this community where modern urban neighbourhoods are nestled into forested hillsides, and SkyTrain provides quick travel throughout Metro Vancouver. ',
    images: [
      [
        {
          src: require('src/assets/images/community/profiles/west-coquitlam-skytrain.jpg'),
          alt: 'Skytrain',
        },
        {
          src: require('src/assets/images/community/profiles/west-coquitlam-stoney-creek.jpg'),
          alt: 'Stoney Creek',
        },
        {
          src: require('src/assets/images/community/profiles/west-coquitlam-safeway.jpg'),
          alt: 'Safeway',
        },
      ],
      [
        {
          src: require('src/assets/images/community/profiles/west-coquitlam-vancouver-golf-club.jpg'),
          alt: 'Vancouver Golf Club',
        },
        {
          src: require('src/assets/images/community/profiles/west-coquitlam-mountain-view-park.jpg'),
          alt: 'Mountain View Park',
        },
        {
          src: require('src/assets/images/community/profiles/west-coquitlam-tennis.jpg'),
          alt: 'Tennis Courts',
        },
      ],
    ],
  },
  {
    title: 'Port Moody',
    marker: require('src/assets/images/community/map/port-moody.svg'),
    top: '15.6%',
    left: '60%',
    tabletTop: '13%',
    tabletLeft: '62%',
    description:
      'This historic town is loved for its heritage charm, art scene, waterfront trails, and breweries.',
    images: [
      [
        {
          src: require('src/assets/images/community/profiles/pomo-brewery.jpg'),
          alt: 'Brewery',
        },
        {
          src: require('src/assets/images/community/profiles/pomo-vivios.jpg'),
          alt: 'Vivios',
        },
        {
          src: require('src/assets/images/community/profiles/pomo-tacos.jpg'),
          alt: 'Tacos',
        },
      ],
      [
        {
          src: require('src/assets/images/community/profiles/pomo-rocky-point-pier.jpg'),
          alt: 'Rocky Point Pier',
        },
        {
          src: require('src/assets/images/community/profiles/pomo-rocky-point-walkway.jpg'),
          alt: 'Rocky Point Walkway',
        },
        {
          src: require('src/assets/images/community/profiles/pomo-rocky-point.jpg'),
          alt: 'Rocky Point',
        },
      ],
    ],
  },
  {
    title: 'Burnaby Mountain/SFU',
    marker: require('src/assets/images/community/map/burnaby.svg'),
    top: '5.7%',
    left: '2.5%',
    tabletTop: '7.5%',
    tabletLeft: '10%',
    description:
      'This is one of Metro Vancouver’s most popular hiking and biking destinations, with a network of 26 multi-use trails winding through 576 woodsy hectares. It is also home to SFU, one of Canada’s top ranked universities.',
    images: [
      [
        {
          src: require('src/assets/images/community/profiles/burnaby-mountain.jpg'),
          alt: 'Burnaby Mountain',
        },
        {
          src: require('src/assets/images/community/profiles/burnaby-sfu.jpg'),
          alt: 'Simon Fraser University',
        },
        {
          src: require('src/assets/images/community/profiles/burnaby-velodrome-trail.jpg'),
          alt: 'Velodrome Trail',
        },
      ],
    ],
  },
  {
    title: 'Coquitlam',
    marker: require('src/assets/images/community/map/coquitlam.svg'),
    top: '39.3%',
    left: '69.7%',
    tabletTop: '40.5%',
    tabletLeft: '73%',
    description:
      'This rapidly growing city is known for walkable urban neighbourhoods. Explore the character shops and unique restaurants of Austin Heights, or enjoy one of the many celebrated parks and lakes of the Metro Vancouver area.',
    images: [
      [
        {
          src: require('src/assets/images/community/profiles/coquitlam-mundy-park.jpg'),
          alt: 'Mundy Park',
        },
        {
          src: require('src/assets/images/community/profiles/coquitlam-giancarlo-deli.jpg'),
          alt: 'Giancarlo Deli',
        },
        {
          src: require('src/assets/images/community/profiles/west-coquitlam-italian-deli.jpg'),
          alt: 'Italian Deli',
        },
      ],
    ],
  },
];
