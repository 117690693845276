import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';

const Radio = props => {
  const { radio } = props;
  return (
    <Root>
      <div className="labels">
        <p>{radio.label}</p>
        <p id="radio-invalid">This field is required</p>
      </div>
      {radio.options.map((item, index) => (
        <Input key={index}>
          {item.name}
          <input type="radio" name={radio.name} value={item.value} required />
          <span />
        </Input>
      ))}
    </Root>
  );
};

export default Radio;

const Root = styled.div`
  margin-bottom: 33px;
  p {
    margin-bottom: 12px;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    letter-spacing: 1px;
    color: black;
    @media (min-width: ${media.tablet}) {
      font-size: 15px;
      letter-spacing: 1.5px;
    }
  }
  .labels {
    display: flex;
    @media (max-width: 330px) {
      display: block;
    }
  }
  #radio-invalid {
    color: #ff0000;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 16px;
    letter-spacing: 0.8px;
    opacity: 0;
    transition: 0.3s ease;
    margin-left: 5px;
    margin-bottom: 0;
    max-height: 0;
    @media (max-width: 330px) {
      margin-left: 0;
    }
  }
`;

const Input = styled.label`
  position: relative;
  padding-left: 30px;
  margin-right: 20px;
  font-family: ${({ theme }) => theme.font.family};
  font-size: 15px;
  line-height: 1;
  letter-spacing: 1.5px;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    opacity: 0;
    cursor: pointer;
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.brownGrey};
    cursor: pointer;
  }
  input:checked ~ span {
    background-color: ${({ theme }) => theme.color.brownGrey};
  }
`;
