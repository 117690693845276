import React from 'react';
import styled from 'styled-components';

import Intl from '../../../components/Intl';
import CaptionMedia from 'components/CaptionMedia';
import Container from 'components/Container';
import ScrollAnimation from 'components/ScrollAnimation';

import media from 'src/styles/media';

const Projects = () => {
  return (
    <Intl>
      {t => (
        <Root>
          <Container>
            <ScrollAnimation>
              <Wrapper margin="322px" tabletMargin="264px">
                <StyledCaptionMedia
                  image={require('src/assets/images/boffo/boffo-smithe-tower.jpg')}
                  title={t.boffo.projects[0].title}
                  text={t.boffo.projects[0].caption}
                  width="41.67%"
                  tabletWidth="43.6%"
                />
                <CaptionMedia
                  image={require('src/assets/images/boffo/boffo-modello-tower.jpg')}
                  title={t.boffo.projects[1].title}
                  text={t.boffo.projects[1].caption}
                  width="41.67%"
                  tabletWidth="43.6%"
                />
              </Wrapper>
              <Wrapper margin="160px" tabletMargin="97px">
                <CaptionMedia
                  image={require('src/assets/images/boffo/boffo-adagio.jpg')}
                  text={t.boffo.projects[2].caption}
                  width="50%"
                  tabletWidth="58.2%"
                />
                <StyledCaptionMedia
                  image={require('src/assets/images/boffo/boffo-jewel-towers-edited.jpg')}
                  text={t.boffo.projects[3].caption}
                  width="25%"
                  tabletWidth="33.4%"
                />
              </Wrapper>
              <Wrapper margin="213px" tabletMargin="60px">
                <Award>
                  <img
                    src={require('src/assets/images/boffo/udi_winner_logo.png')}
                    alt="UDI Winner"
                  />
                  <h1>
                    {t.boffo.projects[4].title}
                  </h1>
                </Award>
                <StyledCaptionMedia
                  image={require('src/assets/images/boffo/boffo-modello-kitchen.jpg')}
                  text={t.boffo.projects[5].caption}
                  width="50%"
                  tabletWidth="58.2%"
                  flipped
                />
              </Wrapper>
              <StyledCaptionMedia
                image={require('src/assets/images/boffo/boffo-artemisia.jpg')}
                text={t.boffo.projects[6].caption}
                width="100%"
                marginBottom
              />
              <Wrapper margin="153px" tabletMargin="87px">
                <StyledCaptionMedia
                  image={require('src/assets/images/boffo/the-smithe-kitchen.jpg')}
                  text={t.boffo.projects[7].caption}
                  width="50%"
                  tabletWidth="58.2%"
                />
                <CaptionMedia
                  image={require('src/assets/images/boffo/boffo-jewel-townhome.jpg')}
                  text={t.boffo.projects[8].caption}
                  width="25%"
                  tabletWidth="33.4%"
                />
              </Wrapper>
              <Wrapper end margin="120px" tabletMargin="60px">
                <Ovation
                  src={require('src/assets/images/boffo/ovation_award_winner.png')}
                  alt="Ovation Award Winner"
                />
              </Wrapper>
              <CaptionMedia
                image={require('src/assets/images/boffo/boffo-jewel-porte-cochere.jpg')}
                text={t.boffo.projects[9].caption}
                width="100%"
              />
            </ScrollAnimation>
          </Container>
        </Root>
      )}
    </Intl>
  );
};

export default Projects;

const Root = styled.div`
  padding: 20px 0 0;
  @media (min-width: ${media.tablet}) {
    padding: 60px 0 88px;
  }
  @media (min-width: ${media.desktop}) {
    padding: 120px 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => (props.end ? 'flex-end' : 'space-between')};
    align-items: flex-start;
    margin-bottom: ${(props) =>
      props.tabletMargin ? props.tabletMargin : props.margin};
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${(props) => props.margin};
  }
`;

const StyledCaptionMedia = styled(CaptionMedia)`
  order: ${(props) => (props.flipped ? 1 : 'none')};
  @media (min-width: ${media.tablet}) {
    order: none;
    margin-top: ${(props) => (props.marginBottom ? 0 : '40px')};
    margin-bottom: ${(props) => (props.marginBottom ? '88px' : 0)};
  }
  @media (min-width: ${media.desktop}) {
    margin-top: ${(props) => (props.marginBottom ? 0 : '120px')};
    margin-bottom: ${(props) => (props.marginBottom ? '193px' : 0)};
  }
`;

const Award = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 2;
  margin-bottom: 60px;
  img {
    width: 148px;
    height: 206px;
    margin-top: 20px;
    order: 2;
  }
  h1 {
    text-align: center;
    text-transform: uppercase;
    order: 1;
  }
  @media (min-width: ${media.tablet}) {
    order: 1;
    width: 50%;
    margin-bottom: 0;
  }
  @media (min-width: ${media.desktop}) {
    img {
      order: 1;
      margin-top: 0;
      margin-bottom: 60px;
      width: 182px;
      height: 252px;
    }
    h1 {
      order: 2;
    }
  }
`;

const Ovation = styled.img`
  width: 115px;
  margin: 0 auto 60px;
  @media (min-width: ${media.tablet}) {
    margin: 0;
    margin-right: 14.4%;
  }
  @media (min-width: ${media.desktop}) {
    width: 12.2%;
  }
`;
