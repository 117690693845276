import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'src/styles/media';

const Hero = (props) => {
  const { background, textImage } = props;
  return (
    <Root background={background}>
      <Text src={textImage} alt="" />
      <Arrow
        src={require('src/assets/images/icons/arrow-down.svg')}
        alt="Scroll Down"
      />
    </Root>
  );
};

Hero.propTypes = {
  background: PropTypes.string,
  textImage: PropTypes.string,
};

export default Hero;

const Root = styled.div`
  height: 178px;
  margin-top: 70px;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  position: relative;
  @media (min-width: ${media.tablet}) {
    height: 427px;
  }
  @media (min-width: ${media.desktop}) {
    height: calc(100vh - 136px);
    min-height: 564px;
    margin-top: 136px;
  }
  @media ${media.ipadPro} {
    height: 427px;
    min-height: none;
    margin-top: 70px;
  }
`;

const Text = styled.img`
  position: absolute;
  top: 43px;
  left: 50%;
  transform: translateX(-50%);
  width: 252px;
  @media (min-width: ${media.tablet}) {
    width: 458px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (min-width: ${media.desktop}) {
    width: auto;
    height: 215px;
  }
  @media ${media.ipadPro} {
    width: 458px;
    height: auto;
  }
`;

const Arrow = styled.img`
  width: 31px;
  height: 8px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: ${media.tablet}) {
    bottom: 60px;
  }
  @media (min-width: ${media.desktop}) {
    bottom: 111px;
  }
  @media ${media.ipadPro} {
    bottom: 60px;
  }
`;
