import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import CaptionMedia from 'components/CaptionMedia';
import Container from 'components/Container';
import ScrollAnimation from 'components/ScrollAnimation';
import IntroText from 'components/Layout/IntroText';

import media from 'src/styles/media';

const Intro = () => {
  return (
    <Intl>
      {(t) => (
        <Container>
          <Root>
            <ScrollAnimation>
              <IntroText
                title={t.homes.intro.title}
                text={t.homes.intro.text}
              />
              <CaptionMedia
                image={require('src/assets/images/homes/smith-and-farrow-interior-living.jpg')}
                text={t.homes.intro.imageCaption1}
                width="100%"
                relative
              />
              <Wrapper>
                <StyledCaptionMedia
                  image={require('src/assets/images/homes/smith-and-farrow-interior-wall-niche.jpg')}
                  text={t.homes.intro.imageCaption2}
                  width="33.33%"
                  relative
                />
                <CaptionMedia
                  image={require('src/assets/images/homes/smith-and-farrow-interior-living-detail.jpg')}
                  text={t.homes.intro.imageCaption3}
                  width="41.67%"
                  relative
                />
              </Wrapper>
            </ScrollAnimation>
          </Root>
        </Container>
      )}
    </Intl>
  );
};

const Root = styled.div``;

const Wrapper = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
  }
  @media (min-width: ${media.desktop}) {
    margin-top: 115px;
  }
`;

const StyledCaptionMedia = styled(CaptionMedia)`
  @media (min-width: ${media.tablet}) {
    margin-top: 40px;
  }
  @media (min-width: ${media.desktop}) {
    margin-top: 120px;
  }
`;

export default Intro;
