import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import CaptionMedia from 'components/CaptionMedia';
import Container from 'components/Container';
import ScrollAnimation from 'components/ScrollAnimation';
import IntroText from 'components/Layout/IntroText';

const Intro = () => {
  return (
    <Intl>
      {(t) => (
        <Container>
          <Root>
            <ScrollAnimation>
              <IntroText
                title={t.amenities.intro.title}
                text={t.amenities.intro.text}
                wide
              />
              <CaptionMedia
                image={require('src/assets/images/amenities/smith-and-farrow-amenity-entrance.jpg')}
                text={t.amenities.intro.imageCaption}
                width="100%"
                relative
                artistRendering
              />
            </ScrollAnimation>
          </Root>
        </Container>
      )}
    </Intl>
  );
};

const Root = styled.div``;

export default Intro;
