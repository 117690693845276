import React from 'react';
import styled from 'styled-components';

import Intl from '../../components/Intl';
import TextHero from 'components/TextHero';
import RegisterTagline from 'components/RegisterTagline';
import Projects from './sections/Projects';
import Developers from './sections/Developers';

const Boffo = () => {
  return (
    <Intl>
      {t => (
        <Root>
          <TextHero
            title={t.boffo.hero.title}
            width="562px"
            text={t.boffo.hero.bodyParagraphs}
          />
          <Projects />
          <Developers />
          <RegisterTagline />
        </Root>
      )}
    </Intl>
  );
};

export default Boffo;

const Root = styled.div``;
