import React from 'react';
import styled from 'styled-components';

import Intl from './Intl';
import Container from 'components/Container';

import media from 'src/styles/media';

const RegisterTagline = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <Container>
            <h1>{t.registerBanner.title}</h1>
            <Wrapper>
              <div>
                <h4>{t.registerBanner.subTitle}</h4>
                <p className="bold">{t.registerBanner.text}</p>
                {/* <p className="address">
                  Janine Mackie - <a href="tel:778.873.6984">778.873.6984</a>
                </p> */}
                <p>
                <a href="mailto:info@SmithAndFarrow.ca">
                    info@SmithAndFarrow.ca
                  </a><br />
                  <a href="tel:604.456.0688">604.456.0688</a>
                </p>
              </div>
              {/* <img
                src={require('src/assets/images/contact/sf-footer-map-outlined.jpg')}
                alt="Sales Gallery"
              /> */}
            </Wrapper>
          </Container>
        </Root>
      )}
    </Intl>
  );
};

export default RegisterTagline;

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.offWhite};
  padding: 40px 0 20px;
  h1 {
    text-transform: uppercase;
    margin-bottom: 32px;
  }
  h4 {
    font-family: ${({ theme }) => theme.font.family};
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 3px;
    color: black;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .bold {
    font-weight: 600;
  }
  .address {
    margin-bottom: 20px;
  }
  .underline {
    text-decoration: underline;
  }
  @media (min-width: ${media.tablet}) {
    padding: 33px 0 43px;
    h1 {
      margin-bottom: 0;
      width: 294px;
      letter-spacing: normal;
    }
    > div {
      display: flex;
      justify-content: space-between;
    }
    h4 {
      font-size: 14px;
      letter-spacing: 3px;
    }
    .address {
      margin-bottom: 20px;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 43px 0 53px;
    h1 {
      margin-bottom: 0;
      width: 483px;
    }
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h4 {
      font-size: 16px;
      letter-spacing: 3.5px;
      margin-bottom: 20px;
    }
    a {
      transition: 0.3s ease;
      &:hover {
        text-decoration: underline;
      }
    }
    .address {
      margin-bottom: 20px;
    }
  }
`;

const Wrapper = styled.div`
  img {
    margin-top: 32px;
    width: 100%;
    display: block;
    border: 1px solid ${({ theme }) => theme.color.brownGrey};
  }
  span {
    margin-right: 5px;
  }
  @media (min-width: ${media.tablet}) {
    display: flex;
    img {
      margin-top: 0;
      margin-left: 20px;
      width: 156px;
      height: 127px;
    }
    p {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: normal;
    }
  }
  @media (min-width: ${media.desktop}) {
    img {
      width: 280px;
      height: 240px;
      margin-left: 40px;
    }
    p {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.5px;
    }
  }
  @media (min-width: 1200px) {
    img {
      width: 320px;
      height: 260px;
      margin-left: 65px;
    }
  }
`;
