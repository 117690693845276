import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Button from 'components/Text/Button';

const Thankyou = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <Container>
            <div>
              <h1>{t.thankYou.title}</h1>
              <p className="intro">{t.thankYou.body}</p>
            </div>
          </Container>
          <Container bottom>
            <Button black to="/pc" width="300px" text="Close" />
          </Container>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.offWhite};
  position: absolute;
  top: 0;
  left: 0;
  h1 {
    font-family: ${({ theme }) => theme.font.header};
    font-size: 40px;
    font-weight: normal;
    line-height: 1.1;
    letter-spacing: 2px;
    text-transform: uppercase;
    max-width: 361px;
    margin: 0 auto 40px;
  }
  .intro {
    font-family: ${({ theme }) => theme.font.secondary};
    font-size: 27px;
    font-weight: 300;
    margin: 0 auto;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 50vh;
  position: absolute;
  left: 0;
  bottom: ${(props) => (props.bottom ? 0 : '50%')};
  display: flex;
  flex-direction: ${(props) => (props.bottom ? 'row' : 'column')};
  align-items: center;
  justify-content: ${(props) => (props.bottom ? 'center' : 'flex-end')};
  text-align: center;
  div {
    max-width: 560px;
  }
`;

export default Thankyou;
