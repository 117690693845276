import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Intl from '../components/Intl'

import media from 'src/styles/media';

const ThankYou = () => {
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root>
          <Content>
            <h1>{t.thankYou.title}</h1>
            <p>
              {t.thankYou.body}
            </p>
            <Button to={getLocaleURL()+'/'}>{t.thankYou.back}</Button>
          </Content>
        </Root>
      )}
    </Intl>
  );
};

export default ThankYou;

const Root = styled.div``;

const Content = styled.div`
  background-color: ${({ theme }) => theme.color.offWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  margin-top: 70px;
  @media (min-width: ${media.tablet}) {
    padding: 102px 0 208px;
  }
  @media (min-width: ${media.desktop}) {
    margin-top: 136px;
  }
  h1 {
    font-family: ${({ theme }) => theme.font.header};
    font-size: 42px;
    font-weight: normal;
    line-height: 1.1;
    text-align: center;
    color: black;
    @media (min-width: ${media.tablet}) {
      font-size: 60px;
      line-height: 1.06;
      max-width: 850px;
    }
  }
  p {
    font-family: ${({ theme }) => theme.font.secondary};
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: 0.5px;
    font-weight: 300;
    text-align: center;
    color: black;
    padding: 18px 0 37px;
    max-width: 650px;
    @media (min-width: ${media.tablet}) {
      max-width: 650px;
      font-size: 24px;
      line-height: normal;
      letter-spacing: normal;
    }
  }
`;

const Button = styled(Link)`
  border: 1px solid ${({ theme }) => theme.color.brownGrey};
  padding: 14px 25px;
  font-family: ${({ theme }) => theme.font.family};
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.4px;
  text-align: center;
  color: black;
  transition: 0.3s ease;
  &:hover {
    background-color: ${({ theme }) => theme.color.brownGrey};
    color: white;
  }
`;
