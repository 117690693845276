import React from 'react';
import styled from 'styled-components';

import Hero from './sections/Hero';
import Main from './sections/Main';
import Video from './sections/Video';
import Highlights from './sections/Highlights';
import Neighbourhood from './sections/Neighbourhood';
// import Visit from './sections/Visit';
import RegisterTagline from 'components/RegisterTagline';

const Community = () => {
  return (
    <Root>
      <Hero />
      <Main />
      <Video />
      <Highlights />
      <Neighbourhood />
      {/* <Visit /> */}
      <RegisterTagline />
    </Root>
  );
};

export default Community;

const Root = styled.div``;
