import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Container from 'components/Container';
import ScrollAnimation from 'components/ScrollAnimation';
import CaptionMedia from 'components/CaptionMedia';
import TitleCallout from 'components/Text/TitleCallout';

import media from 'src/styles/media';

const Interiors = () => {
  return (
    <Intl>
      {(t) => (
        <Container>
          <Root>
            <ScrollAnimation>
              <Wrapper>
                <StyledCaptionMedia
                  image={require('src/assets/images/homes/smith-and-farrow-interior-glass-shower.jpg')}
                  width="33.33%"
                  relative
                />
                <CaptionMedia
                  image={require('src/assets/images/homes/smith-and-farrow-interior-bathroom-vanity.jpg')}
                  text={t.homes.interior.caption1}
                  width="50%"
                  relative
                />
              </Wrapper>
              <TitleCallout title={t.homes.interior.title1} />
              <CaptionMedia
                image={require('src/assets/images/homes/smith-and-farrow-interior-master.jpg')}
                text={t.homes.interior.caption2}
                width="100%"
                relative
              />
              <Wrapper>
                <CaptionMedia
                  image={require('src/assets/images/homes/smith-and-farrow-interior-office.jpg')}
                  text={t.homes.interior.caption3}
                  width="25%"
                  relative
                />
                <StyledCaptionMedia
                  image={require('src/assets/images/homes/smith-and-farrow-interior-second-bedroom.jpg')}
                  text={t.homes.interior.caption4}
                  width="50%"
                  relative
                />
              </Wrapper>
              <Space />
              <CaptionMedia
                video="https://player.vimeo.com/external/519276126.hd.mp4?s=0821dae4e33eaa121073fc1dfe85e3bf81c8fb4b&amp;profile_id=175"
                placeholder={require('src/assets/images/homes/smith-and-farrow-interior-mobile_video_2.jpg')}
                width="100%"
              />
              <TitleCallout title={t.homes.interior.title2} smaller />
              <Wrapper nomargin>
                <CaptionMedia
                  image={require('src/assets/images/homes/smith-and-farrow-interior-laundry-room.jpg')}
                  text={t.homes.interior.caption5}
                  width="33.33%"
                  relative
                />
                <StyledCaptionMedia
                  image={require('src/assets/images/homes/smith-and-farrow-interior-closet.jpg')}
                  text={t.homes.interior.caption6}
                  width="50%"
                  relative
                />
              </Wrapper>
            </ScrollAnimation>
          </Root>
        </Container>
      )}
    </Intl>
  );
};

const Root = styled.div``;

const Wrapper = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
    margin-top: ${(props) => (props.nomargin ? 0 : '80px')};
  }
  @media (min-width: ${media.desktop}) {
    margin-top: ${(props) => (props.nomargin ? 0 : '120px')};
  }
`;

const StyledCaptionMedia = styled(CaptionMedia)`
  @media (min-width: ${media.tablet}) {
    margin-top: 40px;
  }
  @media (min-width: ${media.desktop}) {
    margin-top: 120px;
  }
`;

const Space = styled.div`
  @media (min-width: ${media.tablet}) {
    padding-top: 80px;
  }
  @media (min-width: ${media.desktop}) {
    padding-top: 120px;
  }
`;

export default Interiors;
