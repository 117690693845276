import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';

import media from 'src/styles/media';

const Visit = () => {
  return (
    <Root>
      <StyledContainer>
        <div>
          <h1>
            Visit our
            <br />
            Sales Gallery
          </h1>
          <Flex>
            <div>
              <h4>Sales gallery</h4>
              <p>
                1234 Main Street
                <br />
                Port Coquitlam, BC
              </p>
            </div>
            <div>
              <h4>Hours</h4>
              <p>
                M-F: 10am-8pm
                <br />
                Sat-Sun: 9am-7pm
              </p>
            </div>
          </Flex>
        </div>
        <img
          src={require('src/assets/images/community/salesgallery_map.png')}
          alt="Sales Gallery"
        />
      </StyledContainer>
    </Root>
  );
};

export default Visit;

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.offWhite};
  padding: 40px 0;
  color: black;
  h1 {
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  img {
    width: 100%;
    height: auto;
  }
  h4 {
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 10px;
  }
  p {
    font-family: ${({ theme }) => theme.font.secondary};
    font-weight: 300;
    font-size: 14px;
    line-height: 1.64;
    margin-bottom: 40px;
  }
  @media (min-width: ${media.tablet}) {
    padding: 50px 0 60px;
    h1 {
      margin-bottom: 30px;
    }
    img {
      width: 280px;
      height: 228px;
    }
    p {
      margin-bottom: 0;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 60px 0 40px;
    h1 {
      margin-bottom: 40px;
    }
    img {
      width: 313px;
      height: 254px;
    }
    p {
      font-size: 16px;
      line-height: 1.5;
    }
  }
`;

const StyledContainer = styled(Container)`
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`;

const Flex = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    > div:first-of-type {
      margin-right: 60px;
    }
  }
`;
