import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Rendering from 'src/components/Layout/Rendering';

import media from 'src/styles/media';

const CaptionMedia = (props) => {
  const {
    video,
    image,
    title,
    text,
    width,
    tabletWidth,
    placeholder,
    relative,
    artistRendering,
  } = props;

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const $video = React.useRef();

  const browser = useSelector((state) => state.browser);

  const handleVideo = () => {
    if (browser.is.mobile) {
      if (isVideoPlaying) {
        setIsVideoPlaying(false);
        $video.current.pause();
      } else {
        setIsVideoPlaying(true);
        $video.current.play();
      }
    }
  };

  return (
    <Root
      {...props}
      width={width}
      tabletWidth={tabletWidth}
      title={title}
      onClick={handleVideo}
      isVideoPlaying={isVideoPlaying}
      relative={relative}
    >
      {image && artistRendering && (
        <Rendering src={image} alt={title ? title : text} />
      )}
      {image && !artistRendering && (
        <img src={image} alt={title ? title : text} />
      )}
      {video && (
        <div className="video">
          <video
            src={video}
            autoPlay={browser.greaterThan.mobile ? true : false}
            muted={true}
            playsInline
            loop
            ref={$video}
          />
          <img
            src={placeholder}
            className="placeholder"
            alt={title ? title : text}
          />
        </div>
      )}
      {title && <h3>{title}</h3>}
      {text && <p>{text}</p>}
    </Root>
  );
};

CaptionMedia.propTypes = {
  video: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  width: PropTypes.string,
  tabletWidth: PropTypes.string,
  placeholder: PropTypes.string,
};

export default CaptionMedia;

const Root = styled.div`
  color: black;
  margin-bottom: 40px;
  height: fit-content;
  h3 {
    margin: 5px 0 10px;
  }
  img,
  video {
    width: 100%;
    height: auto;
    margin-bottom: ${(props) => (props.title ? '20px' : '10px')};
    display: block;
  }
  .video {
    position: relative;
  }
  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 2;
    opacity: ${(props) => (props.isVideoPlaying ? 0 : 1)};
    transition: 0.3s ease;
  }
  p {
    font-family: ${(props) =>
      props.title ? props.theme.font.secondary : props.theme.font.family};
    font-weight: ${(props) => (props.title ? 300 : 'normal')};
    font-size: 14px;
    line-height: ${(props) => (props.title ? 1.64 : 1.29)};
    letter-spacing: ${(props) => (props.title ? 'normal' : '0.3px')};
  }
  @media (min-width: ${media.tablet}) {
    width: ${(props) => (props.tabletWidth ? props.tabletWidth : props.width)};
    position: relative;
    margin-bottom: 0;
    img,
    video {
      margin-bottom: 0;
    }
    h3 {
      position: absolute;
      top: calc(100% + 20px);
    }
    p {
      position: ${(props) => (props.relative ? 'relative' : 'absolute')};
      top: ${(props) =>
        props.relative
          ? 'auto'
          : props.title
          ? 'calc(100% + 66px)'
          : 'calc(100% + 10px)'};
      margin-top: ${(props) => (props.relative ? '10px' : 0)};
    }
    .placeholder {
      display: none;
    }
  }
  @media (min-width: ${media.desktop}) {
    width: ${(props) => props.width};
    h3 {
      top: calc(100% + 20px);
    }
    p {
      top: ${(props) =>
        props.relative
          ? 'auto'
          : props.title
          ? 'calc(100% + 66px)'
          : 'calc(100% + 15px)'};
      margin-top: ${(props) => (props.relative ? '17px' : 0)};
    }
  }
  @media ${media.ipadPro} {
    width: ${(props) => (props.tabletWidth ? props.tabletWidth : props.width)};
  }
`;
