import React from 'react';
import styled from 'styled-components';

import Button from '../elements/Button';

import media from 'src/styles/media';

const DesktopMap = () => {
  return (
    <Root>
      <img
        src={require('src/assets/images/contact/smith-farrow-directional-map-outlined.svg')}
        alt="Smith &amp; Farrow Map"
      />
      <Button />
    </Root>
  );
};

export default DesktopMap;

const Root = styled.div`
  display: none;
  @media (min-width: ${media.desktop}) {
    display: block;
    width: 100%;
    height: 49vw;
    position: relative;
    border-top: 1px solid #d1d1d1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
