import React from 'react';
import styled from 'styled-components';

import Hero from './sections/Hero';
import Content from './sections/Content';
import About from './sections/About';
import Amenities from './sections/Amenities';
import Panel from './elements/Panel';
import Intl from 'components/Intl';
import RegisterTagline from 'components/RegisterTagline';

const Home = () => {
  return (
    <Intl>
      {(t, { locale }) => (
        <Root>
          <Hero />
          <Content />
          <Panel
            title={t.home.homes.title}
            ctaLabel={t.home.homes.ctaLabel}
            to="/homes"
            image={require('src/assets/images/home/smith-and-farrow-amenities.jpg')}
            hidden={locale !== 'en'}
          />
          <Amenities hidden={locale !== 'en'} />
          <Panel
            title={t.home.community.title}
            ctaLabel={t.home.community.ctaLabel}
            to="/community"
            image={require('src/assets/images/home/explore_community.jpg')}
            twoLines
          />
          <About />
          <RegisterTagline />
        </Root>
      )}
    </Intl>
  );
};

export default Home;

const Root = styled.div``;
