import React from 'react';
import styled from 'styled-components';

import Intl from '../../../components/Intl'
import Container from 'components/Container';
import CaptionMedia from 'components/CaptionMedia';
import ScrollAnimation from 'components/ScrollAnimation';

import media from 'src/styles/media';

const Highlights = () => {
  return (
    <Intl>
      {t => (
        <Root>
          <Container>
            <ScrollAnimation>
              <h3>{t.community.highlights.title}</h3>
              <h4>{t.community.highlights.body}</h4>
            </ScrollAnimation>
            <ScrollAnimation>
              <Wrapper bottom largeMargin>
                <CaptionMedia
                  image={require('src/assets/images/community/port-moody-vivios-flowers.jpg')}
                  text={t.community.highlights.imageCaptions[0]}
                  width="50%"
                />
                <CaptionMedia
                  image={require('src/assets/images/community/burnaby-mountain.jpg')}
                  text={t.community.highlights.imageCaptions[1]}
                  width="33.33%"
                  tabletWidth="41.7%"
                />
              </Wrapper>
            </ScrollAnimation>
            <ScrollAnimation>
              <Wrapper>
                <CaptionMedia
                  image={require('src/assets/images/community/gabi-and-jules.jpg')}
                  text={t.community.highlights.imageCaptions[2]}
                  width="25%"
                  tabletWidth="33.2%"
                />
                <StyledCaptionMedia
                  placeholder={require('src/assets/images/community/lifestyle-park.jpg')}
                  video="https://player.vimeo.com/external/389597903.sd.mp4?s=37522caf508058b793af86f0c9f01865e6dc1612&amp;profile_id=165"
                  text={t.community.highlights.imageCaptions[3]}
                  width="50%"
                  tabletWidth="58.1%"
                  marginTop
                />
              </Wrapper>
            </ScrollAnimation>
            <StyledCaptionMedia
              placeholder={require('src/assets/images/community/video-skytrain.jpg')}
              video="https://player.vimeo.com/external/389299901.hd.mp4?s=8601de78e70fc5ef0b551a16249f94be8be7f88f&amp;profile_id=174"
              text={t.community.highlights.imageCaptions[4]}
              width="100%"
              marginBottom
            />
            <ScrollAnimation>
              <Wrapper largeMargin bottom>
                <CaptionMedia
                  image={require('src/assets/images/community/burnaby-mountain-sfu.jpg')}
                  text={t.community.highlights.imageCaptions[5]}
                  width="33.33%"
                  tabletWidth="41.5%"
                />
                <CaptionMedia
                  image={require('src/assets/images/community/mundy-park.jpg')}
                  text={t.community.highlights.imageCaptions[6]}
                  width="33.33%"
                  tabletWidth="41.7%"
                />
              </Wrapper>
            </ScrollAnimation>
            <Wrapper rightAlign>
              <CaptionMedia
                placeholder={require('src/assets/images/community/coquitlam-park-video.jpg')}
                video="https://player.vimeo.com/external/389301386.sd.mp4?s=e06132fbc7fa1f7cbfff4e335816ef478a9bc7da&amp;profile_id=164"
                text={t.community.highlights.imageCaptions[7]}
                width="58.25%"
                tabletWidth="74.8%"
              />
            </Wrapper>
            <ScrollAnimation>
              <CaptionMedia
                image={require('src/assets/images/community/westcoquitlam_pano.jpg')}
                text={t.community.highlights.imageCaptions[8]}
                width="100%"
                tabletWidth="100%"
              />
            </ScrollAnimation>
          </Container>
        </Root>
      )}  
    </Intl>
  );
};

export default Highlights;

const Root = styled.div`
  padding: 40px 0 0;
  color: black;
  h3,
  h4 {
    text-align: center;
  }
  h4 {
    margin: 20px auto 40px;
  }
  @media (min-width: ${media.tablet}) {
    padding: 60px 0 12px;
    h4 {
      max-width: 573px;
      margin: 30px auto;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 120px 0;
    h4 {
      max-width: 880px;
      margin: 20px auto 60px;
    }
  }
`;

const Wrapper = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: ${(props) =>
      props.rightAlign ? 'flex-end' : 'space-between'};
    align-items: ${(props) => (props.bottom ? 'flex-end' : 'flex-start')};
    margin-bottom: ${(props) => (props.largeMargin ? '114px' : '76px')};
  }
  @media (min-width: ${media.desktop}) {
    display: flex;
    justify-content: ${(props) =>
      props.rightAlign ? 'flex-end' : 'space-between'};
    align-items: ${(props) => (props.bottom ? 'flex-end' : 'flex-start')};
    margin-bottom: ${(props) => (props.largeMargin ? '188px' : '154px')};
  }
`;

const StyledCaptionMedia = styled(CaptionMedia)`
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${(props) => (props.marginBottom ? '60px' : 0)};
    margin-top: ${(props) => (props.marginTop ? '67px' : 0)};
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${(props) => (props.marginBottom ? '154px' : 0)};
    margin-top: ${(props) => (props.marginTop ? '165px' : 0)};
  }
`;
