import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'src/styles/media';

const IntroText = (props) => {
  const { title, text, wide } = props;
  return (
    <Root wide={wide}>
      <h2>{title}</h2>
      {text && <h4 className="intro">{text}</h4>}
    </Root>
  );
};

IntroText.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  wide: PropTypes.bool,
};

const Root = styled.div`
  text-align: center;
  color: black;
  padding: 40px 20px;
  h2 {
    text-transform: uppercase;
    font-size: 40px;
    line-height: 1.1;
    letter-spacing: 2px;
  }
  h4 {
    text-align: center;
    margin: 20px auto 0;
  }
  @media (min-width: ${media.tablet}) {
    padding: 60px 0;
    h4 {
      max-width: 560px;
      margin: 40px auto 0;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 120px 0 60px;
    h4 {
      max-width: ${(props) => (props.wide ? '1100px' : '1080px')};
    }
    h2 {
      font-size: 42px;
      line-height: 1.19;
      letter-spacing: normal;
    }
  }
  @media ${media.ipadPro} {
    padding: 60px 0;
    h4 {
      max-width: 80%;
      margin: 30px auto 0;
    }
  }
`;

export default IntroText;
