import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Container from 'components/Container';
import ScrollAnimation from 'components/ScrollAnimation';
import CaptionMedia from 'components/CaptionMedia';
import TitleCallout from 'components/Text/TitleCallout';
import IntroText from 'components/Layout/IntroText';

import media from 'src/styles/media';

const Townhome = () => {
  return (
    <Intl>
      {(t) => (
        <Container>
          <Root>
            <ScrollAnimation>
              <IntroText title={t.homes.townhome.title} />
              <CaptionMedia
                image={require('src/assets/images/homes/townhome/image-1.jpg')}
                text={t.homes.townhome.caption1}
                width="100%"
                relative
              />
              <StyledCaptionMedia
                image={require('src/assets/images/homes/townhome/image-2.jpg')}
                text={t.homes.townhome.caption2}
                width="60%"
                relative
              />
              <Wrapper>
                <CaptionMedia
                  image={require('src/assets/images/homes/townhome/image-3.jpg')}
                  text={t.homes.townhome.caption3}
                  width="60%"
                  relative
                />
              </Wrapper>
              <TitleCallout title={t.homes.townhome.text} smaller />
              <CaptionMedia
                image={require('src/assets/images/homes/townhome/image-4.jpg')}
                text={t.homes.townhome.caption4}
                width="100%"
                relative
              />
              <IntroText title="SALES GALLERY + DISPLAY HOME TOUR" smaller/>
              <CaptionMedia
                video="https://player.vimeo.com/progressive_redirect/playback/913145927/rendition/1080p/file.mp4?loc=external&log_user=0&signature=39fef1a37d3979ca5d6a76dc3f71e0cae46c2bfb78e0d1394bbe987f8b37e8a0"
                placeholder={require('src/assets/images/homes/interiors-display-suite-placeholder.jpg')}
                width="100%"
              />
              <Space />
            </ScrollAnimation>
          </Root>
        </Container>
      )}
    </Intl>
  );
};

const Root = styled.div``;

const Wrapper = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: flex-end;
    margin-top: ${(props) => (props.nomargin ? 0 : '80px')};
  }
  @media (min-width: ${media.desktop}) {
    margin-top: ${(props) => (props.nomargin ? 0 : '120px')};
  }
`;

const StyledCaptionMedia = styled(CaptionMedia)`
  @media (min-width: ${media.tablet}) {
    margin-top: 80px;
  }
  @media (min-width: ${media.desktop}) {
    margin-top: 120px;
  }
`;

const Space = styled.div`
  @media (min-width: ${media.tablet}) {
    padding-top: 80px;
  }
  @media (min-width: ${media.desktop}) {
    padding-top: 120px;
  }
`;

export default Townhome;
