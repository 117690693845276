import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import media from './media';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: ${theme.font.family};
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: black;
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  .fadeInUp {
    animation-name: fadeInUp;
  }

  h1 {
    font-family: ${theme.font.header};
    font-weight: normal;
    font-size: 40px;
    line-height:1.1;
    letter-spacing: 2px;
    @media (min-width: ${media.desktop}) {
      font-size: 68px;
      line-height: 1.06;
    }
    @media (min-width: ${media.xl}) {
      font-size: 92px;
    }
  }

  h2 {
    font-family: ${theme.font.header};
    font-weight: normal;
    font-size: 14px;
    line-height: 1.71;
    @media (min-width: ${media.desktop}) {
      font-size: 42px;
      line-height: 1.71;
    }
  }

  h3 {
    font-family: ${theme.font.header};
    font-weight: normal;
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: 1px;
    @media (min-width: ${media.desktop}) {
      font-size: 36px;
      line-height: 1;
      letter-spacing: normal;
    }
    &.gallery {
      font-family: ${theme.font.secondary};
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 2.63px;
      text-align: center;
      text-transform: uppercase;
      @media (min-width: ${media.desktop}) {
        font-size: 16px;
        letter-spacing: 3px;
      }
    }
  }

  h4 {
    font-family: ${theme.font.secondary};
    font-weight: 300;
    font-size: 17px;
    line-height: 1.53;
    @media (min-width: ${media.desktop}) {
      font-size: 27px;
      line-height: normal;
    }
    &.intro {
      font-size: 17px;
      font-weight: 300;
      line-height: 1.53;
      letter-spacing: normal;
      @media (min-width: ${media.desktop}) {
        font-size: 27px;
        line-height: normal;
      }
    }
  }

  h5 {
    font-family: ${theme.font.family};
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  p {
    font-family: ${theme.font.family};
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: 0.5px;
    @media (min-width: ${media.desktop}) {
      font-size: 18px;
      line-height: 1.39;
      letter-spacing: 1px;
    }
    @media (min-width: ${media.xl}) {
      font-size: 20px;
      line-height: 1.4;
    }
  }

  p.default {
    font-family: ${theme.font.secondary};
    font-size: 14px;
    font-weight: 300;
    line-height: 1.64;
    letter-spacing: normal;
    @media (min-width: ${media.desktop}) {
      font-size: 20px;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  p.small {
    font-family: ${theme.font.secondary};
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: normal;
  }
`;
