import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'src/styles/media';

const Grid = props => {
  const { data, activeSection } = props;

  const [activeSlide, setActiveSlide] = useState(0);

  let interval;

  useEffect(() => {
    if (data[activeSection].images.length > 1) {
      interval = setInterval(() => handleSlide(), 5000);
    }
    return () => clearInterval(interval);
  }, [activeSlide, activeSection]);

  useEffect(() => {
    setActiveSlide(0);
    return () => clearInterval(interval);
  }, [activeSection]);

  const handleSlide = () => {
    if (activeSlide === data[activeSection].images.length - 1) {
      return setActiveSlide(0);
    } else {
      return setActiveSlide(activeSlide + 1);
    }
  };

  return (
    <Root>
      {data.map((item, index) => (
        <Wrapper key={index} active={index === activeSection}>
          {item.images.map((images, j) => (
            <Layout key={j} active={activeSlide === j}>
              {images.map((image, k) => (
                <img src={image.src} key={k} alt={image.alt} />
              ))}
            </Layout>
          ))}
        </Wrapper>
      ))}
      {data[activeSection].images.length > 1 && (
        <Dots>
          {data[activeSection].images.map((item, index) => (
            <Dot
              key={index}
              active={index === activeSlide}
              onClick={() => setActiveSlide(index)}
            />
          ))}
        </Dots>
      )}
    </Root>
  );
};

Grid.propTypes = {
  data: PropTypes.array,
  activeSection: PropTypes.number,
};

export default Grid;

const Root = styled.div`
  width: 100%;
  height: 412px;
  position: relative;
  @media (min-width: ${media.tablet}) {
    width: 58%;
    height: 590px;
  }
  @media (min-width: ${media.desktop}) {
    width: 74.5%;
    height: 650px;
  }
  @media ${media.ipadPro} {
    width: 58%;
    height: 590px;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'all' : 'none')};
  transition: 0.5s ease-out;
`;

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 9px;
  grid-template-columns: 62.5% 33.6%;
  grid-template-rows: 68% 30%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: ${props => (props.active ? 1 : 0)};
    transition: 0.5s ease-out;
  }
  img:first-of-type {
    grid-row: 1;
    grid-column: 1 / 3;
  }
  img:nth-of-type(2) {
    grid-column: 1;
    grid-row: 2;
    transition-delay: 0.1s;
  }
  img:last-of-type {
    grid-column: 2;
    grid-row: 2;
    transition-delay: 0.2s;
  }
  @media (min-width: ${media.tablet}) {
    grid-gap: 13px;
  }
  @media (min-width: ${media.desktop}) {
    grid-gap: 20px;
    grid-template-columns: 66% 32%;
    grid-template-rows: 34% 63%;
    img:first-of-type {
      grid-row: 1 / 3;
      grid-column: 1;
    }
    img:nth-of-type(2) {
      grid-column: 2;
      grid-row: 1;
      transition-delay: 0.1s;
    }
    img:last-of-type {
      grid-column: 2;
      grid-row: 2;
      transition-delay: 0.2s;
    }
  }
  @media ${media.ipadPro} {
    grid-gap: 13px;
    img:first-of-type {
      grid-row: 1;
      grid-column: 1 / 3;
    }
    img:nth-of-type(2) {
      grid-column: 1;
      grid-row: 2;
      transition-delay: 0.1s;
    }
    img:last-of-type {
      grid-column: 2;
      grid-row: 2;
      transition-delay: 0.2s;
    }
  }
`;

const Dots = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
  display: flex;
  @media (min-width: ${media.desktop}) {
    top: calc(100% + 30px);
  }
`;

const Dot = styled.span`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${props =>
    props.active ? 'black' : props.theme.color.brownGrey};
  margin-left: 13px;
  background-color: ${props => (props.active ? 'black' : 'transparent')};
  transition: 0.3s ease;
  cursor: pointer;
`;
