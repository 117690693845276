import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';

const Select = props => {
  const { select } = props;
  return (
    <Root>
      <label htmlFor={select.name}>{select.label}</label>
      <p>This field is required</p>
      <select
        name={select.name}
        id={select.id ? select.id : select.name}
        required={select.required}
      >
        <option value="" />
        {select.options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.name}
          </option>
        ))}
      </select>
      <img src={require('src/assets/images/icons/select-arrow.svg')} alt="" />
    </Root>
  );
};

export default Select;

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
  position: relative;
  @media (min-width: ${media.tablet}) {
    width: 48.5%;
  }
  @media (min-width: ${media.desktop}) {
    width: 43.9%;
  }
  label {
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    letter-spacing: 1.2px;
    margin-bottom: 7px;
    display: block;
    @media (min-width: ${media.desktop}) {
      font-size: 15px;
      letter-spacing: 1.5px;
    }
  }
  select {
    width: 100%;
    height: 50px;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.brownGrey};
    border-radius: 0;
    appearance: none;
    font-size: 14px;
    letter-spacing: 1.2px;
    padding-left: 10px;
    @media (min-width: ${media.desktop}) {
      font-size: 15px;
      letter-spacing: 1.5px;
    }
  }
  img {
    position: absolute;
    right: 15px;
    bottom: 20px;
    @media (min-width: ${media.desktop}) {
      right: 25px;
    }
  }
  p {
    color: #ff0000;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    letter-spacing: 0.5px;
    opacity: 0;
    transition: 0.3s ease;
    max-height: 0;
    @media (min-width: ${media.tablet}) {
      font-size: 16px;
      letter-spacing: 0.8px;
    }
    @media (min-width: ${media.desktop}) {
      margin-left: 5px;
    }
  }
`;
