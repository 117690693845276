import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Intl from '../../../components/Intl';
import TransitOverlay from './TransitOverlay';

import media from 'src/styles/media';

const Map = props => {
  const { data, handleSection } = props;
  return (
    <Intl>
      {t => (
        <Root>
          <Header>
            <h3>{t.community.map.title}</h3>
          </Header>
          <Wrapper>
            <img
              src={require('src/assets/images/community/map/smith-farrow-community-map.jpg')}
              alt="Community Map"
              className="map mobile"
            />
            <img
              src={require('src/assets/images/community/map/smith-farrow-community-map_with_transit.jpg')}
              alt="Community Map"
              className="map desktop"
            />
            <TransitOverlay />
            {data.map((item, index) => (
              <Marker
                key={index}
                src={item.marker}
                alt={item.title}
                top={item.top}
                left={item.left}
                tabletTop={item.tabletTop}
                tabletLeft={item.tabletLeft}
                onClick={() => handleSection(index)}
                larger={item.largerMarker}
              />
            ))}
            <Logo
              src={require('src/assets/images/community/map/site_marker.svg')}
              alt="Smith &amp; Farrow"
            />
          </Wrapper>
        </Root>
      )}
    </Intl>
  );
};

Map.propTypes = {
  data: PropTypes.array,
  handleSection: PropTypes.func,
};

export default Map;

const Root = styled.div``;

const Header = styled.div`
  width: 100%;
  height: 100px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  color: white;
  background-color: ${({ theme }) => theme.color.grey};
  @media (min-width: ${media.tablet}) {
    height: 80px;
    padding: 0 40px;
  }
  @media (min-width: ${media.desktop}) {
    height: 100px;
    padding: 0 60px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 66.67vw;
  position: relative;
  background-color: grey;
  .map {
    width: 100%;
    height: 100%;
    display: block;
  }
  .desktop {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
`;

const Marker = styled.img`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  cursor: pointer;
  max-height: ${props => (props.larger ? '24px' : '22px')};
  width: auto;
  @media (min-width: ${media.tablet}) {
    max-height: ${props => (props.larger ? '35px' : '30px')};
    top: ${props => props.tabletTop};
    left: ${props => props.tabletLeft};
  }
  @media (min-width: ${media.desktop}) {
    max-height: ${props => (props.larger ? '3.8vw' : '3.5vw')};
  }
`;

const Logo = styled.img`
  position: absolute;
  top: 35.5%;
  left: 29.4%;
  width: 13.125vw;
  height: 10vw;
  @media (min-width: ${media.tablet}) {
    width: 62px;
    height: 52px;
    top: 38.5%;
    left: 34.4%;
  }
  @media (min-width: ${media.tablet}) {
    width: 5vw;
    height: 4.3vw;
    top: 40%;
    left: 37.4%;
  }
`;
