import React from 'react';

export default {
  name: 'Traditional Chinese',
  menu: [
    {
      title: 'BOFFO 鉅獻',
      to: '/boffo',
    },
    {
      title: 'Homes',
      to: '/homes',
      enOnly: true,
    },
    // {
    //   title: 'Floorplans',
    //   to: '/floorplans',
    // },
    {
      title: 'Amenities',
      to: '/amenities',
      enOnly: true,
    },
    {
      title: '西高貴林',
      to: '/community',
    },
    {
      title: 'Gallery',
      to: '/gallery',
    },
    {
      title: 'Contact',
      to: '/contact',
    },
    {
      title: '登記',
      to: '/register',
    },
  ],
  registerLabel: '登記',
  home: {
    hero: {
      comingSoon: '即將公開',
      slides: [
        {
          src: require('src/assets/images/home/hero-form-concept-box_tc.svg'),
          mobileSrc: require('src/assets/images/home/mobile_form-concept_tc.svg'),
          subtitle: '匠心獨具',
        },
        {
          src: require('src/assets/images/home/hero-time-place-box_tc.svg'),
          mobileSrc: require('src/assets/images/home/mobile_form-place_tc.svg'),
          subtitle: '都會之心',
        },
        {
          src: require('src/assets/images/home/hero-time-commitment-box_tc.svg'),
          mobileSrc: require('src/assets/images/home/mobile_form-commitment_tc.svg'),
          subtitle: '50 年歷史傳奇',
        },
      ],
    },
    content: {
      title: '好事成雙',
      body: '由知名發展商 Boffo 精心打造的 Smith & Farrow ，是一個融合公寓及城市屋房型的大型社區。其規劃完善的一至三睡房住家，不僅與Burquitlam天車站及西門菲沙大學為鄰，周邊更被各種生活便利環繞。社區內也設計了一系列的住戶獨享的休閒配套，各種細節都卓越地呈現了 Boffo 所堅持的傳統價值，以及無與倫比的傑出工藝。',
      button: 'View Floorplans',
    },
    homes: {
      title: 'Just one look, and you know',
      ctaLabel: 'Explore the Homes',
    },
    amenities: {
      title: 'Community spaces to celebrate everyday life',
      ctaLabel: 'View Amenities',
    },
    community: {
      title: (
        <>
          天生一對
          <br />
          共享鍾愛
        </>
      ),
      ctaLabel: '探索社區',
    },
    about: {
      title: (
        <>
          永續打造
          <br />
          美麗傳奇
        </>
      ),
      ctaLabel: '關於 BOFFO',
    },
  },
  registerBanner: {
    title: (
      <>
        家園之美
        <br />
        待您探究
      </>
    ),
    buttonLabel: '請即登記',
    subTitle: 'Sales Gallery',
    text: (
      <>
        Open daily (except Friday)
        <br />
        12pm – 6pm
      </>
    ),
    address: (
      <>
        2100 Springer Avenue, Burnaby
        <br />
        (southeast corner at Lougheed)
      </>
    ),
  },
  community: {
    hero: {
      textFile: 'hero_community_tc.svg',
    },
    main: {
      title: '西高貴林',
      body: '這個座落在本拿比與高貴林之間的現代化社區，不僅被美麗山林與靜謐的天然林地環繞，便捷的天車系統更近在咫尺，讓您來去自如、毫不費力！',
    },
    video: {
      title: (
        <>
          更接近
          <br />
          生活中的
          <br />
          一切美好
        </>
      ),
      iconName: 'play-video_tc.png',
    },
    highlights: {
      title: '在您身邊',
      body: '座落在城市中被天然美景環繞的優美地段，Smith & Farrow 地處靜謐的住宅區內，不僅能居高臨下地飽覽大片美景，各種美食餐廳、超市、名校、公園、遊樂場及天然綠徑就在身邊。以下是這個地段中，最為人津津樂道且備受喜愛的好去處。',
      imageCaptions: [
        '體現花藝之美的 Vivio 花卉藝廊。',
        '西門菲沙大學（SFU）本拿比校區旁、本拿比山公園當中的「天神遊樂場」（Kamui Mintara）裝置藝術。',
        '提供咖啡、手工派及甜點的美味去處 Gabi & Jules。',
        '不論是周日早午餐，或者在公園野餐，許多精彩的回憶都在此等待您的開創。',
        '從您的新家出發，徒步兩分鐘便可抵達 Burquitlam 天車站，由此至溫市中心只要短短的 35 分鐘車程。',
        '西門菲沙大學不僅學術地位崇高，更有特殊建築及壯麗美景',
        '佔地 435 英畝的 Mundy 公園，是高貴林最大的公園，當中還包含戶外泳池、野餐區、球場及環繞兩個優美湖泊的綠徑網絡。',
        '被靜謐的天然美景環抱，與湖泊、小溪及山林綠徑為鄰。',
        'Smith & Farrow 的 360 度優美景緻。',
      ],
    },
    map: {
      title: '四通八達的中心地段',
    },
    neighbourhood: {
      'West Coquitlam': {
        title: '西高貴林',
        description:
          '這個現代化的都會區，不僅坐擁綠意盎然的森林山坡，更有直通大溫的 SkyTrain 捷運系統。來往於各地之間，毫不費力。',
      },
      'Port Moody': {
        title: '滿地寶',
        description:
          '歷史悠久的滿地寶，因具有傳統文化、文藝氣息、水畔綠徑及多間聞名的特色釀酒廠，而備受大眾喜愛。',
      },
      'Burnaby Mountain/SFU': {
        title: '本拿比山/西門菲沙大學',
        description:
          '本拿比山佔地 576 英畝，有 26 條多用途綠徑穿梭其在其林區之間，是大溫最受歡迎的登山及單車去處之一，也是加國名列前茅的西門菲沙大學之所在地。',
      },
      Coquitlam: {
        title: '高貴林',
        description:
          '發展迅速的高貴林，以徒步可達的各種社區便利聞名。不論是 Austin 高地的特色商店及美味餐廳，或是深受大溫民眾喜愛的公園綠地或湖泊，肯定都會讓您流連忘返。',
      },
    },
    neighbourhoodProfiles: '社區概況',
  },
  boffo: {
    hero: {
      title: (
        <>
          50 年悠久歷史
          <br />
          為您打造溫馨家園
        </>
      ),
      bodyParagraphs: [
        '過去 50 年以來，Boffo 以卓越信譽廣獲好評。我們以貼心的設計及精美的工藝，為業主帶來無與倫比的體驗。在每一間由 Boffo 所打造的住家當中，都能見到我們對細節的堅持，並藉此呈現最尊貴的豪華成果。',
        '每一個由 Boffo 打造的社區，皆包含其獨特願景。我們憑藉豐富經驗，以人為本，為業主打造最溫馨的家園。為了讓每個社區都能源遠流長，我們更提供完善的售後服務，因為我們相信每個作品都是代表作，也反映出我們與眾不同的傑出特質。',
      ],
    },
    projects: [
      {
        title: 'The Smithe',
        caption:
          '位於溫市中心的藝術及展演特區內，The Smithe 社區內匠心獨具的 2 及 3 房府邸，皆為呈現前所未有的豪華生活體驗而打造。',
      },
      {
        title: 'Modello',
        caption:
          '以榮獲大獎肯定的 Jewel 社區為基礎，Modello 為本拿比的豪華公寓社區建立了新的標竿。它以引人矚目的建築設計、創新的造景及藝術設計，成為新落成的 Beresford 藝術走廊中最奪目耀眼的亮點。',
      },
      {
        caption: 'Adagio I + II、南素里',
      },
      {
        caption: 'Jewel I + II、本拿比',
      },
      {
        title: (
          <>
            榮獲
            <br />
            城市高樓社區獎
            <br />
            肯定
          </>
        ),
      },
      {
        caption: 'Modello、本拿比 | 2018 年 UDI 都會發展研究所卓越獎',
      },
      {
        caption: 'Artemisia、溫市中心',
      },
      {
        caption: 'The Smithe、溫市中心',
      },
      {
        caption: 'Jewel 城市屋',
      },
      {
        caption:
          'Jewel I + II、本拿比 | 榮獲 UDI 都會發展研究所、大溫房屋建築商協會及加拿大房屋建築商協會評選為多單位高層社區第一名。',
      },
    ],
    developers: [
      {
        name: 'Chris Dikeakos 建築設計公司',
        description:
          '高度專業的 Chris Dikeakos 建築設計公司（CDA)，曾獲得許多獎項肯定，CDA 打造的許多項目，更讓他們成為北美多單位與高層社區設計的領導者。Smith & Farrow 是 Boffo 與 CDA 第八次共同合作的項目。',
      },
      {
        name: 'mckinley',
        description:
          'Mckinley 採用獨特的沈浸實驗性創新手法，改良傳統式的設計方式，為創意顧問開創了一條嶄新道路。他們專精於建築、室內設計及品牌包裝，也是酒店、餐館及零售商鋪傢俱設計專家，更擅長於打造多用途項目的相關設計。Boffo 與 Mckinley 的合作，將為 Smith & Farrow 帶來令人耳目一新的室內設計。',
      },
    ],
  },
  register: {
    title: '獲得最新訊息',
    description:
      '請填寫以下欄目，以便獲取關於本項目的最新消息、特別活動通知及促銷訊息。您提交的所有資訊將被保密。',
  },
  form: {
    inputs: [
      {
        // only translate the label please :)
        name: 'contact[first_name]',
        id: 'contact_first_name',
        label: '名字*',
        required: true,
        pattern: '[a-zA-Z ]+',
      },
      {
        name: 'contact[last_name]',
        id: 'contact_last_name',
        label: '姓氏*',
        required: true,
        pattern: '[a-zA-Z ]+',
      },
      {
        name: 'contact[email]',
        id: 'contact_email',
        label: '電郵*',
        type: 'email',
        required: true,
      },
      {
        name: 'contact[phone]',
        id: 'contact_phone',
        label: '電話*',
        type: 'tel',
        required: true,
        pattern: '^[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$',
      },
      {
        name: 'contact[postcode]',
        id: 'contact_postcode',
        label: '郵編*',
        required: true,
        pattern: '[a-zA-Z0-9 ]+',
      },
    ],
    selects: [
      // only translate the label and option name please
      {
        label: '您偏好的聯絡方式',
        name: 'contact[contact_preference]',
        id: 'contact_contact_preference',
        required: false,
        options: [
          {
            name: '電郵',
            value: 'Email',
          },
          {
            name: '電話',
            value: 'Phone',
          },
          {
            name: '簡訊',
            value: 'Text Message',
          },
        ],
      },
      {
        label: '您如何得知有關 Smith & Farrow 的訊息？*',
        name: 'answers[5961][answers]',
        id: 'answers_5961',
        required: true,
        options: [
          {
            name: '網路搜索',
            value: 'Online Search',
          },
          {
            name: '網路廣告/社交網站',
            value: 'Online Advertising/Social Media',
          },
          {
            name: '印刷廣告',
            value: 'Print Advertising',
          },
          {
            name: '廣告看板',
            value: 'Signage',
          },
          {
            name: '電郵',
            value: 'Email',
          },
          {
            name: '親友告知',
            value: 'Friends & Family',
          },
          {
            name: '地產經紀',
            value: 'Realtor',
          },
        ],
      },
      {
        label: '您的家庭狀況',
        name: 'answers[6181][answers]',
        id: 'answers_6181',
        required: false,
        options: [
          {
            name: '單身',
            value: 'Single',
          },
          {
            name: '已婚',
            value: 'Couple',
          },
          {
            name: '已婚有小孩',
            value: 'Couple with Children',
          },
          {
            name: '單身有小孩',
            value: 'Single with Children',
          },
        ],
      },
    ],
    radio: {
      label: '您是否為地產經紀？*',
      name: 'agent',
      required: true,
      options: [
        {
          name: '是',
          value: 'true',
        },
        {
          name: '否',
          value: 'false',
        },
      ],
    },
    comments: '其他留言',
    required: '此為必填欄。',
    submit: '提交表格',
    insider:
      '我同時願意成為 Boffo 社區的一份子，並且願意收到 Boffo 公司其他項目的相關消息。',
    consent:
      '我願意接收由 The Boffo 集團發出的商業電郵，同時明白可隨時依照下方英文條款說明，取消訂閱此類電郵。',
    disclaimer:
      'Boffo Developments Ltd #200 - 4580 Hastings Street, Burnaby, BC V5C 2K4 By opting-in you consent to Boffo Developments Ltd and their current and future affiliates contacting you with information about their current and future products and services. Your information will not be shared, sold or distributed or otherwise to any other parties. If at any time you would no longer like to receive updates, you can update your profile by clicking the unsubscribe link at the bottom of any future email correspondence. Sales powered by MLA Canada Realty.',
  },
  thankYou: {
    title: '感謝您的登記',
    body: '您將優先收到各類項目最新訊息。',
    back: '回到首頁',
  },
  footer: {
    title: '體驗 Boffo',
    subtitle: '卑詩省精美豪邸的創造專家',
    text: 'THE DEVELOPER RESERVES THE RIGHT TO MODIFY THE BUILDING DESIGN, FLOORPLANS, PRICES, FEATURES AND SPECIFICATIONS WITHOUT NOTICE. THIS IS NOT AN OFFERING FOR SALE. ANY SUCH OFFERING CAN ONLY BE MADE BY WAY OF DISCLOSURE STATEMENT. E. & O.E. Sales powered by MLA Canada Realty.',
  },
  homes: {
    intro: {
      title: (
        <>
          Just One Look,
          <br />
          and You Know
        </>
      ),
      text: 'Boffo is unwavering in their commitment to creating unprecedented living experiences through artistic design and enduring quality. Standout floorplans place stunning views in front of tranquil master suites, expansive living areas, spacious kitchens and large terraces. Abundant glazing fills the residence with natural light and custom millwork makes every inch of the space exceptional.',
      imageCaption1:
        'Boffo’s signature floorplan, “Plan G”, has a uniquely wide and shallow layout with 37-feet of continuous windows spanning across the extremely spacious living area. This west-facing plan is available with 2 or 3 bedrooms with 2 bathrooms and is showcased in the darker “Smith” scheme.',
      imageCaption2:
        'Entry niches with integrated lighting, magnetic board and charging station allow you to keep track of all on-the-go essentials.',
      imageCaption3:
        'Accept no compromise in the place you call home. Smith & Farrow raises the bar for elegant living, with exacting standards of quality in every room. Over-height doors, 9-foot ceilings, open-concept design and wide-plank European flooring throughout contribute to the residence’s grand scale.',
    },
    kitchen: {
      title1:
        'Designed with chefs in mind, Boffo kitchens are renowned for their impeccable style and function. Everything is included - superior features are standard here.',
      title2:
        'Brilliant storage solutions, integrated under-cabinet lighting and sophisticated features combine for a space that radiates beauty while performing to the highest standards.',
      title3:
        'Bright and airy kitchen windows, pantry walls with display niches, and custom kitchen tables are featured throughout a selection of floorplans.',
      caption1:
        'Generous working surfaces, locally-crafted custom cabinetry and integrated appliances take culinary excitement to new heights. The stage is set for elegant entertaining and inspired family meals.',
      caption2:
        'Cabinets are built to maximize functionality, with spacesaving corners and Häfele pull-out storage solutions.',
      caption3:
        'Undermount 27-inch sink with fixtures in polished chrome or modern champagne—your choice.',
      caption4:
        'Five-burner Fisher & Paykel gas cooktop and electric convection oven refine the art of entertaining.',
      caption5:
        '“Plan H” is the north-west corner 2 bedroom, 2 bathroom plan showcased in the white “Farrow” scheme.',
      caption6:
        'Covered terraces are generously proportioned, allowing homeowners to create a private outdoor space that expresses their personal style. Enjoy the open air in comfort and extend year-round outdoor living.',
    },
    interior: {
      title1:
        'Bedrooms, dens and flex areas give homeowners the opportunity to utilize space however best fits your lifestyle.',
      title2:
        'By finessing the details, Boffo ensures that concealed spaces function as beautifully as the rest of the home. They are relentless in their pursuit of opportunities to work inspired storage solutions into every niche, nook and cranny.',
      caption1:
        'Ensuites combine the comfort of heated flooring with an abundance of storage, throughout expansive medicine cabinets and cabinetry drawers. Lighting is integrated into multi-paneled mirrors and tucked under cabinets. Floating vanities, shower niches and sleek fixtures create beautiful clean lines.',
      caption2:
        'Master bedrooms are private living quarters designed for serenity. Oversized windows put spectacular scenes within view.',
      caption3:
        'Flexible space can be transformed into a study, home office, additional pantry or extra storage room.',
      caption4: 'Guest room, kids room, art studio – the option is yours.',
      caption5:
        'Laundry rooms are well-lit, efficient spaces with custom storage solutions in each plan.',
      caption6:
        'Built-in wardrobes feature custom organization systems with integrated LED lighting.',
    },
    penthouse: {
      title: 'PENTHOUSE COLLECTION',
      text: 'Four unique penthouses place stunning forty-fifth storey views throughout incredibly spacious, thoughtfully designed homes.',
      caption1:
        'PH 02B has a spacious floorplan with ample dining and living areas, including a separate family TV room.',
      caption2:
        'PH 02B has a spacious floorplan with ample dining and living areas, including a separate family TV room.',
      caption3:
        'PH 02B features a bright and airy window kitchen: plus 36” appliance package, waterfall stone island with wide drawer cabinetry and wine fridge, and pantry with display niche.',
      caption4:
        'Penthouses offer beautiful sunset views with a partially enclosed atrium and extending balcony overlooking Burnaby Mountain.',
    },
    townhome: {
      title: 'TOWNHOME COLLECTION',
      text: 'This rare collection of townhomes feature wide, shallow layouts that provide the feeling of a single-family home experience.',
      caption1:
        'An obsession for detail is inherent in every Boffo home and impossible to miss. These unique townhomes feature wide, shallow layouts to provide the feeling of a single-family home experience.',
      caption2:
        'Townhome K is positioned on the lower level of the secluded north corner, offering convenient street access from North Road and a private door accessing a dedicated corridor leading to the same-level townhome parking stalls.',
      caption3:
        'Top level homes feature a courtyard entry with private outdoor space. Lush landscaping and thoughtfully designed fencing provide privacy and a beautiful sense of arrival at home.',
      caption4:
        'Abundant glazing fills the home with natural light and stunning unobstructed views.',
    },
  },
  amenities: {
    intro: {
      title: (
        <>
          Community spaces
          <br />
          to celebrate
          <br />
          everyday life
        </>
      ),
      text: 'Extensive amenities are spread over three floors, offering a variety of activities for all homeowners. Lounges are impeccably furnished—inside and out—providing inviting areas to gather, work, exercise and unwind. Beautiful plantings, ambient lighting, fire tables and deep overhangs blur indoor-outdoor definitions, creating a seamless flow of space and experience. Wellness facilities foster selfcare as part of daily life.',
      imageCaption:
        'The elegant first impression begins at the courtyard entry with intricate landscaping and illuminated water features creating a soothing soundscape of trickling water. The roundabout pick-up and drop-off leads to a cobbled port cochère and dramatic glass entryway. Inside, a full-service concierge completes the hotelstyle sense of arrival.',
    },
    outdoor: {
      title1:
        'Over-height glass between indoor and outdoor lounges creates a sense of continuous connection.',
      title2: 'A place in the sun … swim laps, cool off, relax and rejuvenate.',
      imageCaption1:
        'Families can enjoy a private family lounge that extends to the outdoor playground – perfect for a children’s birthday party.',
      imageCaption2:
        'With canopy-covered dining tables and lounge seating with fire tables, outdoor living is a pleasure in every season.',
      imageCaption3:
        'The 65-foot pool with hot tub, and pool house with steam, sauna and shower facilities.',
    },
    highlights: {
      title1:
        'Whether you’re getting family and friends together for a holiday dinner or collaborating on a presentation with your colleagues, amenity spaces are thoughtfully imagined and exquisitely furnished.',
      title2:
        'Healthy living is designed into the community, so residents can embrace wellness as part of daily life.',
      title3:
        'When it’s your turn to host, welcome friends to the stylish rooftop lounge, complete with full-service bar area, banquette seating and pool table.',
      imageCaption1:
        'Host larger gatherings in this five-star, indoor-outdoor dining area, where a full catering kitchen connects to barbecue stations on the garden terrace.',
      imageCaption2:
        'With a fireplace and natural light streaming through oversized windows, this workshare lounge redefines the concept of home office space.',
      imageCaption3:
        'The richly appointed lobby sets the tone for every arrival and departure. With a doublesided fireplace and ceilings that ascend to 21-feet, the lounge makes a refined setting for meeting friends before dinner or waiting for your Uber.',
      imageCaption4:
        'Overlooking the landscaped courtyard, the fully-equipped fitness facility provides top-of-the-line cardio equipment and weight training gear.',
      imageCaption5:
        'This serene setting with an abundance of natural light is a perfect space for yoga, Pilates, balance, stretching and meditation.',
      imageCaption6:
        'All homeowners can enjoy the best views on the 3,600sqft rooftop terrace. With this incredible panorama to catch the eye, any gathering is immediately an elevated event. An outdoor screening space, fire tables and landscaping provide unrivalled spaces for celebrations and everyday enjoyment.',
      imageCaption7:
        'With long-table entertaining and a beautiful curving sectional sofa, there’s space for everyone to relax and enjoy the occasion.',
      imageCaption8:
        'You cannot beat an outdoor screening area to enjoy a live concert, game or have a family movie night while star gazing at the same time.',
      imageCaption9:
        'In the rooftop lounge, glass NanoWall doors fold out of sight to seamlessly connect the outdoor terrace. Step from the interior lounge directly into a lush garden in the sky, where mesmerizing views make an exquisite extension to your private living space.',
    },
  },
  gallery: {
    title: 'Gallery',
    categories: ['Amenities', 'Homes', 'West Coquitlam'],
  },
  artistRendering: 'Artist Rendering',
};
