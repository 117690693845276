export const inputs = [
  {
    name: 'contact[first_name]',
    id: 'contact_first_name',
    label: 'First Name*',
    required: true,
    pattern: '[a-zA-Z ]+',
  },
  {
    name: 'contact[last_name]',
    id: 'contact_last_name',
    label: 'Last Name*',
    required: true,
    pattern: '[a-zA-Z ]+',
  },
  {
    name: 'contact[email]',
    id: 'contact_email',
    label: 'Email*',
    type: 'email',
    required: true,
  },
  {
    name: 'contact[phone]',
    id: 'contact_phone',
    label: 'Phone*',
    type: 'tel',
    required: true,
    pattern: '^[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$',
  },
  {
    name: 'contact[postcode]',
    id: 'contact_postcode',
    label: 'Postal Code*',
    required: true,
    pattern: '[a-zA-Z0-9 ]+',
  },
];

export const selects = [
  {
    label: 'Preferred Contact',
    name: 'contact[contact_preference]',
    id: 'contact_contact_preference',
    required: false,
    options: [
      {
        name: 'Email',
        value: 'Email',
      },
      {
        name: 'Phone',
        value: 'Phone',
      },
      {
        name: 'Text Message',
        value: 'Text Message',
      },
    ],
  },
  {
    label: 'How did you hear about Smith & Farrow?*',
    name: 'answers[5961][answers]',
    id: 'answers_5961',
    required: true,
    options: [
      {
        name: 'Online Search',
        value: 'Online Search',
      },
      {
        name: 'Online Advertising/Social Media',
        value: 'Online Advertising/Social Media',
      },
      {
        name: 'Print Advertising',
        value: 'Print Advertising',
      },
      {
        name: 'Signage',
        value: 'Signage',
      },
      {
        name: 'Email',
        value: 'Email',
      },
      {
        name: 'Friends & Family',
        value: 'Friends & Family',
      },
      {
        name: 'Realtor',
        value: 'Realtor',
      },
    ],
  },
  {
    label: 'Family Status',
    name: 'answers[6181][answers]',
    id: 'answers_6181',
    required: false,
    options: [
      {
        name: 'Single',
        value: 'Single',
      },
      {
        name: 'Couple',
        value: 'Couple',
      },
      {
        name: 'Couple with Children',
        value: 'Couple with Children',
      },
      {
        name: 'Single with Children',
        value: 'Single with Children',
      },
    ],
  },
];

export const radio = {
  label: 'Are you a realtor?*',
  name: 'agent',
  required: true,
  options: [
    {
      name: 'Yes',
      value: 'true',
    },
    {
      name: 'No',
      value: 'false',
    },
  ],
};
