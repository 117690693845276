import React from 'react';
import styled from 'styled-components';

import Intl from '../../components/Intl';
import TextHero from 'components/TextHero';
import Description from './sections/Description';
import Hubspot from './sections/Hubspot';

const Register = () => {
  return (
    <Intl>
      {(t, { locale }) => (
        <Root>
          <TextHero
            title={t.register.title}
            width="420px"
            background={require('src/assets/images/contact/register_pano_background.jpg')}
          />
          <Description />
          <Hubspot locale={locale} />
        </Root>
      )}
    </Intl>
  );
};

export default Register;

const Root = styled.div``;
