import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'src/styles/media';

const TextHero = (props) => {
  const { title, width, text, background } = props;
  return (
    <Root width={width} background={background}>
      <h1>{title}</h1>
      <span />
      {text && (
        <Content>
          {text.map((item, index) => (
            <p key={index} className="default">
              {item}
            </p>
          ))}
        </Content>
      )}
    </Root>
  );
};

TextHero.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  background: PropTypes.string,
  text: PropTypes.array,
};

export default TextHero;

const Root = styled.div`
  margin-top: 70px;
  background-color: ${({ theme }) => theme.color.whiteTwo};
  background-image: ${(props) =>
    props.background ? `url(${props.background})` : 'none'};
  background-position: center 40%;
  padding: 40px 20px;
  color: ${(props) => (props.background ? 'white' : 'black')};
  h1 {
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
  }
  span {
    display: block;
    width: 72px;
    height: 2px;
    background-color: ${(props) =>
      props.background ? 'white' : props.theme.color.grey};
    margin: 30px auto 0;
  }
  @media (min-width: ${media.tablet}) {
    padding: 60px;
    h1 {
      max-width: 385px;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 80px 0;
    margin-top: 136px;
    h1 {
      max-width: ${(props) => props.width};
    }
    span {
      margin: 40px auto 0;
    }
  }
  @media ${media.ipadPro} {
    padding: 60px;
    margin-top: 70px;
    h1 {
      max-width: 385px;
    }
  }
`;

const Content = styled.div`
  margin: 30px 0 0;
  p:first-of-type {
    margin-bottom: 20px;
  }
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
    width: 648px;
    p:first-of-type {
      margin-bottom: 0;
    }
    p {
      width: 290px;
      margin-bottom: 0;
    }
  }
  @media (min-width: ${media.desktop}) {
    margin: 40px auto 0;
    width: 1040px;
    p {
      width: 490px;
    }
  }
  @media ${media.ipadPro} {
    width: 90%;
    p:first-of-type {
      margin-bottom: 0;
    }
    p {
      width: 45%;
      margin-bottom: 0;
    }
  }
`;
