import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Container from 'components/Container';
import ScrollAnimation from 'components/ScrollAnimation';
import CaptionMedia from 'components/CaptionMedia';
import TitleCallout from 'components/Text/TitleCallout';

import media from 'src/styles/media';

const Outdoor = () => {
  return (
    <Intl>
      {(t) => (
        <Container>
          <Root>
            <ScrollAnimation>
              <TitleCallout title={t.amenities.outdoor.title1} />
              <Wrapper>
                <StyledCaptionMedia
                  image={require('src/assets/images/amenities/smith-and-farrow-amenity-kids-lounge.jpg')}
                  text={t.amenities.outdoor.imageCaption1}
                  width="25%"
                  relative
                  artistRendering
                />
                <CaptionMedia
                  image={require('src/assets/images/amenities/smith-and-farrow-amenity-outdoor-amenity.jpg')}
                  text={t.amenities.outdoor.imageCaption2}
                  width="50%"
                  relative
                  artistRendering
                />
              </Wrapper>
              <TitleCallout title={t.amenities.outdoor.title2} />
              <Wrapper>
                <CaptionMedia
                  image={require('src/assets/images/amenities/smith-and-farrow-amenity-pool-deck.jpg')}
                  text={t.amenities.outdoor.imageCaption3}
                  width="50%"
                  relative
                  artistRendering
                />
                <StyledCaptionMedia
                  image={require('src/assets/images/amenities/smith-and-farrow-amenity-pool.jpg')}
                  width="25%"
                  relative
                  artistRendering
                />
              </Wrapper>
            </ScrollAnimation>
          </Root>
        </Container>
      )}
    </Intl>
  );
};

const Root = styled.div``;

const Wrapper = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledCaptionMedia = styled(CaptionMedia)`
  @media (min-width: ${media.tablet}) {
    margin-top: 40px;
  }
  @media (min-width: ${media.desktop}) {
    margin-top: 120px;
  }
`;

export default Outdoor;
