import React from 'react';
import styled from 'styled-components';

import Intl from '../../../components/Intl';
import Container from 'components/Container';
import ScrollAnimation from 'components/ScrollAnimation';
import LineLink from 'components/Text/LineLink';
import Rendering from 'components/Layout/Rendering';

import media from 'src/styles/media';

const Content = (props) => {
  const { hidden } = props;
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <ScrollAnimation>
          <Container grey>
            <Root name="content" hidden={hidden}>
              <StyledRendering
                src={require('src/assets/images/home/smith-and-farrow-homes.jpg')}
                alt=""
              />
              <div>
                <h1>{t.home.amenities.title}</h1>
                <LineLink
                  to="/amenities"
                  text={t.home.amenities.ctaLabel}
                  black
                />
              </div>
            </Root>
          </Container>
        </ScrollAnimation>
      )}
    </Intl>
  );
};

export default Content;

const Root = styled.div`
  padding: 20px 0 40px;
  color: black;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  img {
    width: 100%;
    height: auto;
  }
  h1 {
    text-transform: uppercase;
    margin-bottom: 16px;
    max-width: 257px;
  }
  p.body {
    font-size: 17px;
    line-height: 1.41;
    letter-spacing: 0.94px;
  }
  .body {
    margin-bottom: 16px;
  }
  @media (min-width: ${media.tablet}) {
    padding: 40px 0;
    display: ${(props) => (props.hidden ? 'none' : 'flex')};
    align-items: center;
    h1 {
      max-width: 304px;
      margin-bottom: 30px;
    }
    p.body {
      font-size: 16px;
      line-height: 1.44;
      letter-spacing: 0.5px;
      max-width: 304px;
    }
    .body {
      margin-bottom: 32px;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 60px 0;
    h1 {
      max-width: 419px;
      margin-bottom: 23px;
    }
    p.body {
      max-width: 374px;
    }
  }
  @media ${media.ipadPro} {
    padding: 40px 0;
    h1 {
      max-width: 304px;
      margin-bottom: 30px;
    }
    p.body {
      max-width: 304px;
    }
  }
`;

const StyledRendering = styled(Rendering)`
  margin-bottom: 23px;
  width: 100%;
  @media (min-width: ${media.tablet}) {
    display: block;
    width: 50%;
    margin-right: 40px;
    margin-bottom: 0;
  }
  @media (min-width: ${media.desktop}) {
    margin-right: 120px;
  }
  @media ${media.ipadPro} {
    margin-right: 40px;
  }
`;
