import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'src/styles/media';

const Popup = (props) => {
  const {
    active,
    activeImage,
    handleNext,
    handlePrev,
    handleClose,
    images,
    innerRef,
    swiping,
  } = props;
  return (
    <Root
      style={{
        opacity: active ? 1 : 0,
        pointerEvents: active ? 'all' : 'none',
      }}
      ref={innerRef}
      id="popup"
      {...swiping}
    >
      <Close
        src={require('src/assets/images/gallery/gallery-close.svg')}
        alt="Close"
        onClick={handleClose}
      />
      <Wrapper>
        {images &&
          images.map((image, index) => (
            <Image
              src={image}
              alt="Gallery"
              key={index}
              main={index === 0}
              style={{
                opacity: index === activeImage ? 1 : 0,
                pointerEvents: index === activeImage ? 'inherit' : 'none',
              }}
            />
          ))}
      </Wrapper>
      <Arrows>
        <img
          src={require('src/assets/images/gallery/button-circle-left.svg')}
          alt="Prev"
          onClick={handlePrev}
        />
        <img
          src={require('src/assets/images/gallery/button-circle-right.svg')}
          alt="Next"
          onClick={handleNext}
        />
      </Arrows>
    </Root>
  );
};

Popup.propTypes = {
  active: PropTypes.bool,
  activeImage: PropTypes.number,
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
  handleClose: PropTypes.func,
  images: PropTypes.array,
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: white;
  transition: 0.3s ease-out;
  z-index: 10;
  touch-action: none;
  @media (min-width: ${media.desktop}) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
  }
`;

const Close = styled.img`
  width: 36px;
  height: 36px;
  position: absolute;
  top: 17px;
  right: 12px;
  @media (min-width: ${media.tablet}) {
    top: 30px;
    right: 20px;
  }
  @media (min-width: ${media.desktop}) {
    cursor: pointer;
    z-index: 2;
    top: 36px;
    right: 40px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 65vh;
  position: relative;
  margin: 0 20px;
  @media (min-width: ${media.tablet}) {
    margin: 0;
    height: 452px;
  }
  @media (min-width: ${media.desktop}) {
    margin: 0;
    height: 100%;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  display: block;
  transition: 0.5s ease-out;
  @media (min-width: ${media.desktop}) {
    max-height: ${(props) => (props.main ? '100%' : '70%')};
    width: ${(props) => (props.main ? '100%' : 'auto')};
    height: ${(props) => (props.main ? '100%' : 'auto')};
    object-fit: cover;
  }
`;

const Arrows = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 9px 15px;
  img {
    width: 44px;
    height: 44px;
    display: block;
  }
  @media (min-width: ${media.tablet}) {
    padding: 0 220px;
    bottom: 227px;
  }
  @media (min-width: ${media.desktop}) {
    bottom: 50%;
    transform: translateY(50%);
    padding: 0 35px;
    img {
      cursor: pointer;
    }
  }
`;

export default Popup;
