import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import media from 'src/styles/media';

const LineLink = (props) => {
  const { text, to, black, mobileBlack, twolines } = props;
  return (
    <Root to={to} black={black} mobileBlack={mobileBlack} twolines={twolines}>
      <p>{text}</p>
      <span />
    </Root>
  );
};

LineLink.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  black: PropTypes.bool,
  mobileBlack: PropTypes.bool,
  twolines: PropTypes.bool,
};

export default LineLink;

const Root = styled(Link)`
  display: flex;
  align-items: center;
  span {
    display: block;
    width: 40px;
    height: 2px;
    background-color: ${(props) =>
      props.black || props.mobileBlack ? 'black' : 'white'};
    margin-left: ${(props) => (props.twolines ? '-20px' : '7px')};
    transition: 0.3s ease;
    margin-top: ${(props) => (props.twolines ? '20px' : 0)};
  }
  p {
    max-width: ${(props) => (props.twolines ? '158px' : 'none')};
    font-family: ${({ theme }) => theme.font.family};
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: ${(props) => (props.black || props.mobileBlack ? 'black' : 'white')};
    transition: 0.3s ease;
  }
  &:hover {
    p {
      color: ${({ theme }) => theme.color.brownGrey};
    }
    span {
      margin-left: 7px;
      background-color: ${({ theme }) => theme.color.brownGrey};
    }
  }
  @media (min-width: ${media.tablet}) {
    p {
      max-width: none;
      color: ${(props) => (props.black ? 'black' : 'white')};
    }
    span {
      background-color: ${(props) => (props.black ? 'black' : 'white')};
      margin-left: 7px;
    }
  }
  @media (min-width: ${media.desktop}) {
    display: flex;
    align-items: center;
    p {
      max-width: none;
    }
    span {
      margin-left: 7px;
      margin-top: 0;
    }
  }
`;
