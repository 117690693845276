import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';

const Container = (props) => {
  return <Root {...props}>{props.children}</Root>;
};

export default Container;

const Root = styled.div`
  padding: 0 20px;
  background-color: ${(props) =>
    props.grey ? props.theme.color.whiteTwo : 'transparent'};
  @media (min-width: ${media.tablet}) {
    padding: 0 40px;
  }
  @media (min-width: ${media.desktop}) {
    padding: 0 60px;
  }
`;
