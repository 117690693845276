import React from 'react';

export default {
  name: 'Simplified Chinese',
  menu: [
    {
      title: 'BOFFO 巨献',
      to: '/boffo',
    },
    {
      title: 'Homes',
      to: '/homes',
      enOnly: true,
    },
    // {
    //   title: 'Floorplans',
    //   to: '/floorplans',
    // },
    {
      title: 'Amenities',
      to: '/amenities',
      enOnly: true,
    },
    {
      title: '西高贵林',
      to: '/community',
    },
    {
      title: 'Gallery',
      to: '/gallery',
    },
    {
      title: 'Contact',
      to: '/contact',
    },
    {
      title: '登记',
      to: '/register',
    },
  ],
  registerLabel: '登记',
  home: {
    hero: {
      comingSoon: '即将公开',
      slidesSubtitle: ['匠心独具', '都会之心', '50 年历史传奇'],
      slides: [
        {
          src: require('src/assets/images/home/hero-form-concept-box_sc.svg'),
          mobileSrc: require('src/assets/images/home/mobile_form-concept_sc.svg'),
          subtitle: '匠心独具',
        },
        {
          src: require('src/assets/images/home/hero-time-place-box_sc.svg'),
          mobileSrc: require('src/assets/images/home/mobile_form-place_sc.svg'),
          subtitle: '都会之心',
        },
        {
          src: require('src/assets/images/home/hero-time-commitment-box_sc.svg'),
          mobileSrc: require('src/assets/images/home/mobile_form-commitment_sc.svg'),
          subtitle: '50 年历史传奇',
        },
      ],
    },
    content: {
      title: '好事成双',
      body: '由知名发展商 Boffo 精心打造的 Smith & Farrow ，是一个融合公寓及城市屋房型的大型社区。其规划完善的一至三睡房住家，不仅与Burquitlam天车站及西门菲沙大学为邻，周边更被各种生活便利环绕。社区内也设计了一系列的住户独享的休闲配套，各种细节都卓越地呈现了 Boffo 所坚持的传统价值，以及无与伦比的杰出工艺。',
      button: 'View Floorplans',
    },
    homes: {
      title: 'Just one look, and you know',
      ctaLabel: 'Explore the Homes',
    },
    amenities: {
      title: 'Community spaces to celebrate everyday life',
      ctaLabel: 'View Amenities',
    },
    community: {
      title: (
        <>
          天生一对
          <br />
          共享钟爱
        </>
      ),
      ctaLabel: '探索社区',
    },
    about: {
      title: (
        <>
          永续打造
          <br />
          美丽传奇
        </>
      ),
      ctaLabel: '关于 BOFFO',
    },
  },
  registerBanner: {
    title: (
      <>
        家園之美
        <br />
        待您探究
      </>
    ),
    buttonLabel: '请即登记',
    subTitle: 'Sales Gallery',
    text: (
      <>
        Open daily (except Friday)
        <br />
        12pm – 6pm
      </>
    ),
    address: (
      <>
        2100 Springer Avenue, Burnaby
        <br />
        (southeast corner at Lougheed)
      </>
    ),
  },
  community: {
    hero: {
      textFile: 'hero_community_tc.svg',
    },
    main: {
      title: '家园之美 待您探究',
      body: '这个座落在本拿比与高贵林之间的现代化社区，不仅被美丽山林与静谧的天然林地环绕，便捷的天车系统更近在咫尺，让您来去自如、毫不费力！',
    },
    video: {
      title: (
        <>
          更接近
          <br />
          生活中的
          <br />
          一切美好
        </>
      ),
      iconName: 'play-video_sc.png',
    },
    highlights: {
      title: '在您身边',
      body: '座落在城市中被天然美景环绕的优美地段，Smith & Farrow 地处静谧的住宅区内，不仅能居高临下地饱览大片美景，各种美食餐厅、超市、名校、公园、游乐场及天然绿径就在身边。以下是这个地段中，最为人津津乐道且备受喜爱的好去处。',
      imageCaptions: [
        '体现花艺之美的 Vivio 花卉艺廊。',
        '西门菲沙大学（SFU）本拿比校区旁、本拿比山公园当中的“天神游乐场”（Kamui Mintara）装置艺术。',
        '提供咖啡、手工派及甜点的美味去处 Gabi & Jules。',
        '不论是周日早午餐，或者在公园野餐，许多精彩的回忆都在此等待您的开创。',
        '从您的新家出发，徒步两分钟便可抵达 Burquitlam 天车站，由此至温市中心只要短短的 35 分钟车程。',
        '西门菲沙大学不仅学术地位崇高，更有特殊建筑及壮丽美景',
        '占地 435 英亩的 Mundy 公园，是高贵林最大的公园，当中还包含户外泳池、野餐区、球场及环绕两个优美湖泊的绿径网络。',
        '被静谧的天然美景环抱，与湖泊、小溪及山林绿径为邻。',
        'Smith & Farrow 的 360 度优美景致。',
      ],
    },
    map: {
      title: '四通八达的中心地段',
    },
    neighbourhood: {
      'West Coquitlam': {
        title: '西高贵林',
        description:
          '这个现代化的都会区，不仅坐拥绿意盎然的森林山坡，更有直通大温的 SkyTrain 捷运系统。来往于各地之间，毫不费力。',
      },
      'Port Moody': {
        title: '满地宝',
        description:
          '历史悠久的满地宝，因具有传统文化、文艺气息、水畔绿径及多间闻名的特色酿酒厂，而备受大众喜爱。',
      },
      'Burnaby Mountain/SFU': {
        title: '本拿比山/西门菲沙大学',
        description:
          '本拿比山占地 576 英亩，有 26 条多用途绿径穿梭其在其林区之间，是大温最受欢迎的登山及单车去处之一，也是加国名列前茅的西门菲沙大学之所在地。',
      },
      Coquitlam: {
        title: '高贵林',
        description:
          '发展迅速的高贵林，以徒步可达的各种社区便利闻名。不论是 Austin 高地的特色商店及美味餐厅，或是深受大温民众喜爱的公园绿地或湖泊，肯定都会让您流连忘返。',
      },
    },
    neighbourhoodProfiles: '社区概况',
  },
  boffo: {
    hero: {
      title: (
        <>
          50 年悠久历史
          <br />
          为您打造温馨家园
        </>
      ),
      bodyParagraphs: [
        '过去 50 年以来，Boffo 以卓越信誉广获好评。我们以贴心的设计及精美的工艺，为业主带来无与伦比的体验。在每一间由 Boffo 所打造的住家当中，都能见到我们对细节的坚持，并借此呈现最尊贵的豪华成果。',
        '每一个由 Boffo 打造的社区，皆包含其独特愿景。我们凭藉丰富经验，以人为本，为业主打造最温馨的家园。为了让每个社区都能源远流长，我们更提供完善的售后服务，因为我们相信每个作品都是代表作，也反映出我们与众不同的杰出特质。',
      ],
    },
    projects: [
      {
        title: 'The Smithe',
        caption:
          '位于温市中心的艺术及展演特区内，The Smithe 社区内匠心独具的 2 及 3 房府邸，皆为呈现前所未有的豪华生活体验而打造。',
      },
      {
        title: 'Modello',
        caption:
          '以荣获大奖肯定的 Jewel 社区为基础，Modello 为本拿比的豪华公寓社区建立了新的标竿。它以引人瞩目的建筑设计、创新的造景及艺术设计，成为新落成的 Beresford 艺术走廊中最夺目耀眼的亮点。',
      },
      {
        caption: 'Adagio I + II、南素里',
      },
      {
        caption: 'Jewel I + II、本拿比',
      },
      {
        title: (
          <>
            荣获
            <br />
            城市高楼社区奖
            <br />
            肯定
          </>
        ),
      },
      {
        caption: 'Modello、本拿比 | 2018 年 UDI 都会发展研究所卓越奖',
      },
      {
        caption: 'Artemisia、温市中心',
      },
      {
        caption: 'The Smithe、温市中心',
      },
      {
        caption: 'Jewel 城市屋',
      },
      {
        caption:
          'Jewel I + II、本拿比 | 荣获 UDI 都会发展研究所、大温房屋建筑商协会及加拿大房屋建筑商协会评选为多单位高层社区第一名。',
      },
    ],
    developers: [
      {
        name: 'Chris Dikeakos 建筑设计公司',
        description:
          '高度专业的 Chris Dikeakos 建筑设计公司（CDA)，曾获得许多奖项肯定，CDA 打造的许多项目，更让他们成为北美多单位与高层社区设计的领导者。Smith & Farrow 是 Boffo 与 CDA 第八次共同合作的项目。',
      },
      {
        name: 'mckinley',
        description:
          'Mckinley 采用独特的沉浸实验性创新手法，改良传统式的设计方式，为创意顾问开创了一条崭新道路。他们专精于建筑、室内设计及品牌包装，也是酒店、餐馆及零售商铺家具设计专家，更擅长于打造多用途项目的相关设计。Boffo 与 Mckinley 的合作，将为 Smith & Farrow 带来令人耳目一新的室内设计。',
      },
    ],
  },
  register: {
    title: '获得最新讯息',
    description:
      '请填写以下栏目，以便获取关于本项目的最新消息、特别活动通知及促销讯息。您提交的所有资讯将被保密。',
  },
  form: {
    inputs: [
      {
        // only translate the label please :)
        name: 'contact[first_name]',
        id: 'contact_first_name',
        label: '名字*',
        required: true,
        pattern: '[a-zA-Z ]+',
      },
      {
        name: 'contact[last_name]',
        id: 'contact_last_name',
        label: '姓氏*',
        required: true,
        pattern: '[a-zA-Z ]+',
      },
      {
        name: 'contact[email]',
        id: 'contact_email',
        label: '电邮*',
        type: 'email',
        required: true,
      },
      {
        name: 'contact[phone]',
        id: 'contact_phone',
        label: '电话*',
        type: 'tel',
        required: true,
        pattern: '^[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$',
      },
      {
        name: 'contact[postcode]',
        id: 'contact_postcode',
        label: '邮编*',
        required: true,
        pattern: '[a-zA-Z0-9 ]+',
      },
    ],
    selects: [
      // only translate the label and option name please
      {
        label: '您偏好的联络方式',
        name: 'contact[contact_preference]',
        id: 'contact_contact_preference',
        required: false,
        options: [
          {
            name: '电邮',
            value: 'Email',
          },
          {
            name: '电话',
            value: 'Phone',
          },
          {
            name: '短信',
            value: 'Text Message',
          },
        ],
      },
      {
        label: '您如何得知有关 Smith & Farrow 的讯息？*',
        name: 'answers[5961][answers]',
        id: 'answers_5961',
        required: true,
        options: [
          {
            name: '线上搜索',
            value: 'Online Search',
          },
          {
            name: '线上广告/社交网站',
            value: 'Online Advertising/Social Media',
          },
          {
            name: '印刷广告',
            value: 'Print Advertising',
          },
          {
            name: '广告看板',
            value: 'Signage',
          },
          {
            name: '电邮',
            value: 'Email',
          },
          {
            name: '亲友告知',
            value: 'Friends & Family',
          },
          {
            name: '地产经纪',
            value: 'Realtor',
          },
        ],
      },
      {
        label: '您的家庭状况',
        name: 'answers[6181][answers]',
        id: 'answers_6181',
        required: false,
        options: [
          {
            name: '单身',
            value: 'Single',
          },
          {
            name: '已婚',
            value: 'Couple',
          },
          {
            name: '已婚有小孩',
            value: 'Couple with Children',
          },
          {
            name: '单身有小孩',
            value: 'Single with Children',
          },
        ],
      },
    ],
    radio: {
      label: '您是否为地产经纪？*',
      name: 'agent',
      required: true,
      options: [
        {
          name: '是',
          value: 'true',
        },
        {
          name: '否',
          value: 'false',
        },
      ],
    },
    comments: '其他留言',
    required: '此为必填栏。',
    submit: '提交表格',
    insider:
      '我同时愿意成为 Boffo 社区的一分子，并且愿意收到 Boffo 公司其他项目的相关消息。',
    consent:
      '我愿意接收由 The Boffo 集团发出的商业电邮，同时明白可随时依照下方英文条款说明，取消订阅此类电邮。',
    disclaimer:
      'Boffo Developments Ltd #200 - 4580 Hastings Street, Burnaby, BC V5C 2K4 By opting-in you consent to Boffo Developments Ltd and their current and future affiliates contacting you with information about their current and future products and services. Your information will not be shared, sold or distributed or otherwise to any other parties. If at any time you would no longer like to receive updates, you can update your profile by clicking the unsubscribe link at the bottom of any future email correspondence. Sales powered by MLA Canada Realty.',
  },
  thankYou: {
    title: '感谢您的登记',
    body: '您将优先收到各类项目最新讯息。',
    back: '回到首页',
  },
  footer: {
    title: '体验 Boffo',
    subtitle: '卑诗省精美豪邸的创造专家',
    text: 'THE DEVELOPER RESERVES THE RIGHT TO MODIFY THE BUILDING DESIGN, FLOORPLANS, PRICES, FEATURES AND SPECIFICATIONS WITHOUT NOTICE. THIS IS NOT AN OFFERING FOR SALE. ANY SUCH OFFERING CAN ONLY BE MADE BY WAY OF DISCLOSURE STATEMENT. E. & O.E. Sales powered by MLA Canada Realty.',
  },
  homes: {
    intro: {
      title: (
        <>
          Just One Look,
          <br />
          and You Know
        </>
      ),
      text: 'Boffo is unwavering in their commitment to creating unprecedented living experiences through artistic design and enduring quality. Standout floorplans place stunning views in front of tranquil master suites, expansive living areas, spacious kitchens and large terraces. Abundant glazing fills the residence with natural light and custom millwork makes every inch of the space exceptional.',
      imageCaption1:
        'Boffo’s signature floorplan, “Plan G”, has a uniquely wide and shallow layout with 37-feet of continuous windows spanning across the extremely spacious living area. This west-facing plan is available with 2 or 3 bedrooms with 2 bathrooms and is showcased in the darker “Smith” scheme.',
      imageCaption2:
        'Entry niches with integrated lighting, magnetic board and charging station allow you to keep track of all on-the-go essentials.',
      imageCaption3:
        'Accept no compromise in the place you call home. Smith & Farrow raises the bar for elegant living, with exacting standards of quality in every room. Over-height doors, 9-foot ceilings, open-concept design and wide-plank European flooring throughout contribute to the residence’s grand scale.',
    },
    kitchen: {
      title1:
        'Designed with chefs in mind, Boffo kitchens are renowned for their impeccable style and function. Everything is included - superior features are standard here.',
      title2:
        'Brilliant storage solutions, integrated under-cabinet lighting and sophisticated features combine for a space that radiates beauty while performing to the highest standards.',
      title3:
        'Bright and airy kitchen windows, pantry walls with display niches, and custom kitchen tables are featured throughout a selection of floorplans.',
      caption1:
        'Generous working surfaces, locally-crafted custom cabinetry and integrated appliances take culinary excitement to new heights. The stage is set for elegant entertaining and inspired family meals.',
      caption2:
        'Cabinets are built to maximize functionality, with spacesaving corners and Häfele pull-out storage solutions.',
      caption3:
        'Undermount 27-inch sink with fixtures in polished chrome or modern champagne—your choice.',
      caption4:
        'Five-burner Fisher & Paykel gas cooktop and electric convection oven refine the art of entertaining.',
      caption5:
        '“Plan H” is the north-west corner 2 bedroom, 2 bathroom plan showcased in the white “Farrow” scheme.',
      caption6:
        'Covered terraces are generously proportioned, allowing homeowners to create a private outdoor space that expresses their personal style. Enjoy the open air in comfort and extend year-round outdoor living.',
    },
    interior: {
      title1:
        'Bedrooms, dens and flex areas give homeowners the opportunity to utilize space however best fits your lifestyle.',
      title2:
        'By finessing the details, Boffo ensures that concealed spaces function as beautifully as the rest of the home. They are relentless in their pursuit of opportunities to work inspired storage solutions into every niche, nook and cranny.',
      caption1:
        'Ensuites combine the comfort of heated flooring with an abundance of storage, throughout expansive medicine cabinets and cabinetry drawers. Lighting is integrated into multi-paneled mirrors and tucked under cabinets. Floating vanities, shower niches and sleek fixtures create beautiful clean lines.',
      caption2:
        'Master bedrooms are private living quarters designed for serenity. Oversized windows put spectacular scenes within view.',
      caption3:
        'Flexible space can be transformed into a study, home office, additional pantry or extra storage room.',
      caption4: 'Guest room, kids room, art studio – the option is yours.',
      caption5:
        'Laundry rooms are well-lit, efficient spaces with custom storage solutions in each plan.',
      caption6:
        'Built-in wardrobes feature custom organization systems with integrated LED lighting.',
    },
    penthouse: {
      title: 'PENTHOUSE COLLECTION',
      text: 'Four unique penthouses place stunning forty-fifth storey views throughout incredibly spacious, thoughtfully designed homes.',
      caption1:
        'PH 02B has a spacious floorplan with ample dining and living areas, including a separate family TV room.',
      caption2:
        'PH 02B has a spacious floorplan with ample dining and living areas, including a separate family TV room.',
      caption3:
        'PH 02B features a bright and airy window kitchen: plus 36” appliance package, waterfall stone island with wide drawer cabinetry and wine fridge, and pantry with display niche.',
      caption4:
        'Penthouses offer beautiful sunset views with a partially enclosed atrium and extending balcony overlooking Burnaby Mountain.',
    },
    townhome: {
      title: 'TOWNHOME COLLECTION',
      text: 'This rare collection of townhomes feature wide, shallow layouts that provide the feeling of a single-family home experience.',
      caption1:
        'An obsession for detail is inherent in every Boffo home and impossible to miss. These unique townhomes feature wide, shallow layouts to provide the feeling of a single-family home experience.',
      caption2:
        'Townhome K is positioned on the lower level of the secluded north corner, offering convenient street access from North Road and a private door accessing a dedicated corridor leading to the same-level townhome parking stalls.',
      caption3:
        'Top level homes feature a courtyard entry with private outdoor space. Lush landscaping and thoughtfully designed fencing provide privacy and a beautiful sense of arrival at home.',
      caption4:
        'Abundant glazing fills the home with natural light and stunning unobstructed views.',
    },
  },
  amenities: {
    intro: {
      title: (
        <>
          Community spaces
          <br />
          to celebrate
          <br />
          everyday life
        </>
      ),
      text: 'Extensive amenities are spread over three floors, offering a variety of activities for all homeowners. Lounges are impeccably furnished—inside and out—providing inviting areas to gather, work, exercise and unwind. Beautiful plantings, ambient lighting, fire tables and deep overhangs blur indoor-outdoor definitions, creating a seamless flow of space and experience. Wellness facilities foster selfcare as part of daily life.',
      imageCaption:
        'The elegant first impression begins at the courtyard entry with intricate landscaping and illuminated water features creating a soothing soundscape of trickling water. The roundabout pick-up and drop-off leads to a cobbled port cochère and dramatic glass entryway. Inside, a full-service concierge completes the hotelstyle sense of arrival.',
    },
    outdoor: {
      title1:
        'Over-height glass between indoor and outdoor lounges creates a sense of continuous connection.',
      title2: 'A place in the sun … swim laps, cool off, relax and rejuvenate.',
      imageCaption1:
        'Families can enjoy a private family lounge that extends to the outdoor playground – perfect for a children’s birthday party.',
      imageCaption2:
        'With canopy-covered dining tables and lounge seating with fire tables, outdoor living is a pleasure in every season.',
      imageCaption3:
        'The 65-foot pool with hot tub, and pool house with steam, sauna and shower facilities.',
    },
    highlights: {
      title1:
        'Whether you’re getting family and friends together for a holiday dinner or collaborating on a presentation with your colleagues, amenity spaces are thoughtfully imagined and exquisitely furnished.',
      title2:
        'Healthy living is designed into the community, so residents can embrace wellness as part of daily life.',
      title3:
        'When it’s your turn to host, welcome friends to the stylish rooftop lounge, complete with full-service bar area, banquette seating and pool table.',
      imageCaption1:
        'Host larger gatherings in this five-star, indoor-outdoor dining area, where a full catering kitchen connects to barbecue stations on the garden terrace.',
      imageCaption2:
        'With a fireplace and natural light streaming through oversized windows, this workshare lounge redefines the concept of home office space.',
      imageCaption3:
        'The richly appointed lobby sets the tone for every arrival and departure. With a doublesided fireplace and ceilings that ascend to 21-feet, the lounge makes a refined setting for meeting friends before dinner or waiting for your Uber.',
      imageCaption4:
        'Overlooking the landscaped courtyard, the fully-equipped fitness facility provides top-of-the-line cardio equipment and weight training gear.',
      imageCaption5:
        'This serene setting with an abundance of natural light is a perfect space for yoga, Pilates, balance, stretching and meditation.',
      imageCaption6:
        'All homeowners can enjoy the best views on the 3,600sqft rooftop terrace. With this incredible panorama to catch the eye, any gathering is immediately an elevated event. An outdoor screening space, fire tables and landscaping provide unrivalled spaces for celebrations and everyday enjoyment.',
      imageCaption7:
        'With long-table entertaining and a beautiful curving sectional sofa, there’s space for everyone to relax and enjoy the occasion.',
      imageCaption8:
        'You cannot beat an outdoor screening area to enjoy a live concert, game or have a family movie night while star gazing at the same time.',
      imageCaption9:
        'In the rooftop lounge, glass NanoWall doors fold out of sight to seamlessly connect the outdoor terrace. Step from the interior lounge directly into a lush garden in the sky, where mesmerizing views make an exquisite extension to your private living space.',
    },
  },
  gallery: {
    title: 'Gallery',
    categories: ['Amenities', 'Homes', 'West Coquitlam'],
  },
  artistRendering: 'Artist Rendering',
};
