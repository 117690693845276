export default [
  {
    background: require('src/assets/images/gallery/gallery-amenities.jpg'),
    images: [
      require('src/assets/images/gallery/gallery-amenities.jpg'),
      require('src/assets/images/gallery/amenities/smith-and-farrow-amenity-entrance.jpg'),
      require('src/assets/images/gallery/amenities/smith-and-farrow-amenity-outdoor-amenity.jpg'),
      require('src/assets/images/gallery/amenities/smith-and-farrow-amenity-workspace.jpg'),
      require('src/assets/images/gallery/amenities/smith-and-farrow-amenity-kitchen.jpg'),
      require('src/assets/images/gallery/amenities/smith-and-farrow-amenity-pool.jpg'),
      require('src/assets/images/gallery/amenities/smith-and-farrow-amenity-pool-deck.jpg'),
      require('src/assets/images/gallery/amenities/smith-and-farrow-amenity-kids-lounge.jpg'),
      require('src/assets/images/gallery/amenities/smith-and-farrow-amenity-gym.jpg'),
      require('src/assets/images/gallery/amenities/smith-and-farrow-amenity-yoga.jpg'),
      require('src/assets/images/gallery/amenities/smith-and-farrow-amenity-hummingbird.jpg'),
      require('src/assets/images/gallery/amenities/smith-and-farrow-amenity-outdoor-media-screen.jpg'),
      require('src/assets/images/gallery/amenities/smith-and-farrow-amenity-interior-lounge.jpg'),
      require('src/assets/images/gallery/amenities/smith-and-farrow-amenity-interior-rooftop-lounge.jpg'),
    ],
  },
  {
    background: require('src/assets/images/gallery/gallery-homes.jpg'),
    images: [
      require('src/assets/images/gallery/gallery-homes.jpg'),
      require('src/assets/images/gallery/homes/Smith+Farrow_002.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-living.jpg'),
      require('src/assets/images/gallery/homes/Smith+Farrow_005.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-kitchen.jpg'),
      require('src/assets/images/gallery/homes/Smith+Farrow_010.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-ambient-lighting.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-kitchen-cabinets.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-gas-cooktop.jpg'),
      require('src/assets/images/gallery/homes/Smith+Farrow_023.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-countertop.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-display-niche.jpg'),
      require('src/assets/images/gallery/homes/Smith+Farrow_025.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-master.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-closet.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-bathroom-vanity.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-glass-shower.jpg'),
      require('src/assets/images/gallery/homes/Smith+Farrow_028.jpg'),
      require('src/assets/images/gallery/homes/Smith+Farrow_029.jpg'),
      require('src/assets/images/gallery/homes/Smith+Farrow_030.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-second-bedroom.jpg'),
      require('src/assets/images/gallery/homes/Smith+Farrow_033.jpg'),
      require('src/assets/images/gallery/homes/Smith+Farrow_021.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-wall-niche.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-office.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-laundry-room.jpg'),
      require('src/assets/images/gallery/homes/smith-and-farrow-interior-patio.jpg'),
      require('src/assets/images/gallery/homes/Smith+Farrow_031b.jpg'),
      require('src/assets/images/gallery/homes/ph-1.jpg'),
      require('src/assets/images/gallery/homes/ph-2.jpg'),
      require('src/assets/images/gallery/homes/ph-3.jpg'),
      require('src/assets/images/gallery/homes/ph-4.jpg'),
      require('src/assets/images/gallery/homes/townhome-1.jpg'),
      require('src/assets/images/gallery/homes/townhome-2.jpg'),
      require('src/assets/images/gallery/homes/townhome-3.jpg'),
      require('src/assets/images/gallery/homes/townhome-4.jpg'),
    ],
  },
  {
    background: require('src/assets/images/gallery/gallery-community-centred.jpg'),
    images: [
      require('src/assets/images/gallery/gallery-community-centred.jpg'),
      require('src/assets/images/gallery/community/west-coquitlam-skytrain.jpg'),
      require('src/assets/images/gallery/community/west-coquitlam-safeway.jpg'),
      require('src/assets/images/gallery/community/gabi-and-jules.jpg'),
      require('src/assets/images/gallery/community/burnaby-mountain-sfu.jpg'),
      require('src/assets/images/gallery/community/burnaby-mountain.jpg'),
      require('src/assets/images/gallery/community/west-coquitlam-tennis.jpg'),
      require('src/assets/images/gallery/community/west-coquitlam-vancouver-golf-club.jpg'),
      require('src/assets/images/gallery/community/west-coquitlam-stoney-creek.jpg'),
      require('src/assets/images/gallery/community/mundy-park.jpg'),
      require('src/assets/images/gallery/community/west-coquitlam-mountain-view-park.jpg'),
      require('src/assets/images/gallery/community/pomo-brewery.jpg'),
      require('src/assets/images/gallery/community/pomo-tacos.jpg'),
      require('src/assets/images/gallery/community/pomo-rocky-point-walkway.jpg'),
      require('src/assets/images/gallery/community/pomo-rocky-point-pier.jpg'),
      require('src/assets/images/gallery/community/pomo-vivios.jpg'),
      require('src/assets/images/gallery/community/port-moody-vivios-flowers.jpg'),
    ],
  },
];
