import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Hamburger = props => {
  const { active, handleClick } = props;
  return (
    <Root {...props} onClick={handleClick} active={active}>
      <span />
      <span />
      <span />
    </Root>
  );
};

Hamburger.propTypes = {
  active: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default Hamburger;

const Root = styled.div`
  width: 26px;
  height: 15px;
  position: relative;
  cursor: pointer;
  span {
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.grey};
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    transition: 0.2s ease-in-out;
  }
  span:first-of-type {
    top: ${props => (props.active ? '50%' : 0)};
    transform: ${props => (props.active ? 'rotate(45deg)' : 'none')};
    transition-property: top, transform;
    transition-delay: ${props => (props.active ? '0s, 0.2s' : '0.2s, 0s')};
  }
  span:nth-of-type(2) {
    top: 50%;
    opacity: ${props => (props.active ? 0 : 1)};
  }
  span:last-of-type {
    top: ${props => (props.active ? '50%' : '100%')};
    transform: ${props => (props.active ? 'rotate(-45deg)' : 'none')};
    transition-property: top, transform;
    transition-delay: ${props => (props.active ? '0s, 0.2s' : '0.2s, 0s')};
  }
`;
