import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Hero from 'components/Hero';
import RegisterTagline from 'components/RegisterTagline';
import Intro from './sections/Intro';
import Kitchen from './sections/Kitchen';
import Interiors from './sections/Interiors';
import Penthouse from './sections/Penthouse';
import Townhome from './sections/Townhome';

const Homes = () => {
  return (
    <Intl>
      {(t, { locale }) => (
        <Root>
          <Hero
            textImage={require('src/assets/images/homes/hero_tagline_homes.svg')}
            background={require('src/assets/images/homes/smith-and-farrow-homes-hero.jpg')}
          />
          <Intro />
          <Kitchen />
          <Interiors />
          <Penthouse />
          <Townhome />
          <RegisterTagline />
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div``;

export default Homes;
