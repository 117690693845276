import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';

const Input = props => {
  const { input } = props;
  return (
    <Root>
      <label htmlFor={input.id}>{input.label}</label>
      <p>This field is required</p>
      <input
        name={input.name}
        id={input.id ? input.id : input.name}
        required={input.required}
        type={input.type ? input.type : 'text'}
        pattern={input.pattern && input.pattern}
        className="input"
      />
    </Root>
  );
};

export default Input;

const Root = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 18px;
  position: relative;
  @media (min-width: ${media.tablet}) {
    width: 48.5%;
  }
  @media (min-width: ${media.desktop}) {
    width: 43.9%;
  }
  input {
    width: 100%;
    height: 50px;
    border: 1px solid ${({ theme }) => theme.color.brownGrey};
    border-radius: 0;
    background-color: transparent;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    letter-spacing: 1.2px;
    padding-left: 10px;
    appearance: none;
  }
  label {
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    letter-spacing: 1.2px;
    margin-bottom: 7px;
    @media (min-width: ${media.desktop}) {
      font-size: 15px;
      letter-spacing: 1.5px;
    }
  }
  p {
    color: #ff0000;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    letter-spacing: 0.5px;
    opacity: 0;
    transition: 0.3s ease;
    margin-left: 5px;
    @media (min-width: ${media.tablet}) {
      font-size: 16px;
      letter-spacing: 0.8px;
    }
  }
`;
