import React from 'react';
import styled from 'styled-components';

import HubspotForm from 'react-hubspot-form';

import media from 'src/styles/media';

const Register = () => {
  return (
    <Root>
      <HubspotForm
        portalId="7763850"
        // PC FORM
        formId="120ae5bf-61f1-400b-8178-7a017e329469"
        onSubmit={() => {
          window.location.replace(
            'https://boffo.ca/smithandfarrow/pc/thankyou/'
          );
        }}
      />
    </Root>
  );
};

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.offWhite};
  padding: 44px 20px 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${media.tablet}) {
    padding: 90px 40px 90px;
  }
  @media (min-width: ${media.desktop}) {
    padding: 65px 147px 40px;
  }

  fieldset {
    max-width: none !important;
  }

  .form-columns-2 {
    @media (min-width: ${media.tablet}) {
      /* display: flex; */
      /* justify-content: space-between; */
      position: relative;
      > div {
        width: 47.5% !important;
        display: inline-block;
        &:nth-of-type(2) {
          margin-left: 5%;
        }
      }
    }
    @media (min-width: ${media.desktop}) {
      > div {
        width: 45% !important;
        &:nth-of-type(2) {
          margin-left: 10%;
        }
      }
    }
  }

  //submit button container
  .actions {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .form-columns-1 {
    ${(props) =>
      props.locale === 'tc' || props.locale === 'sc'
        ? `
      textarea {
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.color.brownGrey};
        border-radius: 0;
        appearance: none;
        font-family: ${({ theme }) => theme.font.family};
        font-size: 14px;
        letter-spacing: 1.2px;
        padding: 10px;
        width: 100% !important;
        height: 158px !important;
        display: block;
        margin-right: 0 !important;
        @media (min-width: ${media.tablet}) {
          width: 289px !important;
          height: 166px !important;
        }
        @media (min-width: ${media.desktop}) {
          font-size: 15px;
          letter-spacing: 1.5px;
        }
      }
      @media (min-width: ${media.tablet}) {
        display: block;
        position: relative;
        &:nth-of-type(5) {
          width: 47.5%;
        }
      }
      @media (min-width: ${media.desktop}) {
        display: block;
        // ARE YOU A REALTOR
        &:nth-of-type(5) {
          width: 45%;
        }
      }
    `
        : `
      @media (min-width: ${media.tablet}) {
        display: inline-block;
        // HOW DID YOU HEAR ABOUT US
        &:nth-of-type(4) {
          width: 47.5%;
        }
        // ARE YOU A REALTOR
        &:nth-of-type(5) {
          width: 47.5%;
          margin-left: 5%;
        }
        // ARE YOU A REALTOR
        &:nth-of-type(9) {
          width: 100%;
        }
      }
      @media (min-width: ${media.desktop}) {
        display: inline-block;
        position: relative;
        // HOW DID YOU HEAR ABOUT US
        &:nth-of-type(4) {
          width: 45%;
        }
        // ARE YOU A REALTOR
        &:nth-of-type(5) {
          width: 45%;
          margin-left: 10%;
        }
      }
    `}

    span {
      position: relative;
      top: 5px;
      @media (min-width: ${media.tablet}) {
        margin-bottom: 20px;
      }
      p {
        position: relative;
        top: -6px;
        @media (min-width: ${media.tablet}) {
          top: -4px;
        }
        @media (min-width: ${media.desktop}) {
          top: 0;
        }
      }
    }

    p,
    span {
      font-size: 10px;
      letter-spacing: normal !important;
      line-height: 10px !important;
      @media (min-width: ${media.tablet}) {
        font-size: 11px;
      }
    }
  }

  .hs_smith_farrow_how_did_you_hear_about_us_,
  .hs_are_you_a_realtor_ {
    span {
      display: inline-block;
      font-family: ${({ theme }) => theme.font.family};
      font-size: 14px;
      letter-spacing: 1.2px;
      margin-bottom: 7px;
      @media (min-width: ${media.desktop}) {
        font-size: 15px;
        letter-spacing: 1.5px;
      }
    }
  }

  .input {
    margin-right: 0 !important;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 10px;
    color: #666666;
    /* margin-top: 20px; */
    ul {
      list-style: none;
    }
    @media (min-width: ${media.tablet}) {
      font-size: 11px;
    }
    @media (min-width: ${media.desktop}) {
    }
  }

  input[type='text'],
  input[type='tel'],
  input[type='email'] {
    width: 100% !important;
    height: 50px;
    border: 1px solid ${({ theme }) => theme.color.brownGrey};
    border-radius: 0;
    background-color: transparent;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    letter-spacing: 1.2px;
    padding-left: 10px;
    appearance: none;
    margin-bottom: 17px;
    outline: none;
    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: transparent;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: transparent;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: transparent;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: transparent;
    }
    &::placeholder {
      color: transparent;
    }

    @media (min-width: ${media.desktop}) {
    }
  }

  label {
    display: block;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    letter-spacing: 1.2px;
    margin-bottom: 3px;
  }

  select {
    display: block;
    width: 100% !important;
    height: 50px;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.brownGrey};
    border-radius: 0;
    appearance: none;
    font-size: 14px;
    letter-spacing: 1.2px;
    padding-left: 10px;
    margin-bottom: 17px;
    outline: none;
    @media (min-width: ${media.desktop}) {
      font-size: 15px;
      letter-spacing: 1.5px;
    }
  }

  // ERROR MESSAGES
  .hs-error-msgs {
    /* display: none !important; */
    color: red;
    margin-bottom: 15px;
    list-style: none;
    @media (min-width: ${media.desktop}) {
      position: absolute;
      bottom: -17px;
    }
  }

  .hs_error_rollup {
    position: relative;
    @media (min-width: ${media.desktop}) {
      height: 20px;
    }
  }

  // agency disclosure checkbox error msg
  .hs_realtor_disclosure .hs-error-msgs {
    @media (min-width: ${media.desktop}) {
      bottom: -35px;
    }
  }

  .hs-error-msg {
    @media (min-width: ${media.desktop}) {
      font-size: 12px;
    }
  }

  // DISCLAIMER
  .hs-richtext {
    font-family: ${({ theme }) => theme.font.family};
    font-size: 11px;
    color: #666666;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  // RECAPTCHA
  .grecaptcha-badge {
    margin: 40px 0;
  }

  input[type='submit'] {
    border: solid 1px ${({ theme }) => theme.color.grey} !important;
    background-color: ${({ theme }) => theme.color.grey} !important;
    padding: 14px 18px 12px !important;
    color: ${({ theme }) => theme.color.offWhite} !important;
    font-family: ${({ theme }) => theme.font.family} !important;
    appearance: none;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease;
    width: 198px;
    align-self: flex-end;
  }

  // CHECKBOX
  .hs-form-booleancheckbox-display {
    position: relative;

    > span {
      margin-left: 30px !important;
    }
  }

  input[type='checkbox'] {
    appearance: none;
    outline: none;
    position: absolute;
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;

    &::after {
      content: '';
      background: ${({ theme }) => theme.color.offWhite};
      position: absolute;
      top: 50%;
      left: 47.5%;
      transform: translate(-50%, -50%);
      width: 20px !important;
      height: 20px !important;
      border: 1px solid ${({ theme }) => theme.color.brownGrey};
    }

    &:checked::after {
      background: ${({ theme }) => theme.color.brownGrey};
    }
  }

  .hs_visitor_safety_acknowledgement {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (min-width: ${media.desktop}) {
      width: 76.5vw;
    }
  }
`;

export default Register;
