import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Intl from '../../Intl';
import LanguageSwitcher from '../../LanguageSwitcher';

import media from 'src/styles/media';

const MobileMenu = (props) => {
  const { links, active, closeMenu } = props;
  return (
    <Intl>
      {(t, { locale }) => (
        <Root active={active}>
          {links.map((item, index) => (
            <StyledLink
              to={item.to}
              key={index}
              onClick={closeMenu}
              hidden={locale !== 'en' && item.enOnly}
            >
              {item.title}
            </StyledLink>
          ))}

          {/* Only show Language Switcher on non-English locale */}
          {locale !== 'en' && <LanguageSwitcher onClick={closeMenu} />}
        </Root>
      )}
    </Intl>
  );
};

MobileMenu.propTypes = {
  links: PropTypes.array,
  active: PropTypes.bool,
  closeMenu: PropTypes.func,
};

export default MobileMenu;

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: ${({ theme }) => theme.color.brownGrey};
  opacity: ${(props) => (props.active ? 1 : 0)};
  pointer-events: ${(props) => (props.active ? 'all' : 'none')};
  transition: 0.3s ease-in;
  display: flex;
  padding-top: 140px;
  flex-direction: column;
  align-items: center;
  a {
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 3px;
    color: white;
    text-transform: uppercase;
    margin-bottom: 35px;
  }
  @media (min-width: ${media.tablet}) {
    padding-top: 250px;
    a {
      font-size: 16px;
      line-height: normal;
      margin-bottom: 60px;
    }
  }
  @media (min-width: ${media.desktop}) {
    display: none;
  }
  @media ${media.ipadPro} {
    display: flex;
  }
`;

const StyledLink = styled(Link)`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
`;
