import React from 'react';

export default {
  name: '',
  menu: [
    {
      title: 'By Boffo',
      to: '/boffo',
    },
    {
      title: 'Homes',
      to: '/homes',
      enOnly: true,
    },
    // {
    //   title: 'Floorplans',
    //   to: '/floorplans',
    // },
    {
      title: 'Amenities',
      to: '/amenities',
      enOnly: true,
    },
    {
      title: 'West Coquitlam',
      to: '/community',
    },
    {
      title: 'Gallery',
      to: '/gallery',
    },
    {
      title: 'Contact',
      to: '/contact',
    },
    {
      title: 'Register',
      to: '/register',
    },
  ],
  registerLabel: 'Register Now',
  home: {
    hero: {
      comingSoon: 'Previewing by Appointment',
      slides: [
        {
          src: require('src/assets/images/home/hero-form-concept-box.svg'),
          mobileSrc: require('src/assets/images/home/mobile_form-concept.svg'),
          subtitle: 'An obsession for detail',
        },
        {
          src: require('src/assets/images/home/hero-time-place-box.svg'),
          mobileSrc: require('src/assets/images/home/mobile_form-place.svg'),
          subtitle: 'The essence of community',
        },
        {
          src: require('src/assets/images/home/hero-time-commitment-box.svg'),
          mobileSrc: require('src/assets/images/home/mobile_form-commitment.svg'),
          subtitle: 'A 50 year legacy',
        },
      ],
    },
    content: {
      title: 'Good things Come in pairs',
      body: "A thoughtful community of condominiums and townhomes, envisioned and built by Boffo. These one to three bedroom homes are perfectly positioned near Burquitlam Station, SFU and an abundance of daily conveniences in this dynamic hub. Smith & Farrow brings an extensive collection of amenities to all residents, and delivers on Boffo's longstanding tradition of unparalleled craftsmanship.",
      button: 'View Floorplans',
    },
    homes: {
      title: (
        <>
          Just one
          <br />
          look
          <br />
          and you
          <br />
          know
        </>
      ),
      ctaLabel: 'Explore the Homes',
    },
    amenities: {
      title: 'Community spaces to celebrate everyday life',
      ctaLabel: 'View Amenities',
    },
    community: {
      title: 'PERFECT TOGETHER AS THEY SHARE A FAVOURITE PLACE',
      ctaLabel: 'EXPLORE THE COMMUNITY',
    },
    about: {
      title: (
        <>
          A <br />
          BEAUTIFUL STORY CONTINUES
        </>
      ),
      ctaLabel: 'ABOUT BOFFO',
    },
  },
  registerBanner: {
    title: (
      <>
        DISCOVER
        <br />
        THE BEAUTY
        <br />
        OF HOME
      </>
    ),
    buttonLabel: 'Register Now',
    subTitle: <>FINAL HOME SELECTION AVAILABLE FOR PURCHASE</>,
    text: <>Please contact our sales team to schedule an appointment</>,
    infoTitle: 'General Info',
    address: (
      <>
        2100 Springer Avenue, Burnaby
        <br />
        (southeast corner at Lougheed)
      </>
    ),
  },
  community: {
    hero: {
      textFile: 'hero_community.svg',
    },
    main: {
      title: 'WEST COQUITLAM',
      body: 'A modern community on the cusp of Burnaby and Coquitlam, surrounded by beautiful mountains and the tranquility of natural woodlands. SkyTrain makes for an effortless commute.',
    },
    video: {
      title: 'WHERE THE BEST THINGS IN LIFE ARE CLOSER.',
      iconName: 'play-video.png',
    },
    highlights: {
      title: 'You Are Here',
      body: 'Nestled in nature, yet unmistakably urban, Smith & Farrow rises up from a serene pocket of the city and a quiet residential street. Enjoy the convenience of living close to restaurants, grocery stores, and schools. Parks, playgrounds, and forested trails are all around you. Meet just a few of the area’s much-loved destinations.',
      imageCaptions: [
        'Discover the art of the bouquet at Vivio Flower Gallery.',
        'Simon Fraser University’s (SFU) Burnaby campus adjoins Burnaby Mountain Park and the Kamui Mintara (Playground of the Gods) art installation.',
        'Gabi & Jules is the sweet spot for great coffee plus rustic, handmade pies and other house-baked treats.',
        'From Sunday brunch to picnics at the park, your most memorable moments are waiting.',
        'Burquitlam Station, a 2-minute walk from your new home, puts downtown Vancouver within a 35-minute ride.',
        'SFU is renowned for academics, distinctive architecture, and stunning views.',
        'At 435 acres, Mundy Park is Coquitlam’s largest park. It has an outdoor pool, picnic area, sports fields, and two lakes amidst a network of trails.',
        'Nature’s tranquility is all around; explore lakes, streams, and woodland trails.',
        '360-degree views from Smith & Farrow.',
      ],
    },
    map: {
      title: 'A Central & Connected Location',
    },
    neighbourhood: {
      'West Coquitlam': {
        title: 'West Coquitlam',
        description:
          'All eyes are on this community where modern urban neighbourhoods are nestled into forested hillsides, and SkyTrain provides quick travel throughout Metro Vancouver. ',
      },
      'Port Moody': {
        title: 'Port Moody',
        description:
          'This historic town is loved for its heritage charm, art scene, waterfront trails, and breweries.',
      },
      'Burnaby Mountain/SFU': {
        title: 'Burnaby Mountain/SFU',
        description:
          'This is one of Metro Vancouver’s most popular hiking and biking destinations, with a network of 26 multi-use trails winding through 576 woodsy hectares. It is also home to SFU, one of Canada’s top ranked universities.',
      },
      Coquitlam: {
        title: 'Coquitlam',
        description:
          'This rapidly growing city is known for walkable urban neighbourhoods. Explore the character shops and unique restaurants of Austin Heights, or enjoy one of the many celebrated parks and lakes of the Metro Vancouver area.',
      },
    },
    neighbourhoodProfiles: 'Neighbourhood Profiles',
  },
  boffo: {
    hero: {
      title: 'BUILDING HOMES FOR 50 YEARS',
      bodyParagraphs: [
        'Boffo Developments has built a solid reputation and a loyal following over 50 years. We’ve achieved this by designing and building homes for a truly exceptional homeowner experience. An obsession for detail is inherent in every Boffo home, and our disciplined attention to design results in a very unique brand of luxury.',
        'Every Boffo community is built intentionally, with a longterm legacy in mind - not just our own, but for that of our homeowners. To protect that legacy, we continue to care for our buildings long after they are built - as everything we build is a reflection of who we are.',
      ],
    },
    projects: [
      {
        title: 'The Smithe',
        caption:
          'Thoughtfully designed 2 + 3 bedroom residences will be situated in the heart of the Arts + Events District, Downtown Vancouver. These homes have been designed to create an unprecedented living experience.',
      },
      {
        title: 'Modello',
        caption:
          'Striking architecture, innovative landscaping and public art inspiration create the cultural centrepoint and gateway to the new Beresford Art Walk. Building on the success of the award-winning Jewel residences, Modello set a new benchmark for luxury condominium living in Burnaby.',
      },
      {
        caption: 'Adagio I + II, South Surrey',
      },
      {
        caption: 'Jewel I + II, Burnaby',
      },
      {
        title: (
          <>
            Urban
            <br />
            High-Rise
            <br />
            Winner
          </>
        ),
      },
      {
        caption: 'Modello, Burnaby | UDI Awards for Excellence 2018',
      },
      {
        caption: 'Artemisia, Downtown Vancouver',
      },
      {
        caption: 'The Smithe, Downtown Vancouver',
      },
      {
        caption: 'Jewel townhomes',
      },
      {
        caption:
          'Jewel I + II, Burnaby | Awarded first place winner of best multi-family highrise by the Urban Development Institute, Greater Vancouver Home Builders Association, and the Canadian Home Builders Association.',
      },
    ],
    developers: [
      {
        name: 'Chris Dikeakos Architects Inc.',
        description:
          'Chris Dikeakos Architects (CDA) is an award-winning architectural firm dedicated to excellence and the highest level of expertise. CDA’s past projects have positioned the firm as one of North America’s leaders in multi-unit and hi-rise residential design. Smith & Farrow will be Boffo and CDA’s 8th collaboration together.',
      },
      {
        name: 'mckinley',
        description:
          'McKinley blurs the lines of the traditional design process by taking an immersive, experimental approach to creative consulting. The firm is a master in architecture, interiors, branding, and furniture in the hospitality, retail, food & beverage, and mixed-use development industries. Boffo and McKinley have collaborated to bring a fresh approach to Smith & Farrow’s interior design offering.',
      },
    ],
  },
  register: {
    title: 'LET’S STAY IN TOUCH',
    description:
      'Our final home selection is available for purchase. While the sales gallery is now closed, we have an interactive tour and virtual appointments available. Please get in touch with our sales team for more information.',
  },
  form: {
    inputs: [
      {
        // only translate the label please :)
        name: 'contact[first_name]',
        id: 'contact_first_name',
        label: 'First Name*',
        required: true,
        pattern: '[a-zA-Z ]+',
      },
      {
        name: 'contact[last_name]',
        id: 'contact_last_name',
        label: 'Last Name*',
        required: true,
        pattern: '[a-zA-Z ]+',
      },
      {
        name: 'contact[email]',
        id: 'contact_email',
        label: 'Email*',
        type: 'email',
        required: true,
      },
      {
        name: 'contact[phone]',
        id: 'contact_phone',
        label: 'Phone*',
        type: 'tel',
        required: true,
        pattern: '^[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$',
      },
      {
        name: 'contact[postcode]',
        id: 'contact_postcode',
        label: 'Postal Code*',
        required: true,
        pattern: '[a-zA-Z0-9 ]+',
      },
    ],
    selects: [
      // only translate the label and option name please
      {
        label: 'Preferred Contact',
        name: 'contact[contact_preference]',
        id: 'contact_contact_preference',
        required: false,
        options: [
          {
            name: 'Email',
            value: 'Email',
          },
          {
            name: 'Phone',
            value: 'Phone',
          },
          {
            name: 'Text Message',
            value: 'Text Message',
          },
        ],
      },
      {
        label: 'How did you hear about Smith & Farrow?*',
        name: 'answers[5961][answers]',
        id: 'answers_5961',
        required: true,
        options: [
          {
            name: 'Online Search',
            value: 'Online Search',
          },
          {
            name: 'Online Advertising/Social Media',
            value: 'Online Advertising/Social Media',
          },
          {
            name: 'Print Advertising',
            value: 'Print Advertising',
          },
          {
            name: 'Signage',
            value: 'Signage',
          },
          {
            name: 'Email',
            value: 'Email',
          },
          {
            name: 'Friends & Family',
            value: 'Friends & Family',
          },
          {
            name: 'Realtor',
            value: 'Realtor',
          },
        ],
      },
      {
        label: 'Family Status',
        name: 'answers[6181][answers]',
        id: 'answers_6181',
        required: false,
        options: [
          {
            name: 'Single',
            value: 'Single',
          },
          {
            name: 'Couple',
            value: 'Couple',
          },
          {
            name: 'Couple with Children',
            value: 'Couple with Children',
          },
          {
            name: 'Single with Children',
            value: 'Single with Children',
          },
        ],
      },
    ],
    radio: {
      label: 'Are you a realtor?*',
      name: 'agent',
      required: true,
      options: [
        {
          name: 'Yes',
          value: 'true',
        },
        {
          name: 'No',
          value: 'false',
        },
      ],
    },
    comments: 'comments',
    required: 'This field is required.',
    submit: 'Submit',
    insider:
      'I would also like to register as a Boffo Community Insider to be updated on other Boffo projects',
    consent:
      'I consent to receiving commercial electronic messages from The Boffo Group, and acknowledge that I can withdraw my consent at any time by unsubscribing as set out below. *',
    disclaimer:
      'Boffo Developments Ltd #200 - 4580 Hastings Street, Burnaby, BC V5C 2K4 By opting-in you consent to Boffo Developments Ltd and their current and future affiliates contacting you with information about their current and future products and services. Your information will not be shared, sold or distributed or otherwise to any other parties. If at any time you would no longer like to receive updates, you can update your profile by clicking the unsubscribe link at the bottom of any future email correspondence. Sales powered by MLA Canada Realty.',
  },
  thankYou: {
    title: 'Thank you for registering',
    body: 'You will be the first to receive project updates and announcements.',
    back: 'Back to homepage',
  },
  footer: {
    title: 'Experience Boffo',
    subtitle: "BC's Boutique Home Builder",
    text: 'THE DEVELOPER RESERVES THE RIGHT TO MODIFY THE BUILDING DESIGN, FLOORPLANS, PRICES, FEATURES AND SPECIFICATIONS WITHOUT NOTICE. THIS IS NOT AN OFFERING FOR SALE. ANY SUCH OFFERING CAN ONLY BE MADE BY WAY OF DISCLOSURE STATEMENT. E. & O.E. Sales powered by MLA Canada Realty.',
  },
  homes: {
    intro: {
      title: (
        <>
          Just One Look
          <br />
          and You Know
        </>
      ),
      text: 'Boffo is unwavering in their commitment to creating unprecedented living experiences through artistic design and enduring quality. Standout floorplans place stunning views in front of tranquil master suites, expansive living areas, spacious kitchens and large terraces. Abundant glazing fills the residence with natural light and custom millwork makes every inch of the space extraordinary.',
      imageCaption1:
        'Boffo’s signature floorplan, “Plan G”, has a uniquely wide and shallow layout with 37-feet of continuous windows spanning across the extremely spacious living area. This west-facing plan is available with 2 or 3 bedrooms with 2 bathrooms and is showcased in the darker “Smith” scheme.',
      imageCaption2:
        'Entry niches with integrated lighting, magnetic board and charging station allow you to keep track of all on-the-go essentials.',
      imageCaption3:
        'Accept no compromise in the place you call home. Smith & Farrow raises the bar for elegant living, with exacting standards of quality in every room. Over-height doors, 9-foot ceilings, open-concept design and wide-plank European flooring throughout contribute to the residence’s grand scale.',
    },
    kitchen: {
      title1:
        'Designed with chefs in mind, Boffo kitchens are renowned for their impeccable style and function. It’s all included - superior features are standard here.',
      title2:
        'Brilliant storage solutions, integrated under-cabinet lighting and sophisticated features combine for a space that radiates beauty while performing to the highest standards.',
      title3:
        'Bright and airy kitchen windows, pantry walls with display niches, and custom kitchen tables are featured throughout a selection of floorplans.',
      caption1:
        'Generous working surfaces, locally-crafted custom cabinetry and integrated appliances take culinary excitement to new heights. The stage is set for elegant entertaining and inspired family meals.',
      caption2:
        'Cabinets are built to maximize functionality, with spacesaving corners and Häfele pull-out storage solutions.',
      caption3:
        'Undermount 27-inch sink with fixtures in polished chrome or modern champagne bronze—your choice.',
      caption4:
        'Five-burner Fisher & Paykel gas cooktop and electric convection oven refine the art of entertaining.',
      caption5:
        '“Plan H” is the north-west corner 2 bedroom, 2 bathroom plan showcased in the white “Farrow” scheme.',
      caption6:
        'Covered balconies are generously proportioned, allowing homeowners to create a private outdoor space that expresses their personal style. Enjoy the open air in comfort and extend year-round outdoor living.',
    },
    interior: {
      title1:
        'Bedrooms, dens and flex areas give homeowners the opportunity to utilize space however best fits your lifestyle.',
      title2:
        'By finessing the details, Boffo ensures that concealed spaces function as beautifully as the rest of the home. They are relentless in their pursuit of opportunities to work inspired storage solutions into every niche, nook and cranny.',
      caption1:
        'Ensuites combine the comfort of heated flooring with an abundance of storage, throughout expansive medicine cabinets and cabinetry drawers. Lighting is integrated into multi-paneled mirrors and tucked under cabinets. Floating vanities, shower niches and sleek fixtures create beautiful clean lines.',
      caption2:
        'Master bedrooms are private living quarters designed for serenity. Oversized windows put spectacular scenes within view.',
      caption3:
        'Flexible space can be transformed into a study, home office, additional pantry or extra storage room.',
      caption4: 'Guest room, kids room, art studio – the option is yours.',
      caption5:
        'Laundry rooms are well-lit, efficient spaces with custom storage solutions in each plan.',
      caption6:
        'Built-in wardrobes feature custom organization systems with integrated LED lighting.',
    },
    penthouse: {
      title: 'PENTHOUSE COLLECTION',
      text: 'Four unique penthouses place stunning forty-fifth storey views throughout incredibly spacious, thoughtfully designed homes.',
      caption1:
        'PH 02B has a spacious floorplan with ample dining and living areas, including a separate family TV room.',
      caption2:
        'PH 02B is positioned on the south-east corner and features three separate outdoor living spaces, including a semi-enclosed atrium.',
      caption3:
        'PH 02B features a bright and airy window kitchen: plus 36” appliance package, waterfall stone island with wide drawer cabinetry and wine fridge, and pantry with display niche.',
      caption4:
        'Penthouses offer beautiful sunset views with a partially enclosed atrium and extending balcony overlooking Burnaby Mountain.',
    },
    townhome: {
      title: (
        <>
          Townhome Collection
          <br />
          Only ONE townhome available
        </>
      ),
      text: 'This rare collection of townhomes feature wide, shallow layouts that provide the feeling of a single-family home experience.',
      caption1:
        'An obsession for detail is inherent in every Boffo home and impossible to miss. These unique townhomes feature wide, shallow layouts to provide the feeling of a single-family home experience.',
      caption2:
        'Townhome K is positioned on the lower level of the secluded north corner, offering convenient street access from North Road and a private door accessing a dedicated corridor leading to the same-level townhome parking stalls.',
      caption3:
        'Top level homes feature a courtyard entry with private outdoor space. Lush landscaping and thoughtfully designed fencing provide privacy and a beautiful sense of arrival at home.',
      caption4:
        'Abundant glazing fills the home with natural light and stunning unobstructed views.',
    },
  },
  amenities: {
    intro: {
      title: (
        <>
          Community spaces
          <br />
          to celebrate
          <br />
          everyday life
        </>
      ),
      text: 'Extensive amenities are located throughout three floors, offering a variety of activities for all homeowners. Lounges are impeccably furnished—inside and out—providing inviting areas to gather, work, exercise and unwind. Beautiful plantings, ambient lighting, fire tables and deep overhangs blur indoor-outdoor definitions, creating a seamless flow of space and experience. Wellness facilities foster selfcare as part of daily life.',
      imageCaption:
        'The elegant first impression begins at the courtyard entry with intricate landscaping and illuminated water features creating a soothing soundscape of trickling water. The roundabout pick-up and drop-off leads to a cobbled port cochère and dramatic glass entryway. Inside, a full-service concierge completes the hotel-style sense of arrival.',
    },
    outdoor: {
      title1:
        'Over-height glass between indoor and outdoor lounges creates a sense of continuous connection.',
      title2: 'A place in the sun … swim laps, cool off, relax and rejuvenate.',
      imageCaption1:
        'Families can enjoy a private family lounge that extends to the outdoor playground – perfect for childrens’ birthday parties.',
      imageCaption2:
        'With canopy-covered dining tables and lounge seating with fire tables, outdoor living is a pleasure in every season.',
      imageCaption3:
        'The 65-foot pool with hot tub, and pool house with steam, sauna and shower facilities.',
    },
    highlights: {
      title1:
        'Whether you’re bringing family and friends together for a holiday dinner or collaborating on a presentation with your colleagues, amenity spaces are thoughtfully imagined and exquisitely furnished.',
      title2:
        'Healthy living is designed into the community, so residents can embrace wellness as part of daily life.',
      title3:
        'When it’s your turn to host, welcome friends to the stylish rooftop lounge, complete with full-service bar area, banquette seating and pool table.',
      imageCaption1:
        'Host larger gatherings in this five-star, indoor-outdoor dining area, where a  full catering kitchen connects to outdoor cooking facilities on the garden terrace.',
      imageCaption2:
        'With a fireplace and natural light streaming through oversized windows, this workshare lounge redefines the concept of home office space.',
      imageCaption3:
        'The richly appointed lobby sets the tone for every arrival and departure. With a doublesided fireplace and ceilings that ascend to 21-feet, the lounge makes a refined setting for meeting friends before dinner or waiting for your Uber.',
      imageCaption4:
        'Overlooking the landscaped courtyard, the fully-equipped fitness facility provides multiple cardio stations and weight training gear.',
      imageCaption5:
        'This serene setting with an abundance of natural light is a perfect space for yoga, Pilates, balance, stretching and meditation.',
      imageCaption6:
        'All homeowners can enjoy the best views on the 3,600sqft rooftop terrace. With this incredible panorama to catch the eye, any gathering is immediately an elevated event. An outdoor screening space, fire tables and landscaping provide unrivalled spaces for celebrations and everyday enjoyment.',
      imageCaption7:
        'With long-table entertaining and a beautiful curving sectional sofa, there’s space for everyone to relax and enjoy the occasion.',
      imageCaption8:
        'You cannot beat an outdoor screening area to enjoy a live concert, game or have a family movie night while star gazing at the same time.',
      imageCaption9:
        'In the rooftop lounge, glass NanoWall doors fold out of sight to seamlessly connect the outdoor terrace. Step from the interior lounge directly into a lush garden in the sky, where mesmerizing views make an exquisite extension to your private living space.',
    },
  },
  gallery: {
    title: 'Gallery',
    categories: ['Amenities', 'Homes', 'West Coquitlam'],
  },
  artistRendering: 'Artist Rendering',
};
