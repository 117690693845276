import React from 'react';
import styled from 'styled-components';

import Intl from './Intl';

import media from 'src/styles/media';

import socials from 'src/data/socials';

const Footer = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <Logo>
            <span />
            <img
              src={require('src/assets/images/icons/ampresand.svg')}
              alt=""
            />
            <span />
          </Logo>
          <Container>
            <div>
              <Flex>
                <div className="wrapper">
                  <a
                    href="https://boffo.ca/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Boffo
                      src={require('src/assets/images/logos/boffo-logo.svg')}
                      alt="Boffo Developments Ltd"
                    />
                  </a>
                  <Social>
                    {socials.map((item, index) => (
                      <a
                        href={item.url}
                        key={index}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          width="32px"
                          height="32px"
                          viewBox="0 0 32 32"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                        >
                          {item.svg}
                        </svg>
                      </a>
                    ))}
                  </Social>
                </div>
                <Contact>
                  <a
                    href="https://mlacanada.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require('src/assets/images/logos/mla-logo.svg')}
                      alt="MLA Canada"
                    />
                  </a>
                </Contact>
              </Flex>
              <img
                src={require('src/assets/images/logos/bcs-boutique-homebuilder.svg')}
                alt="BC's Boutique Home Builder"
              />
              <Sales>Sales by MLA Canada Realty</Sales>
            </div>
            <Contact mobile>
              <a
                href="https://mlacanada.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('src/assets/images/logos/mla-logo.svg')}
                  alt="MLA Canada"
                />
              </a>
            </Contact>
          </Container>
          <p>{t.footer.text}</p>
        </Root>
      )}
    </Intl>
  );
};

export default Footer;

const Root = styled.div`
  position: relative;
  background-color: #2f2f2f;
  color: white;
  padding: 34px 20px 20px;
  @media (min-width: ${media.tablet}) {
    padding: 48px 30px 22px;
  }
  @media (min-width: ${media.desktop}) {
    padding: 56px 45px 33px;
  }
  @media (min-width: ${media.xl}) {
    padding: 56px 115px 32px;
  }
  p {
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 9px;
    width: 265px;
    @media (min-width: ${media.tablet}) {
      width: 636px;
      max-width: 100%;
      margin-top: 25px;
    }
    @media (min-width: ${media.desktop}) {
      width: auto;
    }
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 85px;
  @media (min-width: ${media.desktop}) {
    margin-bottom: 104px;
  }
  span {
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.brownGrey};
  }
  img {
    width: 41px;
    height: 46px;
    margin: 0 20px;
    @media (min-width: ${media.tablet}) {
      width: 51px;
      height: 57px;
      margin: 0 23px;
    }
  }
`;

const Boffo = styled.img`
  width: 129px;
  height: 58px;
  margin-bottom: 44px;
  @media (min-width: ${media.tablet}) {
    width: 110px;
    height: 48px;
    margin-bottom: 0;
  }
  @media (min-width: ${media.desktop}) {
    width: 129px;
    height: 58px;
    margin-bottom: 0;
  }
`;

const Flex = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const Social = styled.div`
  display: flex;
  margin-bottom: 20px;
  @media (min-width: ${media.tablet}) {
    margin-left: 66px;
    margin-bottom: 0;
  }
  @media (min-width: ${media.desktop}) {
    margin-left: 57px;
    margin-bottom: 0;
  }
  svg {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    @media (min-width: ${media.tablet}) {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
    @media (min-width: ${media.desktop}) {
      width: 32px;
      height: 32px;
      margin-right: 12px;
      g {
        transition: 0.4s ease-out;
      }
      &:hover g {
        fill: white;
      }
    }
  }
`;

const Container = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    > div {
      width: 100%;
    }
    .wrapper {
      display: flex;
      align-items: center;
    }
  }
`;

const Contact = styled.div`
  margin: 60px 0 20px;
  display: ${(props) => (props.mobile ? 'block' : 'none')};
  a {
    display: block;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.73;
    letter-spacing: 2px;
    color: white;
    height: max-content;
  }
  @media (min-width: ${media.tablet}) {
    margin: 0;
    display: ${(props) => (props.mobile ? 'none' : 'block')};
    a {
      height: 51px;
      text-align: right;
    }
  }
  @media (min-width: ${media.desktop}) {
    a {
      text-decoration: none;
      transition: all 0.3s ease;
      &:hover {
        color: ${({ theme }) => theme.color.lightGreyGreen};
      }
    }
  }
`;

const Sales = styled.p`
  font-family: 'Helvetica' !important;
  font-size: 14px !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  line-height: normal !important;
  margin-top: 7px;
  @media (min-width: ${media.tablet}) {
    display: none;
  }
`;
