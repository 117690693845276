import React from 'react';
import styled from 'styled-components';

import RegisterTagline from 'components/RegisterTagline';
import Info from './sections/Info';
import MobileMap from './sections/MobileMap';
import DesktopMap from './sections/DesktopMap';

import media from 'src/styles/media';

const Contact = () => {
  return (
    <Root>
      <MobileMap />
      <DesktopMap />
      <Info />
      <RegisterTagline />
    </Root>
  );
};

export default Contact;

const Root = styled.div`
  padding-top: 70px;
  @media (min-width: ${media.desktop}) {
    padding-top: 135px;
  }
`;
