import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'src/styles/media';

const TitleCallout = (props) => {
  const { title, smaller, smallerMobile } = props;
  return (
    <Root smaller={smaller} smallerMobile={smallerMobile}>
      <h4 className="intro">{title}</h4>
    </Root>
  );
};

TitleCallout.propTypes = {
  title: PropTypes.string,
  smaller: PropTypes.bool,
  smallerMobile: PropTypes.bool,
};

const Root = styled.div`
  text-align: center;
  width: ${(props) => (props.smaller || props.smallerMobile ? '90%' : '100%')};
  margin: 100px auto 100px;
  @media (min-width: ${media.tablet}) {
    padding: 80px 0 120px;
    margin-top: 40px;
    margin-bottom: 0;
    width: 100%;
    h4 {
      max-width: ${(props) => (props.smaller ? '432px' : '480px')};
      margin: 0 auto;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 120px 0 160px;
    h4 {
      max-width: 570px;
    }
  }
`;

export default TitleCallout;
