import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Intl from '../Intl';

import media from 'src/styles/media';

const Register = (props) => {
  const { black } = props;
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root to={`${getLocaleURL()}/register`} black={black} {...props}>
          {t.registerBanner.buttonLabel}
        </Root>
      )}
    </Intl>
  );
};

Register.propTypes = {
  black: PropTypes.bool,
};

export default Register;

const Root = styled(Link)`
  display: block;
  width: fit-content;
  padding: 12px 25px;
  border: 1px solid
    ${(props) => (props.black ? 'black' : props.theme.color.offWhite)};
  color: 'black';
  background-color: ${(props) =>
    props.black ? 'transparent' : props.theme.color.offWhite};
  font-family: ${({ theme }) => theme.font.family};
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  transition: 0.3s ease;
  height: fit-content;
  white-space: nowrap;
  &:hover {
    border-color: ${(props) => !props.black && props.theme.color.primary};
    color: ${(props) =>
      props.black ? props.theme.color.offWhite : props.theme.color.black};
    background-color: ${(props) =>
      props.black ? 'black' : props.theme.color.primary};
  }
  @media (min-width: ${media.desktop}) {
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 1px;
    padding: 10px 21px;
    width: auto;
    height: auto;
  }
`;
