import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';

const Button = () => {
  return (
    <Root
      href="https://www.google.com/maps/place/2100+Springer+Ave,+Burnaby,+BC+V5B+3M7/@49.2646725,-122.9886665,17z/data=!3m1!4b1!4m5!3m4!1s0x5486779e9ad016eb:0x8769309b5904a624!8m2!3d49.264669!4d-122.9864777"
      target="_blank"
      rel="noopener noreferrer"
    >
      Open in Google Maps
    </Root>
  );
};

export default Button;

const Root = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 15px;
  width: 215px;
  transform: translateX(-50%);
  padding: 10px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.color.grey};
  font-family: ${({ theme }) => theme.font.family};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2.57px;
  text-transform: uppercase;
  color: black;
  z-index: 1;
  @media (min-width: ${media.tablet}) {
    width: 242px;
    bottom: 40px;
    font-size: 14px;
    letter-spacing: 3px;
  }
  @media (min-width: ${media.desktop}) {
    bottom: 60px;
    padding: 10px 12px;
    font-size: 14px;
    letter-spacing: 3px;
  }
`;
