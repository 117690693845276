import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Hero from 'components/Hero';
import RegisterTagline from 'components/RegisterTagline';
import Intro from './sections/Intro';
import Outdoor from './sections/Outdoor';
import Highlights from './sections/Highlights';

const Amenities = () => {
  return (
    <Intl>
      {(t, { locale }) => (
        <Root>
          <Hero
            textImage={require('src/assets/images/amenities/hero_tagline_amenities.svg')}
            background={require('src/assets/images/amenities/smith-and-farrow-amenities-hero.jpg')}
          />
          <Intro />
          <Outdoor />
          <Highlights />
          <RegisterTagline />
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div``;

export default Amenities;
