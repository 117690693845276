import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Intl from 'components/Intl';

import media from 'src/styles/media';

const List = props => {
  const {
    data,
    handleClick,
    activeSection,
    isMobileOpen,
    handleMobileList,
    title
  } = props;
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <MobileTab onClick={handleMobileList}>
            <h4>{title}</h4>
            <img
              src={require('src/assets/images/icons/arrow-right.svg')}
              alt={data[activeSection].title}
            />
          </MobileTab>
          <Wrapper isOpen={isMobileOpen}>
            {data.map((item, index) => (
              <Tab
                key={index}
                onClick={() => handleClick(index)}
                active={activeSection === index}
              >
                <h4>{t.community.neighbourhood[item.title].title}</h4>
                <img
                  src={require('src/assets/images/icons/arrow-right.svg')}
                  alt={item.title}
                />
              </Tab>
            ))}
          </Wrapper>
        </Root>
      )}
    </Intl>
  );
};

List.propTypes = {
  data: PropTypes.array,
  activeSection: PropTypes.number,
  handleClick: PropTypes.func,
  handleMobileList: PropTypes.func,
  isMobileOpen: PropTypes.bool,
};

export default List;

const Root = styled.div`
  width: 100%;
  h4 {
    font-family: ${({ theme }) => theme.font.header};
    font-size: 17px;
    line-height: 1.06;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  img {
    position: absolute;
    top: 50%;
    width: 8px;
    height: 20px;
  }
  @media (min-width: ${media.desktop}) {
    width: 92%;
  }
  @media ${media.ipadPro} {
    width: 100%;
  }
`;

const Tab = styled.div`
  border-top: ${props => (props.active ? 0 : '1px')} solid black;
  cursor: pointer;
  position: relative;
  max-height: ${props => (props.active ? 0 : '100px')};
  transition: 0s;
  transition-delay: 0.4s;
  overflow: hidden;
  height: 48px;
  display: flex;
  align-items: center;
  &:last-of-type {
    border-bottom: 1px solid black;
  }
  img {
    right: 0;
    transform: translateY(-50%);
    opacity: ${props => (props.active ? 1 : 0)};
    transition: 0.3s ease;
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    padding: 16px 0;
    height: auto;
    display: block;
    max-height: none;
    transition: none;
    border-top: 1px solid black;
    img {
      display: block;
    }
  }
`;

const MobileTab = styled.div`
  width: 100%;
  padding: 14px 0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  position: relative;
  img {
    right: 5px;
    transform: translateY(-50%) rotate(90deg);
  }
  @media (min-width: ${media.tablet}) {
    display: none;
  }
`;

const Wrapper = styled.div`
  max-height: ${props => (props.isOpen ? '600px' : 0)};
  transition: 0.4s ease-out;
  overflow: hidden;
  @media (min-width: ${media.tablet}) {
    max-height: none;
  }
`;
