import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Intl from 'components/Intl';

import media from 'src/styles/media';

const Rendering = (props) => {
  const { src, alt } = props;
  return (
    <Intl>
      {(t) => (
        <Root {...props}>
          <img src={src} alt={alt} />
          <p className="caption">{t.artistRendering}</p>
        </Root>
      )}
    </Intl>
  );
};

Rendering.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

const Root = styled.div`
  position: relative;
  img {
    display: block;
  }
  .caption {
    font-size: 10px;
    line-height: 1.2;
    margin: 0;
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  }
  @media (min-width: ${media.tablet}) {
    .caption {
      bottom: 8px;
      right: 8px;
    }
  }
  @media (min-width: ${media.desktop}) {
    .caption {
      bottom: 12px;
      right: 12px;
    }
  }
`;

export default Rendering;
