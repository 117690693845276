import React, { Component } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

import Intl from '../../../components/Intl';
import Input from '../elements/Input';
import Select from '../elements/Select';
import Radio from '../elements/Radio';

import media from 'src/styles/media';

import Insight from 'src/plugins/insight';

// import { inputs, selects, radio } from 'src/data/form';

class Form extends Component {
  constructor(props) {
    super(props);
    this.$form = React.createRef();
  }

  state = {
    recaptcha: false,
  };

  componentDidMount() {
    document.getElementById('are_you_simulated').style.display = 'none';

    let required = document
      .getElementById('spark-registration-form')
      .querySelectorAll('[required]');

    for (let i = 0; i < required.length; i++) {
      if (required[i].name !== 'agent') {
        required[i].oninput = (e) => e.target.setCustomValidity('');
        required[i].onchange = (e) => e.target.setCustomValidity('');
        required[i].oninvalid = (e) => e.target.setCustomValidity(' ');
      }
    }
  }

  handleChange = (value) => {
    if (value !== null) {
      this.setState({ recaptcha: true });
    } else {
      this.setState({ recaptcha: false });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let validity = this.$form.current.checkValidity();
    let required = document
      .getElementById('spark-registration-form')
      .querySelectorAll('[required]');
    // applies custom validation messages. checks for radio button because that validation message has different placement
    for (let i = 0; i < required.length; i++) {
      if (required[i].checkValidity() === true) {
        if (required[i].type === 'radio') {
          document.getElementById('radio-invalid').style.opacity = 0;
          document.getElementById('radio-invalid').style.maxHeight = 0;
        } else {
          required[i].oninvalid = (e) => {};
          required[i].previousElementSibling.style.opacity = 0;
          required[i].previousElementSibling.style.maxHeight = 0;
        }
      } else if (required[i].checkValidity() === false) {
        if (required[i].type === 'radio') {
          document.getElementById('radio-invalid').style.opacity = 1;
          document.getElementById('radio-invalid').style.maxHeight = '20px';
        } else {
          required[i].previousElementSibling.style.opacity = 1;
          required[i].previousElementSibling.style.maxHeight = '20px';
        }
        // break;
      }
    }
    if (validity && !this.state.recaptcha) {
      document.getElementById('recaptcha-missing').style.opacity = 1;
      e.preventDefault();
    } else if (validity && this.state.recaptcha) {
      document.getElementById('recaptcha-missing').style.opacity = 0;

      let name =
        document.getElementById('contact_first_name').value +
        ' ' +
        document.getElementById('contact_last_name').value;
      let email = document.getElementById('contact_email').value;
      let postal = document.getElementById('contact_postcode').value;

      let pageName = 'New Registrant - ' + postal;

      await Insight({ name: name, email: email, pageName: pageName });

      this.$form.current.submit();
    }
  };

  render() {
    return (
      <Intl>
        {(t, { getLocaleURL }) => (
          <Root>
            <form
              acceptCharset="UTF-8"
              action="https://spark.re/boffo/smith-farrow/register/smith-and-farrow-reg-form"
              id="spark-registration-form"
              method="post"
              ref={this.$form}
            >
              <input name="utf8" type="hidden" value="&#x2713;" />
              <input
                type="hidden"
                name="authenticity_token"
                value="6gdgLv1vdqlHbU2EIZ45A+hvBvRqlwYr9SiE6UZreIlwqoWQDSK1atFsbQFckE+1slgq9QYiUk9j8ZcjbG8c0w=="
              />
              <input
                id="redirect_success"
                name="redirect_success"
                type="hidden"
                value={`${
                  window.location.origin
                }/smithandfarrow${getLocaleURL()}/thankyou`}
              />
              <input
                id="redirect_error"
                name="redirect_error"
                type="hidden"
                value={window.location.origin + '/smithandfarrow'}
              />
              <Container>
                {t.form.inputs.map((item, index) => (
                  <Input input={item} key={index} />
                ))}
                {t.form.selects.map((item, index) => (
                  <Select select={item} key={index} />
                ))}
              </Container>
              <Radio radio={t.form.radio} />
              <Textarea>
                <label htmlFor="Comments">{t.form.comments}</label>
                <textarea
                  rows="8"
                  type="text"
                  name="contact[comments]"
                  id="contact_comments"
                ></textarea>
              </Textarea>
              <Consent>
                <input
                  type="checkbox"
                  name="answers[6261][answers][]"
                  value="Yes"
                  id="answers_6261_yes"
                />
                <span />
                <label htmlFor="answers_6261_yes">{t.form.insider}</label>
              </Consent>
              <Consent margin>
                <p>{t.form.required}</p>
                <input type="checkbox" name="checkbox" required id="checkbox" />
                <span />
                <label htmlFor="checkbox">{t.form.consent}</label>
              </Consent>
              <Captcha>
                <p id="recaptcha-missing">{t.form.required}</p>
                <ReCAPTCHA
                  sitekey="6LcmhrcUAAAAAHc0gp0nErGCfJ-oBmtxDB2nQ0js"
                  onChange={this.handleChange}
                />
              </Captcha>
              <Submit onClick={(e) => this.handleSubmit(e)}>
                {t.form.submit}
              </Submit>
              <Disclaimer>
                Boffo Developments Ltd #200 - 4580 Hastings Street, Burnaby, BC
                V5C 2K4 By opting-in you consent to Boffo Developments Ltd and
                their current and future affiliates contacting you with
                information about their current and future products and
                services. Your information will not be shared, sold or
                distributed or otherwise to any other parties. If at any time
                you would no longer like to receive updates, you can update your
                profile by clicking the unsubscribe link at the bottom of any
                future email correspondence. Sales powered by MLA Canada Realty.{' '}
              </Disclaimer>
              <input
                type="hidden"
                name="source"
                id="source"
                value={`Website ${t.name && t.name + ' '}Registration Form`}
              />
              <input
                type="text"
                name="are_you_simulated"
                id="are_you_simulated"
                placeholder="Leave this field blank"
              />
            </form>
          </Root>
        )}
      </Intl>
    );
  }
}

export default Form;

const Root = styled.div`
  width: 100vw;
  background-color: ${({ theme }) => theme.color.offWhite};
  padding: 44px 20px 25px;
  @media (min-width: ${media.tablet}) {
    padding: 90px 54px 25px;
  }
  @media (min-width: ${media.desktop}) {
    padding: 126px 150px 34px;
  }
  @media (min-width: ${media.xl}) {
    padding: 126px 360px 38px;
  }
`;

const Captcha = styled.div`
  position: relative;
  @media (max-width: 325px) {
    margin-left: -10px;
  }
  p {
    position: absolute;
    top: 100%;
    color: #ff0000;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    letter-spacing: 0.5px;
    opacity: 0;
    transition: 0.3s ease;
    @media (min-width: ${media.tablet}) {
      font-size: 16px;
      letter-spacing: 0.8px;
    }
  }
`;

const Disclaimer = styled.p`
  font-family: ${({ theme }) => theme.font.family};
  font-size: 10px;
  color: #666666;
  margin-top: 20px;
  @media (min-width: ${media.tablet}) {
    font-size: 11px;
    margin-top: 25px;
  }
  @media (min-width: ${media.desktop}) {
    margin-top: 34px;
  }
`;

const Container = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const Submit = styled.button`
  border: solid 1px ${({ theme }) => theme.color.brownGrey};
  padding: 14px 23px;
  background-color: transparent;
  color: black;
  font-family: ${({ theme }) => theme.font.family};
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.4px;
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    background-color: ${({ theme }) => theme.color.brownGrey};
    color: white;
  }
`;

const Textarea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  label {
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    letter-spacing: 1.2px;
    margin-bottom: 7px;
    @media (min-width: ${media.desktop}) {
      font-size: 15px;
      letter-spacing: 1.5px;
    }
  }
  textarea {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.brownGrey};
    border-radius: 0;
    appearance: none;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    letter-spacing: 1.2px;
    padding: 10px;
    @media (min-width: ${media.desktop}) {
      font-size: 15px;
      letter-spacing: 1.5px;
    }
  }
`;

const Consent = styled.div`
  display: flex;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  margin-bottom: ${(props) => (props.margin ? '40px' : '25px')};
  min-height: 20px;
  @media (min-width: ${media.desktop}) {
    align-items: center;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    opacity: 0;
    cursor: pointer;
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.brownGrey};
    pointer-events: none;
  }
  input:checked ~ span {
    background-color: ${({ theme }) => theme.color.brownGrey};
  }
  label {
    font-family: ${({ theme }) => theme.font.family};
    font-size: 10px;
    color: #666666;
    max-width: 234px;
    @media (min-width: ${media.tablet}) {
      font-size: 11px;
      max-width: 462px;
    }
    @media (min-width: ${media.desktop}) {
      max-width: none;
    }
  }
  p {
    color: #ff0000;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 14px;
    letter-spacing: 0.5px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    transition: 0.3s ease;
    @media (min-width: ${media.tablet}) {
      left: auto;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: 0.8px;
    }
  }
`;
