import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import Intl from '../../../components/Intl';

import media from 'src/styles/media';

const Developers = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <Container>
            <Item>
              <Image>
                <img
                  src={require('src/assets/images/boffo/chris-dikeakos-architects-inc.png')}
                  alt="Chris Dikeakos Architects Inc."
                />
              </Image>
              <h4>{t.boffo.developers[0].name}</h4>
              <p className="default">{t.boffo.developers[0].description}</p>
            </Item>
          </Container>
        </Root>
      )}
    </Intl>
  );
};

export default Developers;

const Root = styled.div`
  background-color: #f2f2f2;
  padding: 40px 0;
  @media (min-width: ${media.tablet}) {
    padding: 60px 0;
    > div {
      display: flex;
      justify-content: space-between;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 80px 0 120px;
    > div {
      justify-content: flex-start;
    }
  }
`;

const Item = styled.div`
  color: black;
  h4 {
    font-family: ${({ theme }) => theme.font.family};
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin: 40px 0 10px;
  }
  &:first-of-type {
    margin-bottom: 60px;
  }
  @media (min-width: ${media.tablet}) {
    &:first-of-type {
      max-width: 43.6%;
      margin-bottom: 0;
    }
    &:last-of-type {
      max-width: 43.6%;
    }
    h4 {
      margin: 30px 0 10px;
    }
  }
  @media (min-width: ${media.desktop}) {
    &:first-of-type {
      max-width: 33.33%;
      margin-bottom: 0;
      margin-right: 16.67%;
    }
    &:last-of-type {
      max-width: 41.67%;
    }
    h4 {
      margin: 40px 0 20px;
    }
  }
`;

const Image = styled.div`
  display: flex;
  align-items: center;
  img {
    max-width: 100%;
  }
  @media (min-width: ${media.tablet}) {
    height: 80px;
  }
`;
