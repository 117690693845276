import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Button from 'components/Text/Button';

const Landing = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <Logo
            src={require('src/assets/images/logos/smith-farrow-logo.svg')}
            alt="Smith &amp; Farrow"
          />
          <Wrapper>
            <Button to="/pc/register" text="New Registration" width="260px" />
          </Wrapper>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.grey};
  position: absolute;
  top: 0;
  left: 0;
`;

const Logo = styled.img`
  width: 480px;
  max-width: 90%;
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translateX(-50%);
`;

const Wrapper = styled.div`
  height: 50vh;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Landing;
