import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';

const Divider = (props) => {
  const { grey } = props;
  return (
    <Root grey={grey}>
      <span />
      <img src={require('src/assets/images/icons/ampresand-dark.svg')} alt="" />
      <span />
    </Root>
  );
};

export default Divider;

const Root = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  background-color: ${(props) =>
    props.grey ? props.theme.color.whiteTwo : 'transparent'};
  @media (min-width: ${media.tablet}) {
    padding-bottom: 40px;
  }
  @media (min-width: ${media.desktop}) {
    padding-bottom: 38px;
  }
  span {
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.brownGrey};
  }
  img {
    width: 41px;
    height: 46px;
    margin: 0 20px;
    @media (min-width: ${media.tablet}) {
      width: 33px;
      height: 37px;
      margin: 0 15px;
    }
    @media (min-width: ${media.deskto}) {
      width: 33px;
      height: 37px;
      margin: 0 38px;
    }
  }
`;
