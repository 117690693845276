import React from 'react';
import styled from 'styled-components';

import Intl from '../../../components/Intl';
import Container from 'components/Container';
import LineLink from 'components/Text/LineLink';
import ScrollAnimation from 'components/ScrollAnimation';

import media from 'src/styles/media';

const Panel = (props) => {
  const { title, ctaLabel, to, image, twoLines, hidden } = props;
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <ScrollAnimation>
          <Root image={image} hidden={hidden}>
            <img src={image} alt="Explore the Community" />
            <Container>
              <h1>{title}</h1>
              <LineLink
                text={ctaLabel}
                to={`${getLocaleURL() + to}`}
                mobileBlack
                twolines={twoLines}
              />
            </Container>
          </Root>
        </ScrollAnimation>
      )}
    </Intl>
  );
};

export default Panel;

const Root = styled.div`
  padding: 0 0 34px;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  h1 {
    text-transform: uppercase;
    color: black;
    max-width: 260px;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    height: 57.8vw;
    display: block;
    margin-bottom: 20px;
  }
  @media (min-width: ${media.tablet}) {
    padding: 52px 0;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-position: center;
    min-height: 446px;
    img {
      display: none;
    }
    h1 {
      color: white;
      margin-bottom: 40px;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 100px 0 253px;
    min-height: calc(100vh - 80px);
    h1 {
      max-width: 430px;
      margin-bottom: 32px;
    }
  }
`;
