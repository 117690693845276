import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { useSwipeable } from 'react-swipeable';

import Intl from 'components/Intl';
import TextHero from 'components/TextHero';
import RegisterTagline from 'components/RegisterTagline';
import Card from './elements/Card';
import Popup from './sections/Popup';

import data from 'src/data/gallery';

import media from 'src/styles/media';

const Gallery = () => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeImage, setActiveImage] = useState(0);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const browser = useSelector((state) => state.browser);

  const $popup = useRef();

  const swiping = useSwipeable({
    onSwipedLeft: (e) => handleSwipe(e),
    onSwipedRight: (e) => handleSwipe(e),
  });

  useEffect(() => {
    document.addEventListener('keydown', handleArrowClick);
    return () => {
      document.removeEventListener('keydown', handleArrowClick);
    };
  }, [isPopupActive, activeImage]);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const handleArrowClick = (e) => {
    if (e.key.toLowerCase() === 'arrowright' && isPopupActive) {
      handleNext();
    } else if (e.key.toLowerCase() === 'arrowleft' && isPopupActive) {
      handlePrev();
    }
  };

  const handleSwipe = (e) => {
    if (isPopupActive) {
      if (e.dir.toLowerCase() === 'right') {
        handleNext();
      } else {
        handlePrev();
      }
    }
  };

  const handleCategory = (index) => {
    setActiveCategory(index);
    setIsTransitioning(true);
    if (browser.is.desktop) {
      setTimeout(() => {
        setIsPopupActive(true);
      }, 1500);
    } else {
      setIsPopupActive(true);
      setIsTransitioning(false);
      disableBodyScroll($popup.current);
    }
  };

  const closePopup = () => {
    enableBodyScroll($popup.current);
    setActiveImage(0);
    setTimeout(() => {
      setIsTransitioning(true);
      setIsPopupActive(false);
    }, 300);
    setTimeout(() => {
      setActiveCategory(null);
      setIsTransitioning(false);
    }, 1500);
  };

  const handlePrev = () => {
    if (activeImage === 0) {
      setActiveImage(data[activeCategory].images.length - 1);
    } else {
      setActiveImage(activeImage - 1);
    }
  };

  const handleNext = () => {
    if (activeImage === data[activeCategory].images.length - 1) {
      setActiveImage(0);
    } else {
      setActiveImage(activeImage + 1);
    }
  };

  return (
    <Intl>
      {(t) => (
        <Root>
          <TextHero title={t.gallery.title} />
          <Wrapper>
            {data.map((item, index) => (
              <Card
                key={index}
                image={item.background}
                title={t.gallery.categories[index]}
                handleClick={() => handleCategory(index)}
                active={activeCategory === index}
                isTransitioning={isTransitioning}
              />
            ))}
            <Popup
              active={isPopupActive}
              activeImage={activeImage}
              handleClose={closePopup}
              handlePrev={handlePrev}
              handleNext={handleNext}
              images={activeCategory !== null && data[activeCategory].images}
              innerRef={$popup}
              swiping={swiping}
            />
          </Wrapper>
          <RegisterTagline />
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div``;

const Wrapper = styled.div`
  padding: 20px;
  position: relative;
  @media (min-width: ${media.tablet}) {
    padding: 0;
    display: flex;
  }
  @media (min-width: ${media.desktop}) {
    width: 100%;
    height: 810px;
  }
`;

export default Gallery;
