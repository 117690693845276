export default {
  color: {
    primary: '#C9FFBD',
    offWhite: '#e9fae5',
    brownGrey: '#979797',
    grey: '#242424',
    whiteTwo: '#f4f4f4',
    lightGreyGreen: '#a3ef92',
  },
  font: {
    family: 'proxima-nova, sans-serif',
    header: 'freight-big-pro, serif',
    secondary: 'SangBleu Sunrise, serif',
  },
};
