import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

import Button from '../elements/Button';

import mapStyles from 'src/data/mapstyles';

import media from 'src/styles/media';

const MobileMap = () => {
  const mapOptions = {
    styles: mapStyles,
    fullscreenControl: false,
  };

  return (
    <Root>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBjffVYE4214dRYSEzO3bKSnqP3YAg8rZw' }}
        defaultCenter={{ lat: 49.2645802, lng: -122.9486176 }}
        defaultZoom={12}
        options={mapOptions}
      >
        <SitePlan
          src={require('src/assets/images/contact/sf-map-marker-mobile.svg')}
          lat={49.2607054}
          lng={-122.8947552}
        />
        <SalesGallery
          src={require('src/assets/images/contact/boffo-map-marker-mobile.svg')}
          lat={49.2645802}
          lng={-122.9886176}
        />
      </GoogleMapReact>
      <Button />
    </Root>
  );
};

export default MobileMap;

const Root = styled.div`
  width: 100%;
  height: 74.3vw;
  position: relative;
  @media (min-width: ${media.desktop}) {
    display: none;
  }
`;

const SitePlan = styled.img`
  width: 64px;
  height: 42px;
  transform: translateX(-100%);
`;

const SalesGallery = styled.img`
  width: 42px;
  height: 62px;
  transform: translate(-100%, -100%);
`;
