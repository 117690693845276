import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Intl from './Intl';

import media from 'src/styles/media';

const LanguageSwitcher = ({ onClick }) => (
  <Intl>
    {(t, { getLocaleURL, locale }) => (
      <Root>
        <Link to="/" className={`link-en ${locale === 'en' ? 'active' : ''}`} onClick={onClick}>
          EN
        </Link>
        <Link to="/sc" className={`${locale === 'sc' ? 'active' : ''}`} onClick={onClick}>
          简
        </Link>
        <Link to="/tc" className={`${locale === 'tc' ? 'active' : ''}`} onClick={onClick}>
          繁
        </Link>
      </Root>
    )}
  </Intl>
);

const Root = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${media.desktop}) {
    margin-left: 40px;
  }

  a {
    color: #979797;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 3px;
    margin: unset;
    margin-right: 20px;
  }

  a:last-child {
    margin-right: 0;
  }

  a.active {
    color: black;
  }

  .link-en {
    font-size: 14px;
  }
`

export default LanguageSwitcher;