import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'src/styles/media';

const Card = (props) => {
  const { image, title, handleClick, active, isTransitioning } = props;
  return (
    <Root
      image={image}
      onClick={handleClick}
      active={active}
      isTransitioning={isTransitioning}
    >
      <h3 className="gallery">{title}</h3>
    </Root>
  );
};

Card.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  handleClick: PropTypes.func,
  active: PropTypes.bool,
};

const Root = styled.div`
  width: 100%;
  height: 50vw;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  padding: 15px 0;
  color: white;
  margin-bottom: 20px;
  position: relative;
  &:last-of-type {
    margin-bottom: 0;
  }
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in;
    background-color: rgba(47, 47, 47, 0.2);
    opacity: 1;
    display: none;
  }
  h3 {
    z-index: 2;
    position: relative;
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: 0;
    padding: 20px 0;
    width: 33.3333%;
    height: 432px;
    &::after {
      display: block;
    }
  }
  @media (min-width: ${media.desktop}) {
    width: ${(props) =>
      props.isTransitioning ? (props.active ? '100%' : '0%') : '33.33%'};
    flex-grow: ${(props) => (props.active ? 1 : 0)};
    margin-bottom: 0;
    padding: 33px 0;
    height: 810px;
    cursor: pointer;
    transition: 1.2s ease-out;
    h3 {
      transition: 0.3s ease-out;
      opacity: ${(props) => (props.isTransitioning ? 0 : 1)};
    }
    &:hover {
      &::after,
      h3 {
        opacity: 0;
      }
    }
  }
`;

export default Card;
