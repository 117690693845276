import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Container from 'components/Container';
import ScrollAnimation from 'components/ScrollAnimation';
import CaptionMedia from 'components/CaptionMedia';
import TitleCallout from 'components/Text/TitleCallout';

import media from 'src/styles/media';

const Kitchen = () => {
  return (
    <Intl>
      {(t) => (
        <Container>
          <Root>
            <ScrollAnimation>
              <TitleCallout title={t.homes.kitchen.title1} />
              <CaptionMedia
                image={require('src/assets/images/homes/smith-and-farrow-interior-kitchen-dark-scheme.jpg')}
                text={t.homes.kitchen.caption1}
                width="100%"
                relative
              />
              <TitleCallout title={t.homes.kitchen.title2} />
              <Wrapper>
                <CaptionMedia
                  image={require('src/assets/images/homes/smith-and-farrow-interior-kitchen-cabinets.jpg')}
                  text={t.homes.kitchen.caption2}
                  width="33.33%"
                  relative
                />
                <StyledCaptionMedia
                  image={require('src/assets/images/homes/smith-and-farrow-interior-ambient-lighting.jpg')}
                  text={t.homes.kitchen.caption3}
                  width="50%"
                  relative
                />
              </Wrapper>
              <StyledCaptionMedia
                image={require('src/assets/images/homes/smith-and-farrow-interior-gas-cooktop.jpg')}
                text={t.homes.kitchen.caption4}
                width="33.33%"
                relative
              />
              <TitleCallout title={t.homes.kitchen.title3} />
              <Wrapper>
                <StyledCaptionMedia
                  video="https://player.vimeo.com/external/519276045.hd.mp4?s=5aec29b07dda2e6d388ecea74580b855c3ccbb6c&amp;profile_id=175"
                  placeholder={require('src/assets/images/homes/smith-and-farrow-interior-mobile_video_1.jpg')}
                  width="58.33%"
                />
                <CaptionMedia
                  image={require('src/assets/images/homes/smith-and-farrow-interior-countertop.jpg')}
                  text={t.homes.kitchen.caption5}
                  width="25%"
                  relative
                />
              </Wrapper>
              <StyledCaptionMedia
                image={require('src/assets/images/homes/smith-and-farrow-interior-patio.jpg')}
                text={t.homes.kitchen.caption6}
                width="100%"
                relative
              />
            </ScrollAnimation>
          </Root>
        </Container>
      )}
    </Intl>
  );
};

const Root = styled.div``;

const Wrapper = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledCaptionMedia = styled(CaptionMedia)`
  @media (min-width: ${media.tablet}) {
    margin-top: 40px;
  }
  @media (min-width: ${media.desktop}) {
    margin-top: 120px;
  }
`;

export default Kitchen;
