import React from 'react';
import styled from 'styled-components';

import Intl from '../../../components/Intl';

import media from 'src/styles/media';

const Description = () => {
  return (
    <Intl>
      {(t, {locale}) => (
        <Root>
          <p className={`description ${locale === 'en' ? '' : 'ch'}`}>
            {t.register.description}
          </p>
        </Root>
      )}
    </Intl>
  );
};

export default Description;

const Root = styled.div`
  padding: 40px 28px;
  @media (min-width: ${media.tablet}) {
    padding: 80px 0;
  }
  .description {
    font-family: ${({ theme }) => theme.font.secondary};
    font-size: 17px;
    font-weight: 300;
    line-height: 1.53;
    text-align: center;
    color: black;
    @media (min-width: ${media.tablet}) {
      font-size: 22px;
      line-height: normal;
      max-width: 557px;
      margin: 0 auto;
    }
    @media (min-width: ${media.desktop}) {
      font-size: 27px;
      max-width: 890px;
    }
  }

  .description.ch {
    @media (min-width: ${media.desktop}) {
      font-size: 27px;
      max-width: 866px;
    }
  }
`;
