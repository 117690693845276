import React from 'react';
import styled from 'styled-components';

import Intl from '../../../components/Intl'

import media from 'src/styles/media';

const Main = () => {
  return (
    <Intl>
      {t => (
        <Root>
          <h2>{t.community.main.title}</h2>
          <h4>{t.community.main.body}</h4>
        </Root>
      )}  
    </Intl>
  );
};

export default Main;

const Root = styled.div`
  text-align: center;
  color: black;
  padding: 40px 20px;
  h2 {
    text-transform: uppercase;
    font-size: 40px;
    line-height: 1.1;
    letter-spacing: 2px;
  }
  h4 {
    font-size: 17px;
    font-weight: 300;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: center;
    margin: 20px auto 0;
  }
  @media (min-width: ${media.tablet}) {
    padding: 60px 0;
    h4 {
      max-width: 560px;
      margin: 30px auto 0;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 120px 0 60px;
    h4 {
      font-size: 27px;
      line-height: normal;
      max-width: 1080px;
    }
    h2 {
      font-size: 42px;
      line-height: 1.71;
      letter-spacing: normal;
    }
  }
  @media ${media.ipadPro} {
    padding: 60px 0;
    h4 {
      max-width: 80%;
      margin: 30px auto 0;
    }
  }
`;
