export default [
  {
    title: 'By Boffo',
    to: '/boffo',
  },
  {
    title: 'Homes',
    to: '/homes',
  },
  {
    title: 'Floorplans',
    to: '/floorplans',
  },
  {
    title: 'Amenities',
    to: '/amenities',
  },
  {
    title: 'West Coquitlam',
    to: '/community',
  },
  {
    title: 'Gallery',
    to: '/gallery',
  },
  {
    title: 'Contact',
    to: '/contact',
  },
  {
    title: 'Register',
    to: '/register',
  },
];
