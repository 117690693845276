import React, { useState } from 'react';
import styled from 'styled-components';
import { scroller } from 'react-scroll';

import Intl from 'components/Intl';
import Container from 'components/Container';
import List from '../elements/List';
import Grid from '../elements/Grid';
import Map from '../elements/Map';

import data from 'src/data/neighbourhood';

import media from 'src/styles/media';

const Neighbourhood = () => {
  const [activeSection, setActiveSection] = useState(0);

  const [isMobileListOpen, setIsMobileListOpen] = useState(false);

  const $neighbourhood = React.useRef();

  const handleSection = index => {
    setActiveSection(index);
    setIsMobileListOpen(false);
  };

  const handleMapSection = index => {
    setActiveSection(index);
    scroller.scrollTo('neighbourhood', { smooth: true, offset: -80 });
  };

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <Map data={data} handleSection={handleMapSection} />
          <Profiles ref={$neighbourhood} name="neighbourhood">
            <Container>
              <h3>
                <span>{t.community.neighbourhoodProfiles}</span>
              </h3>
              <Wrapper>
                <div>
                  <List
                    data={data}
                    handleClick={handleSection}
                    activeSection={activeSection}
                    isMobileOpen={isMobileListOpen}
                    handleMobileList={() => setIsMobileListOpen(!isMobileListOpen)}
                    title={t.community.neighbourhood[data[activeSection].title].title}
                  />
                  <p className="default">{t.community.neighbourhood[data[activeSection].title].description}</p>
                </div>
                <Grid data={data} activeSection={activeSection} />
              </Wrapper>
            </Container>
          </Profiles>
        </Root>
      )}
    </Intl>
  );
};

export default Neighbourhood;

const Root = styled.div``;

const Profiles = styled.div`
  background-color: #f6f6f6;
  padding: 76px 0;
  p {
    margin: 20px 0;
  }
  h3 {
    margin-bottom: 40px;
    font-size: 28px;
    line-height: 0.71px;
    letter-spacing: 0.5px;
  }
  @media (min-width: ${media.tablet}) {
    padding: 60px 0 84px;
    h3 {
      line-height: 1;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${({ theme }) => theme.color.brownGrey};
        height: 1px;
        width: 58%;
      }
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 80px 0 177px;
    s p {
      margin: 58px 0 0;
    }
    h3 {
      margin-bottom: 0;
      font-size: 32px;
      line-height: 1;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        max-width: 22%;
      }
      &::after {
        width: 74.5%;
      }
    }
  }
  @media ${media.ipadPro} {
    padding: 60px 0 84px;
    h3 {
      line-height: 1;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${({ theme }) => theme.color.brownGrey};
        height: 1px;
        width: 58%;
      }
    }
  }
`;

const Wrapper = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    > div:first-of-type {
      width: 36%;
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-top: 60px;
    > div:first-of-type {
      width: 20.5%;
    }
  }
  @media ${media.ipadPro} {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    > div:first-of-type {
      width: 36%;
    }
  }
`;
