import React from 'react';

export default [
  {
    url: 'https://www.instagram.com/boffogroup/',
    svg: (
      <g
        id="icon-circle-instagram"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g fill="#BABABD" fillRule="nonzero">
          <path
            d="M21.5,11.8 C21.2894503,11.189927 20.810073,10.7105497 20.2,10.5 C19.7860414,10.3408104 19.3426649,10.2725987 18.9,10.3 L13.1,10.3 C12.6573351,10.2725987 12.2139586,10.3408104 11.8,10.5 C11.189927,10.7105497 10.7105497,11.189927 10.5,11.8 C10.3408104,12.2139586 10.2725987,12.6573351 10.3,13.1 L10.3,18.9 C10.2725987,19.3426649 10.3408104,19.7860414 10.5,20.2 C10.7105497,20.810073 11.189927,21.2894503 11.8,21.5 C12.2139586,21.6591896 12.6573351,21.7274013 13.1,21.7 L18.9,21.7 C19.3426649,21.7274013 19.7860414,21.6591896 20.2,21.5 C20.810073,21.2894503 21.2894503,20.810073 21.5,20.2 C21.6591896,19.7860414 21.7274013,19.3426649 21.7,18.9 L21.7,13.1 C21.7166977,12.657886 21.6488153,12.2166504 21.5,11.8 Z M16,19.6000316 C14.0117749,19.6000316 12.4,17.9882251 12.4,16 C12.4,14.0117749 14.0117749,12.4 16,12.4 C17.9882251,12.4 19.6000316,14.0117749 19.6000316,16 C19.6040084,16.9560026 19.2260112,17.8740075 18.5500093,18.5500093 C17.8740075,19.2260112 16.9560026,19.6040084 16,19.6000316 L16,19.6000316 Z M19.8,13.1 C19.3029437,13.1 18.9,12.6970563 18.9,12.2 C18.9,11.7029437 19.3029437,11.3 19.8,11.3 C20.2970563,11.3 20.7,11.7029437 20.7,12.2 C20.6,12.7 20.3,13.1 19.8,13.1 L19.8,13.1 Z"
            id="Path_108"
          ></path>
          <circle id="Ellipse_1" cx="16" cy="16" r="2.4"></circle>
          <path
            d="M16,0 C7.163444,0 0,7.163444 0,16 C0,24.836556 7.163444,32 16,32 C24.836556,32 32,24.836556 32,16 C31.9741877,7.17415794 24.8258421,0.0258123416 16,0 Z M23,18.9 C22.9855413,19.478477 22.8844169,20.0515156 22.7,20.6 C22.3723322,21.5932299 21.5932299,22.3723322 20.6,22.7 C20.0593706,22.913647 19.4811015,23.0156945 18.9,23 L13.1,23 C12.521523,22.9855413 11.9484844,22.8844169 11.4,22.7 C10.4067701,22.3723322 9.62766781,21.5932299 9.3,20.6 C9.08635296,20.0593706 8.98430548,19.4811015 9,18.9 L9,13.1 C9.01445867,12.521523 9.11558312,11.9484844 9.3,11.4 C9.62766781,10.4067701 10.4067701,9.62766781 11.4,9.3 C11.9406294,9.08635296 12.5188985,8.98430548 13.1,9 L18.9,9 C19.478477,9.01445867 20.0515156,9.11558312 20.6,9.3 C21.5932299,9.62766781 22.3723322,10.4067701 22.7,11.4 C22.913647,11.9406294 23.0156945,12.5188985 23,13.1 L23,16 C23.1106243,16.9635017 23.1106243,17.9364983 23,18.9 L23,18.9 Z"
            id="Path_109"
          ></path>
        </g>
      </g>
    ),
  },
  {
    url: 'https://www.facebook.com/BoffoDevelopments',
    svg: (
      <g
        id="icon-circle-facebook"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g fill="#BABABD" fillRule="nonzero">
          <path d="M16,0 C7.163444,0 0,7.163444 0,16 C0,24.836556 7.163444,32 16,32 C24.836556,32 32,24.836556 32,16 C31.9741877,7.17415794 24.8258421,0.0258123416 16,0 Z M19.8,10.9 L18.4,10.9 C17.3,10.9 17,11.5 17,12.3 L17,14 L19.8,14 L19.4,16.7 L17,16.7 L17,23.7 L14.1,23.7 L14.1,16.7 L11.7,16.7 L11.7,14 L14.1,14 L14.1,11.9 C14.001304,10.9270134 14.3309187,9.95924183 15.0029861,9.24877062 C15.6750534,8.5382994 16.6230348,8.15546932 17.6,8.2 C18.6,8.2 19.5,8.3 19.7,8.3 L19.8,10.9 L19.8,10.9 Z"></path>
        </g>
      </g>
    ),
  },
  {
    url: 'https://twitter.com/boffogroup',
    svg: (
      <g
        id="icon-circle-twitter"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g fill="#BABABD" fillRule="nonzero">
          <path d="M16,0 C7.163444,0 0,7.163444 0,16 C0,24.836556 7.163444,32 16,32 C24.836556,32 32,24.836556 32,16 C31.9741877,7.17415794 24.8258421,0.0258123416 16,0 Z M22.1,13.2 L22.1,13.6 C22.1107019,15.8045603 21.2396772,17.9219215 19.6807993,19.4807993 C18.1219215,21.0396772 16.0045603,21.9107019 13.8,21.9000975 C12.2389339,21.8981367 10.7114183,21.4468253 9.4,20.6 L10.1,20.6 C11.3957682,20.5851527 12.654477,20.1655831 13.7,19.4 C12.4678572,19.3706819 11.3871023,18.5701227 11,17.4 L11.6,17.4 C11.8703322,17.4104355 12.1405545,17.3766578 12.4,17.3 C11.0294001,17.0676474 10.0199979,15.8900115 10,14.5 L10,14.4 C10.4003772,14.6232818 10.8433566,14.7595832 11.3,14.8 C10.4815733,14.2779097 9.99018082,13.3707235 10,12.4 C9.94809814,11.8992377 10.0913787,11.3977557 10.4,11 C11.8809996,12.8238337 14.0555538,13.9473533 16.4,14.1 C16.4,13.9 16.3,13.7 16.3,13.5 C16.3,12.3141767 17.0219577,11.2478213 18.122967,10.8074176 C19.2239763,10.3670139 20.4821908,10.6413004 21.3,11.5 C21.9627286,11.3558994 22.60219,11.1203083 23.2,10.8 C22.9748777,11.4732489 22.512908,12.041827 21.9,12.4 C22.44643,12.3259622 22.9830145,12.1918161 23.5,12 C23.1276664,12.498469 22.6495498,12.9082832 22.1,13.2 Z"></path>
        </g>
      </g>
    ),
  },
];
