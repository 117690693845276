import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';

const Info = () => {
  return (
    <Root>
      <Wrapper>
        <div>
          <h5>CONTACT – SALES ENQUIRIES</h5>
          <p className="small">
            <a href="mailto:info@smithandfarrow.ca">Info@SmithAndFarrow.ca</a>
            <br />
            <a href="tel:604.456.0688">604.456.0688</a>
          </p>
        </div>
        <div>
          <h5>Sales gallery - CLOSED</h5>
          <p className="small">
            2100 Springer Avenue
            <br />
            Burnaby, BC
          </p>
        </div>
        <div>
          {/* <h5>Hours</h5>
          <p className="small">
            Open daily (except Friday)
            <br /> 12pm – 6pm
          </p> */}
        </div>
        <div>
          <h5>Smith &amp; Farrow - Location</h5>
          <p className="small">
            720 Farrow Street
            <br />
            Coquitlam, BC
          </p>
        </div>
      </Wrapper>
    </Root>
  );
};

export default Info;

const Root = styled.div`
  padding: 40px 20px;
  h5 {
    margin-bottom: 10px;
  }
  @media (min-width: ${media.tablet}) {
    padding: 60px 77px;
  }
  @media (min-width: ${media.desktop}) {
    padding: 80px 120px;
  }
  @media (min-width: ${media.xl}) {
    padding: 120px 180px;
  }
  @media ${media.ipadPro} {
    padding: 60px 155px;
  }
`;

const Wrapper = styled.div`
  > div {
    margin-bottom: 30px;
    p {
      font-size: 14px;
    }
  }
  > div:last-of-type {
    margin-bottom: 0;
  }
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
      width: 280px;
      margin-bottom: 0;
    }
    > div:first-of-type,
    > div:nth-of-type(2) {
      margin-bottom: 40px;
    }
  }
  @media (min-width: ${media.desktop}) {
    > div {
      margin-bottom: 0;
      width: auto;
      p {
        font-size: 16px;
      }
    }
    > div:first-of-type,
    > div:nth-of-type(2) {
      margin-bottom: 0px;
    }
    width: auto;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  @media ${media.ipadPro} {
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto;
  }
`;
