import React from 'react';
import styled from 'styled-components';

import Intl from '../../../components/Intl'
import LineLink from 'components/Text/LineLink';
import Container from 'components/Container';

import media from 'src/styles/media';

const About = () => {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <Line className={`${locale === 'en' ? '' : 'chinese'}`} />
          <Container>
            <h1>
              {t.home.about.title}
            </h1>
            <LineLink text={t.home.about.ctaLabel} to={`${getLocaleURL()}/boffo`} />
          </Container>
        </Root>
      )}
    </Intl>
  );
};

export default About;

const Root = styled.div`
  padding: 40px 0 60px;
  background-color: ${({ theme }) => theme.color.grey};
  position: relative;
  h1 {
    max-width: 1044px;
    font-family: ${({ theme }) => theme.font.header};
    font-size: 40px;
    font-weight: normal;
    line-height: 1.1;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: white;
    margin-bottom: 20px;
  }
  @media (min-width: ${media.tablet}) {
    padding: 40px 0 60px;
    h1 {
      margin-bottom: 40px;
      font-size: 78px;
      line-height: 0.97;
      letter-spacing: 4px;
      color: ${({ theme }) => theme.color.brownGrey};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 60px 0 152px;
    h1 {
      margin-bottom: 47px;
      font-size: 130px;
      line-height: 1;
      letter-spacing: normal;
    }
  }
`;

const Line = styled.span`
  position: absolute;
  right: 0;
  top: 64px;
  width: 77%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.brownGrey};
  
  @media (min-width: ${media.tablet}) {
    top: 81px;
    width: 85%;
  }
  @media (min-width: ${media.desktop}) {
    top: 117px;
  }

  &.chinese {
    width: calc(100% - 200px);

    @media (min-width: ${media.tablet}) {
      width: calc(100% - 400px);
    }

    @media (min-width: ${media.desktop}) {
      width: calc(100% - 650px);
    }
  }
`;
