import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = (props) => {
  const { text, width, to, handleClick, black, transparent } = props;
  return (
    <Root
      as={to ? Link : 'button'}
      to={to}
      onClick={handleClick ? handleClick : (f) => f}
      black={black}
      transparent={transparent}
      width={width}
      {...props}
    >
      {text}
    </Root>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  width: PropTypes.string,
  to: PropTypes.string,
  handleClick: PropTypes.func,
  black: PropTypes.bool,
  transparent: PropTypes.bool,
};

const Root = styled(Link)`
  display: block;
  width: fit-content;
  width: ${(props) => props.width};
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${(props) => (props.black ? 'black' : props.theme.color.offWhite)};
  color: ${(props) => (props.black ? props.theme.color.offWhite : 'black')};
  background-color: ${(props) =>
    props.black ? props.theme.color.grey : props.theme.color.offWhite};
  font-family: ${({ theme }) => theme.font.family};
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.3s ease;
  white-space: nowrap;
  /* &:hover {
    border-color: ${(props) => !props.black && props.theme.color.primary};
    color: ${(props) =>
    props.black ? props.theme.color.offWhite : props.theme.color.black};
    background-color: ${(props) =>
    props.black ? 'black' : props.theme.color.primary};
  } */
`;

export default Button;
