import React, { useState } from 'react';
import styled from 'styled-components';

import Intl from '../../../components/Intl'

import media from 'src/styles/media';

const Video = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const $video = React.createRef();

  const handleVideo = () => {
    if (isVideoPlaying) {
      $video.current.pause();
      setIsVideoPlaying(false);
    } else {
      $video.current.play();
      setIsVideoPlaying(true);
    }
  };

  return (
    <Intl>
      {t => (
        <Root isVideoPlaying={isVideoPlaying} onClick={handleVideo}>
          <h1>{t.community.video.title}</h1>
          <img
            src={require(`src/assets/images/icons/${t.community.video.iconName}`)}
            alt="Play Video"
            className="play"
          />
          <img
            src={require('src/assets/images/community/video-poster.jpg')}
            alt=""
            className="poster"
          />
          <video
            src="https://player.vimeo.com/external/389293277.hd.mp4?s=5cc5e671e3207c4839886c62b0f43a66c69e607f&amp;profile_id=175"
            ref={$video}
            controls
          />
        </Root>
      )}  
    </Intl>
  );
};

export default Video;

const Root = styled.div`
  height: 395px;
  position: relative;
  padding: 55px 20px;
  h1 {
    max-width: 237px;
    color: white;
    text-transform: uppercase;
    opacity: ${props => (props.isVideoPlaying ? 0 : 1)};
    transition: 0.3s ease;
    position: relative;
    z-index: 3;
  }
  .poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 2;
    opacity: ${props => (props.isVideoPlaying ? 0 : 1)};
    transition: 0.3s ease;
    pointer-events: none;
  }
  .play {
    width: 136px;
    position: absolute;
    bottom: 54px;
    left: 22px;
    opacity: ${props => (props.isVideoPlaying ? 0 : 1)};
    transition: 0.3s ease;
    z-index: 3;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
  }
  @media (min-width: ${media.tablet}) {
    margin: 0 40px;
    height: 417px;
    padding: 63px 30px;
    .play {
      width: 136px;
      bottom: 62px;
      left: 30px;
    }
  }
  @media (min-width: ${media.desktop}) {
    height: 800px;
    margin: 0 60px;
    position: relative;
    padding: 146px 54px;
    cursor: pointer;
    h1 {
      max-width: 462px;
    }
    .play {
      width: 170px;
      bottom: 76px;
      left: 57px;
    }
  }
  @media ${media.ipadPro} {
    margin: 0 40px;
    height: 600px;
    padding: 63px 30px;
    .play {
      width: 136px;
      bottom: 62px;
      left: 30px;
    }
  }
`;
