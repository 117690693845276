import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Container from 'components/Container';
import ScrollAnimation from 'components/ScrollAnimation';
import CaptionMedia from 'components/CaptionMedia';
import TitleCallout from 'components/Text/TitleCallout';

import media from 'src/styles/media';

const Highlights = () => {
  return (
    <Intl>
      {(t) => (
        <Container>
          <Root>
            <ScrollAnimation>
              <TitleCallout
                title={t.amenities.highlights.title1}
                smallerMobile
              />
              <Wrapper>
                <StyledCaptionMedia
                  image={require('src/assets/images/amenities/smith-and-farrow-amenity-kitchen.jpg')}
                  text={t.amenities.highlights.imageCaption1}
                  width="33.33%"
                  relative
                  artistRendering
                />
                <CaptionMedia
                  image={require('src/assets/images/amenities/smith-and-farrow-amenity-workspace.jpg')}
                  text={t.amenities.highlights.imageCaption2}
                  width="50%"
                  relative
                  artistRendering
                />
              </Wrapper>
              <Space />
              <CaptionMedia
                image={require('src/assets/images/amenities/smith-and-farrow-amenity-lounge.jpg')}
                text={t.amenities.highlights.imageCaption3}
                width="100%"
                relative
                artistRendering
              />
              <TitleCallout title={t.amenities.highlights.title2} />
              <Wrapper>
                <StyledCaptionMedia
                  image={require('src/assets/images/amenities/smith-and-farrow-amenity-gym.jpg')}
                  text={t.amenities.highlights.imageCaption4}
                  width="33.33%"
                  relative
                  artistRendering
                />
                <CaptionMedia
                  image={require('src/assets/images/amenities/smith-and-farrow-amenity-yoga.jpg')}
                  text={t.amenities.highlights.imageCaption5}
                  width="50%"
                  relative
                  artistRendering
                />
              </Wrapper>
              <Space />
              <CaptionMedia
                image={require('src/assets/images/amenities/smith-and-farrow-amenity-hummingbird.jpg')}
                text={t.amenities.highlights.imageCaption6}
                width="100%"
                relative
                artistRendering
              />
              <TitleCallout title={t.amenities.highlights.title3} />
              <Wrapper>
                <CaptionMedia
                  image={require('src/assets/images/amenities/smith-and-farrow-amenity-interior-rooftop-lounge.jpg')}
                  text={t.amenities.highlights.imageCaption7}
                  width="50%"
                  relative
                  artistRendering
                />
                <StyledCaptionMedia
                  image={require('src/assets/images/amenities/smith-and-farrow-amenity-outdor-media-screen.jpg')}
                  text={t.amenities.highlights.imageCaption8}
                  width="33.33%"
                  relative
                  artistRendering
                />
              </Wrapper>
              <Space />
              <CaptionMedia
                image={require('src/assets/images/amenities/smith-and-farrow-amenity-interior-lounge.jpg')}
                text={t.amenities.highlights.imageCaption9}
                width="100%"
                relative
                artistRendering
              />
              <Space />
            </ScrollAnimation>
          </Root>
        </Container>
      )}
    </Intl>
  );
};

const Root = styled.div``;

const Wrapper = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledCaptionMedia = styled(CaptionMedia)`
  @media (min-width: ${media.tablet}) {
    margin-top: 40px;
  }
  @media (min-width: ${media.desktop}) {
    margin-top: 120px;
  }
`;

const Space = styled.div`
  @media (min-width: ${media.tablet}) {
    padding-top: 80px;
  }
  @media (min-width: ${media.desktop}) {
    padding-top: 120px;
  }
`;

export default Highlights;
